import { PaymentOpaqueValue } from "@b2bportal/purchase-api";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { CarEntryTypeEnum } from "../../cars-module";
import { PortalTypes } from "../../common";
import {
  FareclassShelfBrandName,
  FlightEntryTypeEnum,
  SliceStopCountFilter,
  TripCategory,
} from "../../flights-module";
import {
  HotelEntryTypeEnum,
  LodgingPromotionType,
  MealPlanKindEnum,
  Reservation,
  RoomProduct,
  RoomProductType,
  RoomProductWithTransformedIndexes,
} from "../../hotels-module";
import { PackagesEntryTypeEnum } from "../../packages-module";
import {
  EligibilitySegmentEnum,
  ExerciseEligibilityV1,
  ExerciseEligibilityV1Enum,
  FlightDisruptionEnum,
  FlightDisruptionReasonEnum,
  getEligibleSliceFromEligibility,
  HotelCfarCancelScenario,
  HotelCfarCancelScenarioEnum,
  HotelCfarContract,
  ItineraryEnum,
} from "../../trips-module";
import {
  HotelCfarQuote,
  HotelCfarQuoteAdditionalInfo,
  PostBookingTrackingProperties,
} from "./ancillaries";
import {
  Action,
  CurrentHotelPricingList,
  FrozenHotelPricing,
  LodgingPriceFreezeGetCreditsStatement,
  LodgingPriceFreezeOffer,
  LodgingPriceFreezeRefundResponseEnum,
  LodgingPriceFreezeRefundResult,
  ResultEnum,
  Voucher,
} from "./hotel-price-freeze";
import { PaymentV2Enum } from "./payment-machine";
import { ID } from "./price-freeze";
import { Dealness, PushNotificationFilter, ShopFilter } from "./shop-summary";

dayjs.extend(utc);

// COMMON EVENTS

export const ADD_CONTACT_INFO = "add_contact_info";

export interface AddContactInfoProperties {}

export const ADD_TRAVELER = "add_traveler";

export interface AddTravelerProperties {}

export const SELECT_TRAVELERS = "select_travelers";

export interface SelectTravelersProperties {}

export const SELECT_REWARDS = "select_rewards";

export interface SelectRewardsProperties {}

export const ADD_PAYMENT = "add_payment";

export const TRIGGERED_ERROR_BOUNDARY = "triggered_error_boundary";

export interface AddPaymentProperties {
  success: boolean;
  is_vcn: boolean;
}

export const SELECT_PAYMENT = "select_payment";

export interface SelectPaymentProperties {}

// AIR EVENTS

export const AIR_ENTRY = "air_entry";
export const CHAT_ENTERED = "chat_entered";

export interface AirEntryProperties {
  delegated_to: string;
  first_launch: boolean;
  origin: string;
  destination: string;
  origin_country_code: string;
  destination_country_code: string;
  departure_date: string;
  return_date?: string;
  pax_total: number;
  number_of_active_watches: number;
  trip_type: "round_trip" | "one_way" | "multi_city";
  has_credits: boolean;
  credit_balance: number;
  vx_statement_credit_balance: number;
  rewards_accounts?: string;
  customer_account_role?: string;
  entry_type?: FlightEntryTypeEnum;
  fareclass_filter?: string[];
  non_stop?: boolean;
  preferences_applied?: boolean;
  cross_sell_displayed?: boolean;
  card_on_file?: boolean;
}

export const AIR_MULTICITY_ENTRY = "air_multicity_entry";

export interface AirMultiCityEntryProperties {
  first_launch: boolean;
  flight1_origin: string;
  flight1_origin_country_code: string;
  flight1_destination: string;
  flight1_destination_country_code: string;
  flight1_departure_date: string;
  flight2_origin: string;
  flight2_origin_country_code: string;
  flight2_destination: string;
  flight2_destination_country_code: string;
  flight2_departure_date: string;
  flight3_origin?: string;
  flight3_origin_country_code?: string;
  flight3_destination?: string;
  flight3_destination_country_code?: string;
  flight3_departure_date?: string;
  flight4_origin?: string;
  flight4_origin_country_code?: string;
  flight4_destination?: string;
  flight4_destination_country_code?: string;
  flight4_departure_date?: string;
  flight5_origin?: string;
  flight5_origin_country_code?: string;
  flight5_destination?: string;
  flight5_destination_country_code?: string;
  flight5_departure_date?: string;
  pax_total: number;
  number_of_active_watches: number;
  trip_type: "multi_city";
  has_credits: boolean;
  credit_balance: number;
  vx_statement_credit_balance: number;
  rewards_accounts?: string;
  customer_account_role?: string;
  entry_type?: FlightEntryTypeEnum;
}

export const VIEWED_FORECAST = "viewed_forecast";
export const VIEWED_FLIGHT_LIST = "viewed_flight_list";
export const VIEWED_MULTICITY_FLIGHT_LIST = "viewed_multicity_flight_list";

export interface PredictionProperties {
  recommendation: "buy" | "wait" | "null";
  dealness: Dealness | string;
}

export interface SharedFlightListProperties {
  flights_shown?: number;
  shelf_fares?: {
    [key: string]: number; // number of fares by each fare shelf. This is flattened as separate key-value pairs below for Amplitude
  };
  // There are 5 standard fare shelves as defined here https://hopper-jira.atlassian.net/wiki/spaces/AS/pages/5462983101/Fare+Shelf+Assignment#Mapping-Schema
  shelf0_fares?: number; // Basic fares
  shelf1_fares?: number; // Standard fares
  shelf2_fares?: number; // Enhanced fares
  shelf3_fares?: number; // Premium fares
  shelf4_fares?: number; // Luxury fares
}

export interface AccountProperties {
  account_type_selected: string;
  account_use_type?: string;
  customer_account_role?: string;
  account_allow_rewards_redemption?: boolean;
}

export interface ViewedFlightListProperties
  extends AirEntryProperties,
    SharedFlightListProperties,
    PredictionProperties,
    AccountProperties,
    PriceFreezeUserSelectedDurationProperties {
  slice_direction?: "outbound" | "return";
  fares_shown_outbound?: number;
  fares_shown_return?: number;
  includes_hopper_vi?: boolean;
  hopper_vi_savings_usd?: number;
  hopper_vi_duration_savings_minutes?: number;
  hopper_vi_flights_shown?: number;
  multi_ticket_type?: string;
  includes_hackerfare?: boolean;
  hackerfare_savings_usd?: number;
  hackerfare_flights_shown?: number;
  no_availability?: boolean;
  recommended_flight_displayed?: boolean;
  funnel?: string;
}

export interface ViewedMulticityFlightListProperties
  extends AirEntryProperties,
    SharedFlightListProperties,
    AccountProperties {
  slice_index?: number;
  fares_shown?: number;
}

export interface ViewedPriceFreezeProperties {
  price_freeze_shown: boolean;
  price_freeze_duration: number; // should be in seconds
  price_freeze_otm_cap_usd: number;
  price_freeze_total_cost?: number;
  price_freeze_cost_per_pax: number;
  current_lowest_price_usd?: number;
  frozen_price_total_usd?: number;
}

export const CHANGED_DURATION = "changed_duration";

export interface PriceFreezeUserSelectedDurationProperties {
  default_fee_price_per_traveler_usd?: number;
  default_duration_offered?: number;
  min_fee_price_per_traveler_usd?: number;
  min_duration_offered?: number;
  max_fee_price_per_traveler_usd?: number;
  max_duration_offered?: number;
}

export interface ChangedPriceFreezeDurationProperties
  extends PriceFreezeUserSelectedDurationProperties {
  selected_duration?: number;
  selected_fee_price_per_traveler_usd?: number;
  price_freeze_otm_cap_usd?: number;
  page?: "flight_list" | "review_itinerary" | "pf_checkout";
}

export const SELECTED_PRICE_FREEZE_PURCHASE = "selected_price_freeze_purchase";

export interface SelectedPriceFreezePurchaseProperties
  extends ChangedPriceFreezeDurationProperties {
  price_freeze_id?: string;
}

export const VIEWED_DURATION_SLIDER = "viewed_duration_slider";

export const CONTINUE_DURATION_SLIDER = "continue_duration_slider";

export const APPLIED_FILTER = "applied_filter";

export type AppliedPriceWatchFilterPropertyType =
  | "exclude_LCC"
  | "include_LCC"
  | "stops_watch"
  | "any_stops_watch";

export type AppliedFilterPropertyType =
  | "price"
  | "stops"
  | "airline"
  | "time"
  | "airport"
  | "flight number"
  | "exclude_LCC"
  | "baggage_transfer"
  | "duration"
  | "fareclass"
  | "policy_status"
  | AppliedPriceWatchFilterPropertyType;

export interface AppliedFilterProperties {
  filter_type: AppliedFilterPropertyType;
  location: "search" | "list";
  funnel?: "packages";
}

export const VIEWED_SLICE = "viewed_slice";
export const VIEWED_MULTICITY_SLICE = "viewed_multicity_slice";

export interface ViewedSliceProperties extends ViewedFlightListProperties {
  fare_class: string;
}

export const SELECTED_FLIGHT = "selected_flight";
export const SELECTED_MULTICITY_FLIGHT = "selected_multicity_flight";

export interface SelectedSliceProperties extends ViewedSliceProperties {
  flight_list_ranking?: number;
  multi_ticket_type?: string;
}

export const VIEWED_TRIP_SUMMARY = "viewed_trip_summary";
export const VIEWED_MULTICITY_TRIP_SUMMARY = "viewed_multicity_trip_summary";

export interface ViewedTripSummaryProperties
  extends SelectedSliceProperties,
    PriceFreezeUserSelectedDurationProperties {
  carrier: string;
  is_multi_ticket: boolean;
  multi_ticket_type?: string;
  entry_type?: FlightEntryTypeEnum;
}

export const ADD_FREQUENT_FLYER = "add_frequent_flyer";

export interface AddFrequentFlyerProperties {
  frequent_flyer_program: string;
}

export const ADD_PASSPORT = "add_passport";

export interface AddPassportProperties {
  success: boolean;
}

export const VIEW_ITINERARY_RESTRICTIONS = "view_itinerary_restrictions";
export const REVIEW_FLIGHT_DETAILS = "review_flight_details";
export const REVIEW_MULTICITY_FLIGHT_DETAILS =
  "review_multicity_flight_details";

export interface ReviewFlightDetailsProperties
  extends ViewedTripSummaryProperties,
    Cap1DpOfferFactsProperties {
  stops: number;
  provider: string;
  advance: number;
  carrier: string;
  multi_ticket_type?: string;
  business_loyalty_programs?: string;
  slice_count?: number;
}

export const AIR_SAVE_AND_CONTINUE_CLICKED = "air_save_and_continue_clicked";
export const AIR_PRICE_QUOTE_TO_BOOK_REQUEST_RUNTIME =
  "air_price_quote_to_book_request_runtime";

export const PRICE_QUOTE_AIR = "price_quote_air";
export const PRICE_QUOTE_MULTICITY_AIR = "price_quote_multicity_air";

export interface PriceQuoteAirProperties extends ReviewFlightDetailsProperties {
  success: boolean;
  booking_session_id: string;
  pax_total: number;
  flight_list_ranking?: number;
}

export const COMPLETE_BUY_AIR = "complete_buy_air";
export const COMPLETE_BUY_MULTICITY_AIR = "complete_buy_multicity_air";

export interface CompleteBuyAirProperties
  extends ReviewFlightDetailsProperties {
  success: boolean;
  total_rewards_amount_usd: number;
  total_card_amount_usd: number;
  card_product_used: string;
  rewards_product_used: string;
  rewards_currency: string;
  agent_book_fee_amount_usd: number;
  pax_total: number;
  agent_locator: string;
  booking_session_id: string;
  seats_selection_available: boolean;
  seats_selected_seats: boolean;
  seats_selected_count: number;
  seats_selected_paid: number;
  seats_selected_free: number;
  seats_selected_price_usd: number;
  lowest_price_seat: number;
  flight_list_ranking?: number;
  credit_redeemed: boolean;
  credit_amt_redeemed: number;
  is_vcn: boolean;
  preferences_recommended?: boolean;
  purpose_of_travel?: string;
}

export interface CompleteBuyAirPriceFreezeProperties {
  price_freeze_flow: boolean;
  price_freeze_id: ID;
  price_freeze_shown_OTM_total_usd: number;
  price_freeze_cap_hit: boolean;
  price_freeze_duration: number;
  price_freeze_pax_total: number;
  price_freeze_pax_adults: number;
  price_freeze_pax_children: number;
  price_freeze_pax_lap_infants: number;
  price_freeze_pax_seat_infants: number;
  price_freeze_entry: "flight_search" | "PF_exercise";
  frozen_price_total_usd: number;
  price_freeze_total_cost: number;
  price_freeze_otm_cap_usd: number;
  price_freeze_cost_per_pax: number;
  credit_redeemed: boolean;
  credit_amt_redeemed: number;
  is_vcn: boolean;
}

export interface PriceAlertPreferenceProperties {
  /*
                true = user can receive push notifications & we show them the opt in
                false = user can’t receive push notifications & we don’t show them the opt in
              */
  push_eligibile?: boolean;
  /*
                push = user opts into push
                email = user doesn’t opt into push or is not eligible for push
              */
  channel?: "push" | "email";
}

export const getPriceAlertPreferenceProperties = (
  pushNotificationFilter?: PushNotificationFilter
): PriceAlertPreferenceProperties => {
  return {
    push_eligibile: !!pushNotificationFilter,
    channel:
      pushNotificationFilter === PushNotificationFilter.AppNotifications
        ? "push"
        : "email",
  };
};

export const VIEWED_WATCH = "viewed_watch";

export interface ViewedWatchProperties extends PriceAlertPreferenceProperties {}

export const ADDED_WATCH = "added_watch";

export const SELECTED_WATCH_PUSH = "selected_watch_push";

export interface SelectedWatchPushProperties {
  action: "opt-in" | "opt-out";
}

export enum WatchFilterType {
  NonStopExcludeLCC = "non_stop_exclude_LCC",
  NonStopIncludeLCC = "non_stop_include_LCC",
  AnyStopsExcludeLCC = "any_stops_exclude_LCC",
  AnyStopsIncludeLCC = "any_stops_include_LCC",
}

export interface AddedWatchProperties extends PriceAlertPreferenceProperties {
  recommendation?: "wait" | "buy";
  filter_type:
    | WatchFilterType.NonStopExcludeLCC
    | WatchFilterType.NonStopIncludeLCC
    | WatchFilterType.AnyStopsExcludeLCC
    | WatchFilterType.AnyStopsIncludeLCC;
}

export const getWatchFilterTypeFromShopFilter = (
  shopFilter: ShopFilter | undefined
) => {
  switch (shopFilter) {
    case ShopFilter.NonStopNoLCC:
      return WatchFilterType.NonStopExcludeLCC;
    case ShopFilter.NonStop:
      return WatchFilterType.NonStopIncludeLCC;
    case ShopFilter.NoLCC:
    case ShopFilter.ShortLayoverNoLCC:
      return WatchFilterType.AnyStopsExcludeLCC;
    case ShopFilter.ShortLayover:
    case ShopFilter.NoFilter:
    case undefined:
      return WatchFilterType.AnyStopsIncludeLCC;
  }
};

export const getWatchFilterTypeFromFlightShopParams = (
  stopOption: SliceStopCountFilter,
  noLcc: boolean
): WatchFilterType => {
  if (noLcc) {
    switch (stopOption) {
      case SliceStopCountFilter.NONE:
        return WatchFilterType.NonStopExcludeLCC;
      default:
        return WatchFilterType.AnyStopsExcludeLCC;
    }
  } else {
    switch (stopOption) {
      case SliceStopCountFilter.NONE:
        return WatchFilterType.NonStopIncludeLCC;
      default:
        return WatchFilterType.AnyStopsIncludeLCC;
    }
  }
};

export const APPLIED_FREQUENT_FLYER = "applied_frequent_flyer";
export const SKIPPED_SEAT_SELECTION = "skipped_seats_selection";
export const REQUESTED_SEATMAP = "requested_seatmap";
export const VIEWED_SEATMAP = "viewed_seatmap";
export const CHOSE_FROM_SEATMAP = "chose_from_seatmap";

export const CHANGED_TRIP_TYPE = "changed_trip_type";
export const CHANGED_PAX_COUNT = "changed_pax_count";

export interface ITrackingProperties<T = any> {
  properties: T;
  encryptedProperties: Array<string>;
}

// HOTEL EVENTS
export const HOTEL_ENTRY = "hotel_entry";

export interface HotelEntryProperties {
  delegated_to: string;
  first_launch: boolean;
  market: string;
  country: string;
  number_of_properties: number;
  number_of_guests: number;
  hotel_advance: string;
  advance?: number;
  rooms_searched: number;
  pets_searched?: number;
  has_credits: boolean;
  credit_balance: number;
  vx_statement_credit_balance: number;
  rewards_accounts?: string;
  customer_account_role?: string;
  entry_type?: HotelEntryTypeEnum;
  los?: number;
  preferences_applied?: boolean;
  card_on_file?: boolean;
  adults_count: number;
  children_count: number;
  number_of_filters_applied?: number;
  sort_order?: string;
  destination_name?: string;
}

export const VIEWED_HOTEL_LIST = "viewed_hotel_list";

export interface ViewedHotelListProperties
  extends HotelEntryProperties,
    TravelWalletOfferTrackingProperties,
    SharedTravelOfferTrackingProperties {
  account_type_selected: string;
  account_use_type?: string;
  customer_account_role?: string;
  account_allow_rewards_redemption?: boolean;
  unavailable_hotels: number;
  available_hotels: number;
  pc_shown: number;
  has_pc_properties?: boolean;
  has_lodging_promotions?: number;
  lc_shown: number;
  has_lc_properties?: boolean;
  city_name?: string;
  state?: string;
  no_availability?: boolean;
  google_place_id?: string;
}

export const VIEWED_UNAVAILABLE_HOTEL_LIST = "viewed_unavailable_hotel_list";

export interface ViewedUnavailableHotelListProperties
  extends ViewedHotelListProperties {}

export const SELECTED_UNAVAILABLE_HOTEL_FROM_LIST =
  "selected_unavailable_hotel_from_list";

export interface SelectedUnavailableHotelFromList
  extends ViewedHotelListProperties {}

export const APPLIED_HOTEL_FILTER = "applied_hotel_filter";

export interface AppliedHotelFilterProperties {
  filter_type:
    | "stars"
    | "price"
    | "hotel_name"
    | "amenities"
    | "premier_collection"
    | "free_cancel"
    | "on_sale"
    | "policy_status"
    | "loyalty_programs"
    | "premium_stays"
    | "lifestyle_stays"
    | "stay_types"
    | "meal_plan_types";
  amenities?: string[];
}

export interface AppliedPremiumStaysToggleProperties
  extends AppliedHotelFilterProperties {
  filter_type: "premium_stays";
  location: "list" | "all_filters_modal";
  searched_market: string | null;
}

export interface AppliedLifestyleStaysToggleProperties
  extends AppliedHotelFilterProperties {
  filter_type: "lifestyle_stays";
  location: "list" | "all_filters_modal";
  searched_market: string | null;
}

export const SELECTED_HOTEL_FROM_LIST = "selected_hotel_from_list";

export interface SelectedHotelFromListProperties
  extends ViewedHotelListProperties {
  is_preferred_cot: boolean;
  is_pc: boolean;
  is_lc: boolean;
  nth_night_promo?: string;
  meal_plan?: MealPlanKindEnum;
}

export const SELECTED_HOTEL_FROM_MAP = "selected_hotel_from_map";

export interface SelectedHotelFromMapProperties
  extends ViewedHotelListProperties {
  is_preferred_cot: boolean;
  is_pc: boolean;
  is_lc: boolean;
}

export const SEARCH_THIS_AREA = "search_this_area";

export const VIEWED_HOTEL_DETAILS = "viewed_hotel_details";

export interface ViewedHotelDetailsProperties
  extends ViewedHotelListProperties {
  account_type_selected: string;
  rooms_shown: number;
  is_preferred_cot: boolean;
  is_pc: boolean;
  is_lc: boolean;
  entry_type?: HotelEntryTypeEnum;
  promotion_applicable: LodgingPromotionType;
  promotion_discount: number;
  promotion_discount_currency: string;
  promotion_discount_percentage: number;
  cfar_rr_offer: number;
  hotel_price_freeze_eligible?: boolean;
  hotel_price_freeze_deposit_percent?: number;
  rooms_with_images_count: number;
  rooms_without_images_count: number;
  hotel_details_entry_source?: string;
  nth_night_promo?: string;
  meal_plan?: MealPlanKindEnum;
}

export const SEARCH_DISTANCE_FROM_SHOP = "search_distance_from_shop";
export const VIEWED_HOTEL_SHOP_MAP = "viewed_hotel_shop_map";

export interface SearchDistanceFromShopProperties
  extends ViewedHotelDetailsProperties {
  point_of_interest: string;
}

export const VIEW_HOTELS_NEAR = "view_hotels_near";

export interface ViewHotelsNearAvailabilityProperties
  extends ViewedHotelListProperties {
  poi_availability: string;
}

export const ADD_DRIVERS_LICENSE = "add_drivers_license";

export const SELECT_HOTEL_ROOM = "select_hotel_room";

export interface SelectHotelRoomProperties
  extends ViewedHotelDetailsProperties {
  room_type: string;
  account_type_selected: string;
}

export interface HotelPriceFreezeExerciseProperties {
  price_freeze_flow?: boolean;
  hotel_price_freeze_id?: string;
  hotel_freeze_nightly_price_usd?: number;
  hotel_freeze_total_price_usd?: number;
  hotel_price_freeze_cap_usd?: number;
  exp_timestamp?: number; // Unix timestamp
  hotel_price_freeze_exp_timestamp?: string; // ISO time string
  hotel_freeze_deposit_usd?: number;
  hotel_freeze_exercised_price_usd?: number;
  hotel_price_freeze_actual_otm_usd?: number;
  hotel_price_freeze_duration?: number; // Days left to exercise freeze, or 1 days prior to check-in date, whichever comes first.
  hotel_price_freeze_state?: string;
}

export interface HotelPriceFreezePurchaseOfferProperties {
  price_freeze_flow?: boolean;
  hotel_price_freeze_id?: string;
  hotel_freeze_nightly_price_usd?: number;
  hotel_freeze_total_price_usd?: number;
  hotel_price_freeze_cap_usd?: number;
  hotel_freeze_deposit_usd?: number;
  hotel_price_freeze_duration?: number; // Days
  exp_timestamp?: number; // Unix timestamp
  hotel_price_freeze_exp_timestamp?: string; // ISO time string
}

export const getHotelPriceFreezePurchaseOfferProperties = (
  offer: LodgingPriceFreezeOffer | null,
  expirationTimestamp?: number
): HotelPriceFreezePurchaseOfferProperties => {
  return {
    hotel_price_freeze_id: offer?.id,
    hotel_freeze_nightly_price_usd:
      offer?.pricing.frozenPricePerNight.fiat.value,
    hotel_freeze_total_price_usd: offer?.pricing.frozenPrice.fiat.value,
    hotel_price_freeze_cap_usd: offer?.pricing.cap.fiat.value,
    hotel_freeze_deposit_usd: offer?.pricing.deposit.fiat.value,
    hotel_price_freeze_duration: offer?.durationHours
      ? offer?.durationHours / 24
      : undefined,
    exp_timestamp: expirationTimestamp,
    hotel_price_freeze_exp_timestamp: expirationTimestamp
      ? // expiration for hotel PF is always at 23:59 of a certain date based on UTC,
        // so we convert the time to UTC first, and extract the date only.
        dayjs.unix(expirationTimestamp).utcOffset(0).format("YYYY-MM-DD")
      : undefined,
  };
};

interface IHotelPriceFreezeExerciseProps {
  isPriceFreezeExerciseFlow?: boolean;
  // getCredits
  statement?: LodgingPriceFreezeGetCreditsStatement;
  // getDetail
  voucher?: Voucher;
  pricingsFromGetDetail?: CurrentHotelPricingList;
}

export const getHotelPriceFreezeExerciseProperties = (
  args: IHotelPriceFreezeExerciseProps
): HotelPriceFreezeExerciseProperties => {
  const {
    isPriceFreezeExerciseFlow,
    statement,
    voucher,
    pricingsFromGetDetail,
  } = args;
  const {
    id: voucherId,
    pricings: frozenHotelPricingList,
    deposit,
    state,
    action,
  } = voucher ?? {};
  const getExercisedPriceInUsd = (): number | undefined => {
    return (
      statement?.subtotalWithTotalCreditCharge.fiat.value ??
      pricingsFromGetDetail?.total.selling.fiat.value
    );
  };
  /*
                note: it should be the amount the user saved from PF.
                (the amount that we pay the hotel out of pocket for the PF)
              */
  const getActualOtmInUsd = (): number | undefined => {
    return (
      statement?.savingsCredit.fiat.value ??
      pricingsFromGetDetail?.total.savings.fiat.value
    );
  };

  return {
    price_freeze_flow: isPriceFreezeExerciseFlow,
    hotel_price_freeze_id: voucherId,
    hotel_freeze_nightly_price_usd:
      frozenHotelPricingList?.nightly.frozen.fiat.value,
    hotel_freeze_total_price_usd:
      frozenHotelPricingList?.total.frozen.fiat.value,
    hotel_price_freeze_cap_usd: frozenHotelPricingList?.total.cap.fiat.value,
    exp_timestamp: dayjs(action?.expiration).unix(),
    hotel_price_freeze_exp_timestamp: dayjs(action?.expiration)
      // expiration for hotel PF is always at 23:59 of a certain date based on UTC,
      // so we convert the time to UTC first, and extract the date only.
      .utcOffset(0)
      .format("YYYY-MM-DD"),
    hotel_freeze_deposit_usd: deposit?.fiat.value,
    hotel_freeze_exercised_price_usd: getExercisedPriceInUsd(),
    hotel_price_freeze_actual_otm_usd: getActualOtmInUsd(),
    hotel_price_freeze_duration: dayjs(action?.expiration).diff(dayjs(), "d"), // Days left to exercise
    hotel_price_freeze_state: state?.State,
  };
};

export type PartialHotelCheckoutPriceFreezeExerciseProperties =
  HotelPriceFreezeExerciseProperties;

export const getPartialHotelCheckoutPriceFreezeExerciseProperties = (
  args: IHotelPriceFreezeExerciseProps
): PartialHotelCheckoutPriceFreezeExerciseProperties => {
  return getHotelPriceFreezeExerciseProperties(args);
};

export const REVIEW_DETAILS_HOTEL_CHECKOUT = "review_details_hotel_checkout";

export interface ReviewDetailsHotelCheckoutProperties
  extends ViewedHotelListProperties,
    PartialHotelCheckoutPriceFreezeExerciseProperties {
  lodging_name: string;
  market: string;
  chain: string;
  brand: string;
  length_of_stay: number;
  sell_price_excluding_taxes_and_fees_usd: number;
  owed_now_excluding_taxes_and_fees: number;
  owed_now_taxes_and_fees: number;
  owed_at_checkin_taxes_and_fees: number;
  pay_later_excluding_taxes_and_fees: number;
  pay_later_taxes_and_fees: number;
  pay_later_fees: number;
  total_discount: number;
  refundability: "non_refundable" | "free_cancellation";
  supply_revenue_usd: number;
  check_in_date: string;
  check_out_date: string;
  supplier: string;
  hotel_advance: string;
  provider_name?: string;
  provider_type: "direct" | "retail" | "wholesale";
  rate_provider?: string;
  adr: string;
  is_preferred_cot: boolean;
  in_policy?: boolean;
  hotel_loyalty_eligible?: boolean;
  is_corporate?: boolean;
}

export const HOTEL_SAVE_AND_CONTINUE_CLICKED =
  "hotel_save_and_continue_clicked";

export const PRICE_QUOTE_HOTEL = "price_quote_hotel";

export interface PriceQuoteHotelProperties
  extends ReviewDetailsHotelCheckoutProperties {
  success: boolean;
  booking_session_id: string;
  lodging_row_index?: number;
}

export type PartialPriceQuoteHotelPriceFreezeExerciseProperties =
  HotelPriceFreezeExerciseProperties;

export const getPartialPriceQuoteHotelPriceFreezeExerciseProperties = (
  args: IHotelPriceFreezeExerciseProps
): PartialPriceQuoteHotelPriceFreezeExerciseProperties => {
  return getHotelPriceFreezeExerciseProperties(args);
};

export const COMPLETE_BUY_HOTEL = "complete_buy_hotel";

export interface CompleteBuyHotelProperties
  extends ReviewDetailsHotelCheckoutProperties {
  success: boolean;
  total_rewards_amount_usd: number;
  total_card_amount_usd: number;
  card_product_used: string;
  rewards_product_used: string;
  rewards_currency: string; // ie. “Miles”, “Cash”, “Points”
  agent_booking_fee_amount_usd: number;
  booking_session_id: string;
  reservation_id: string;
  lodging_row_index?: number;
  is_recommended?: boolean;
  rooms_booked: number;
  is_pc: boolean;
  is_lc: boolean;
  credit_redeemed: boolean;
  credit_amt_redeemed: number;
  refundable_advance?: string;
  cancellation_policy?: string;
  entry_type?: HotelEntryTypeEnum;
  is_vcn: boolean;
  is_recent: boolean;
  in_policy?: boolean;
  hotel_loyalty_eligible?: boolean;
  is_corporate?: boolean;
  preferences_recommended?: boolean;
  searched_market?: string;
  total_guests: number;
  city_name?: string;
  state?: string;
  purpose_of_travel?: string;
  nth_night_promo?: string;
  meal_plan?: MealPlanKindEnum;
}

export type PartialCompleteBuyHotelPriceFreezeExerciseProperties =
  HotelPriceFreezeExerciseProperties;

export const getPartialCompleteBuyHotelPriceFreezeExerciseProperties = (
  args: IHotelPriceFreezeExerciseProps
): PartialCompleteBuyHotelPriceFreezeExerciseProperties => {
  return getHotelPriceFreezeExerciseProperties(args);
};

export const SELECTED_RECOMMENDED_HOTEL = "selected_recommended_hotel";

export interface SelectedRecommendedHotelProperties
  extends SelectedHotelFromListProperties {}

export const HOTEL_LIST_MAP_CAROUSEL = "hotel_list_map_carousel";

export const ADD_HOTEL_LOYALTY = "add_hotel_loyalty";

// CARS EVENTS
export const CARS_ENTRY = "cars_entry";

export interface CarsEntryProperties {
  delegated_to: string;
  first_launch: boolean;
  market: string;
  country: string;
  airport: string;
  pick_up_date: string;
  pick_up_hour: number;
  drop_off_date: string;
  drop_off_hour: number;
  search_age: number;
  same_pickUp_dropOff: boolean;
  cars_advance: number;
  has_credits: boolean;
  credit_balance: number;
  vx_statement_credit_balance: number;
  rewards_accounts?: string;
  customer_account_role?: string;
  entry_type?: CarEntryTypeEnum;
  card_on_file?: boolean;
}

export const VIEWED_CARS_LIST = "viewed_cars_list";

export interface ViewedCarsListProperties
  extends CarsEntryProperties,
    Partial<TravelWalletOfferTrackingProperties>,
    SharedTravelOfferTrackingProperties {
  account_type_selected: string;
  number_of_results: number;
  results_from_supplier_ABG?: number;
  results_from_supplier_AM?: number;
  results_from_supplier_HE?: number;
  results_from_supplier_PL?: number;
  results_from_supplier_SX?: number;
  cheapest_price: number;
  cheapest_provider: string;
  pick_up_place_country: string;
  pick_up_place_city: string;
  pick_up_place_id: string;
  drop_off_place_country: string;
  drop_off_place_city: string;
  drop_off_place_id: string;
  account_use_type?: string;
  customer_account_role?: string;
  account_allow_rewards_redemption?: boolean;
  card_on_file?: boolean;
}

export const APPLIED_CARS_FILTER = "applied_cars_filter";

export interface AppliedCarsFilterProperties extends CarsFilterEventProperties {
  filter_type:
    | "reset"
    | "car_type"
    | "price"
    | "rental_company"
    | "specifications"
    | "cancellation_policy"
    | "passengers"
    | "bags"
    | "pickup"
    | "policy_status";
}

export const SELECTED_CAR_FROM_LIST = "selected_car_from_list";

export interface SelectedCarFromListProperties
  extends ViewedCarsListProperties {
  vehicle_type: string;
  total_price: number;
  free_cancellation: boolean;
  unlimited_mileage: boolean;
  automatic: boolean;
  supplier: string;
  vendor: string;
  non_refundable: boolean;
  conditional_cancellation: boolean;
  conditional_cancellation_after_booking: boolean;
  in_policy?: boolean;
}

export const VIEWED_CAR_DETAILS = "viewed_car_details";

export interface ViewedCarDetailsProperties
  extends SelectedCarFromListProperties {
  account_type_selected: string;
  fuel_policy: string;
  pax_count?: number;
  bags_count?: number;
  pickup_location?: string;
  car_type?: string;
  same_dropoff?: boolean;
}

export const REVIEWED_CAR_DETAILS_CHECKOUT = "reviewed_car_details_checkout";

export interface ReviewedCarDetailsCheckoutProperties
  extends ViewedCarDetailsProperties {
  driver_age: number;
  pay_now: number;
  pay_later: number;
  estimated_commission: number;
}

export const CAR_SAVE_AND_CONTINUE_CLICKED = "car_save_and_continue_clicked";

export const PRICE_QUOTE_CAR = "price_quote_car";

export interface PriceQuoteCarProperties
  extends ReviewedCarDetailsCheckoutProperties {
  success: boolean;
  booking_session_id: string;
}

export const COMPLETE_BUY_CARS = "complete_buy_car";

export interface CompleteBuyCarProperties
  extends ReviewedCarDetailsCheckoutProperties {
  success: boolean;
  total_rewards_amount_usd: number;
  total_card_amount_usd: number;
  card_product_used: string;
  rewards_product_used: string;
  rewards_currency: string;
  agent_booking_fee_amount_usd: number;
  booking_session_id: string;
  ground_booking_id: string;
  credit_redeemed: boolean;
  credit_amt_redeemed: number;
  is_vcn: boolean;
  entry_type?: CarEntryTypeEnum;
  in_policy?: boolean;
  purpose_of_travel?: string;
}

export const ADD_HERTZ_LOYALTY_REWARDS = "add_hertz_loyalty_rewards";

export interface AddHertzLoyaltyRewardsProperties {}

// MY TRIPS EVENTS
export const VIEWED_MY_TRIPS = "viewed_my_trips";

export const VIEWED_CONTACT_MODAL = "viewed_contact_modal";

export interface MyTripsProperties {
  agent_locator: string;
  agent_locator_provider: string;
  days_until_departure: string;
  booking_date: string;
  has_skch?: string;
  skch_severity?: string;
  cfar_active?: number;
}

// Corporate Travel
export const TRIP_REQUESTED_CAR = "trip_requested_car";

export interface ViewedContactModalProperties extends MyTripsProperties {
  contact_reason:
    | "cancel_flight"
    | "change_flight"
    | "change_hotel"
    | "cancel_hotel"
    | "change_car"
    | "cancel_car"
    | "general_contact";
  contact_provider_air?: string;
  contact_provider_hotel?: string;
  contact_provider_ground?: string;
}

export const WATCH_ENTRY = "watch_entry";
export const SELECT_WATCHED_FLIGHT = "select_watched_flight";
export const STOPPED_WATCH = "stopped_watch";

export interface FlightWatchBaseProperties {
  advance: number;
  destination: string;
  origin: string;
  departure_date: string;
  return_date?: string;
  trip_type: TripCategory;
  filter_type: WatchFilterType;
}

export interface WatchEntryProperties extends FlightWatchBaseProperties {}

export interface SelectWatchedFlightProperties
  extends FlightWatchBaseProperties {
  number_of_active_watches: number;
}

export interface StoppedWatchProperties {
  success: boolean;
}

export const VIEWED_MAJOR_SCHEDULE_CHANGE = "viewed_major_schedule_change";

export interface ViewedMajorScheduleChangeProperties
  extends MyTripsProperties {}

export const USER_ACTION_SCHEDULE_CHANGE_SUCCESS =
  "user_action_schedule_change_success";

export interface UserActionScheduleChangeSuccessProperties {
  action: string;
  user_action: string;
  severity: string;
  system_locator_provider: string;
  agent_locator_provider: string;
  agent_locator: string;
  system_locator: string;
  days_until_departure: string;
  connection_time_valid: string;
  is_continuous: string;
  has_reprotection: string;
  provider: string;
  pcc: string;
  booking_date: string;
  itinerary_id: string;
  validating_carrier: string;
}

export const SCHEDULE_CHANGE_DECISION = "schedule_change_decision";

export interface ScheduleChangeDecisionProperties extends MyTripsProperties {
  decision_outcome: "accept" | "deny";
}

export const AUTO_ACCEPT = "auto_accept";

// ONBOARDING EVENTS

export const VIEWED_ONBOARDING = "viewed_onboarding";
export const COMPLETED_ONBOARDING = "completed_onboarding";
export const SKIPPED_ONBOARDING = "skipped_onboarding";

// FTUX

export const PRICE_DROP_CREDIT = "price_drop_credit";

export interface PriceDropCreditProperties {
  price_bucket?: PriceBucketValue | null;
  refund_type?: "cash" | "credit" | null;
}

type PriceBucketValue = "great_price" | "good_price" | "fair_price" | "wait";

export const getPriceBucketValueFromDealness = (
  dealness: Dealness
): PriceBucketValue | null => {
  switch (dealness) {
    case Dealness.Wait:
      return "wait";
    case Dealness.Fair:
      return "fair_price";
    case Dealness.Good:
      return "good_price";
    case Dealness.Great:
      return "great_price";
    default:
      return null;
  }
};

export const PRICE_DROP_VIEWED = "price_drop_viewed";

export interface PriceDropViewedProperties extends PriceDropCreditProperties {
  page:
    | "watched_trips"
    | "forecast"
    | "flight_list"
    | "trip_summary"
    | "flight_book"
    | "payment_summary"
    | "trip_review"
    | "flight_confirmation";
  price_drop_offer_duration: number;
  price_drop_offer_max_cap: number;
  price_drop_offer_min_cap: number;
}

export const VIEWED_PRICE_DROP_DETAILS = "viewed_price_drop_details";

export interface ViewedPriceDropDetailsProperties
  extends PriceDropCreditProperties {
  page:
    | "watched_trips"
    | "forecast"
    | "flight_list"
    | "trip_summary"
    | "flight_book"
    | "mobile"
    | "trip_review"
    | "flight-confirmation";
}

export const PRICE_DROP_ADDED = "price_drop_added";

export interface PriceDropAddedProperties extends PriceDropCreditProperties {
  price_drop_offer_duration: number;
  price_drop_offer_max_cap: number;
  price_drop_offer_min_cap: number;
}

export const PRICE_DROP_REFUND_RECEIVED = "price_drop_refund_received";

export interface PriceDropRefundReceivedProperties
  extends PriceDropCreditProperties {
  success: boolean;
  drop_amount: number;
}

export const CREDITS_ISSUED = "credits_issued";

export interface CreditsIssuesProperties extends PriceDropCreditProperties {
  credit_issued_reason: string;
}

// GENERAL EVENTS & FAILURES

export interface RuntimeProperties {
  runtime: number;
  provider?: string;
  carrier?: string;
}

export type LandingScreen =
  | "cars_list"
  | "cars_search"
  | "exchange"
  | "flight_search"
  | "flight_shop"
  | "ftux"
  | "hotel_list"
  | "hotel_search"
  | "my_trips"
  | "travel_wallet";

export const LAUNCHED_APPLICATION = "launched_application";

export interface LaunchedApplicationProperties {
  landing_screen: LandingScreen;
  url: string;
  rewards_accounts: string;
  has_credits: boolean;
  credit_balance: number;
  vx_statement_credit_balance: number;
  user_source?: string;
  utm_medium?: string;
}

export const LOGGED_IN = "logged_in";

export interface LoggedInProperties {
  runtime: number;
}

export const LAUNCHED_MOBILE_LOCATION_SCREEN =
  "launched_mobile_location_screen";

export interface LaunchedMobileLocationScreenProperties {
  funnel:
    | "flights"
    | "cars"
    | "hotels"
    | "premier_collection"
    | "experiences"
    | "other";
  url: string;
  entry_type?: string;
}

export const AUTOCOMPLETE_LOCATION_SELECTED = "autocomplete_location_selected";

export interface AutocompleteLocationSelectedProperties {
  funnel:
    | "flights"
    | "cars"
    | "hotels"
    | "premier_collection"
    | "experiences"
    | "other";
  url: string;
  autocomplete_value: string;
}

export const LAUNCHED_MOBILE_CALENDAR = "launched_mobile_calendar";

export interface LaunchedMobileCalendarProperties {
  funnel:
    | "flights"
    | "cars"
    | "hotels"
    | "premier_collection"
    | "experiences"
    | "other";
  url: string;
}

export const CALENDAR_DATES_SELECTED = "calendar_dates_selected";

export interface CalendarDatesSelectedProperties {
  funnel:
    | "flights"
    | "cars"
    | "hotels"
    | "premier_collection"
    | "experiences"
    | "other";
  url: string;
  start_date: string;
  end_date: string;
}

export const NETWORK_CALL_FAILED = "network_call_failed";
export const MODAL_ALERT = "modal_alert";
export const MODAL_ALERT_CHOICE = "modal_alert_choice";

export const VIEWED_TAKEOVER = "viewed_takeover";
export const TAPPED_TAKEOVER_CHOICE = "tapped_takeover_choice";

export const VIEWED_PRICE_FREEZE_SIMILAR_FLIGHT_LIST =
  "viewed_price_freeze_similar_flight_list";

export const PRICE_FREEZE_SIMILAR_FLIGHT_CONFIRMATION =
  "cap1_price_freeze_similar_flight_confirmation";
export const PRICE_FREEZE_SIMILAR_FLIGHT_ERROR =
  "cap1_price_freeze_similar_flight_error";

export enum ModalCategoryType {
  FEATURE = "feature",
  TROUBLE = "trouble",
}

export enum ModalScreens {
  CARS_AVAILABILITY = "cars availability",
  CARS_CHECKOUT = "cars checkout",
  CARS_SHOP = "cars shop",
  CARS_SEARCH = "cars search",
  FLIGHTS_CHECKOUT = "flights checkout",
  FLIGHTS_SEARCH = "flights search",
  AIR_PRICE_FREEZE_CHECKOUT = "air price freeze checkout",
  HOTELS_AVAILABILITY = "hotels availability",
  HOTELS_SHOP = "hotels details",
  HOTELS_CHECKOUT = "hotels checkout",
  HOTELS_SEARCH = "hotels search",
  HOTEL_PRICE_FREEZE_PURCHASE = "hotel_price_freeze_purchase",
  FLIGHTS_AVAILABILITY = "flights availability",
  DISRUPTION_REBOOKING = "disruption_rebooking",
  MY_TRIPS = "my trips",
  ADD_WATCH_FLIGHT_LIST = "add watch flight list",
  VR_CHECKOUT = "vr checkout",
  VR_DETAILS = "vr details",
  FLIGHTS_SHOP = "flights shop",
  PACKAGES_CHECKOUT = "packages checkout",
  PACKAGES_AVAILABILITY = "packages availability",
  EXPERIENCES_CHECKOUT = "experiences checkout",
  EXPERIENCES_AVAILABILITY = "experiences availability",
}

export enum CorpModalScreens {
  ONBOARDING_WELCOME = "onboarding welcome",
  ONBOARDING_LIST = "onboarding list",
  ONBOARDING_DETAILS = "onboarding details",
  ONBOARDING_ADDITIONAL = "onboarding additional",
  ONBOARDING_POLICY = "onboarding policy",
  YOU_ARE_ALL_SET = "you_are_all_set",
}

export enum ModalButtonType {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  CLOSE = "close",
}

export interface ModalAlertProperties extends Cap1HotelCfarRRProperties {
  lob?: "na" | "air" | "ground" | "hotel" | "storefront" | "vacation rentals";
  type: string;
  category: ModalCategoryType;
  screen?: string;
  primary_button: string;
  secondary_button: string;
  modal_subtitle: string;
  modal_title: string;
  agent_title: string;
  agent_subtitle: string;
  step: string;
  funnel:
    | "flights"
    | "cars"
    | "hotels"
    | "premier_collection"
    | "packages"
    | "vacation_rentals"
    | "experiences"
    | "other";
  modal_type?: string;
  price_freeze_flow?: boolean;
}

export interface ModalAlertChoiceProperties extends ModalAlertProperties {
  button_choice: ModalButtonType;
  button?: string;
}

export interface OnboardingModalChoiceProperties
  extends ModalAlertChoiceProperties {
  has_changes: boolean;
}

export const VIEWED_FLIGHT_SHOP_LOADING = "viewed_flight_shop_loading";
export const VIEWED_FLIGHT_BOOK_PROCESSING = "viewed_flight_book_processing";
export const VIEWED_HOTEL_AVAILABILITY_LOADING =
  "viewed_hotel_availability_loading";
export const VIEWED_HOTEL_SHOP_LOADING = "viewed_hotel_shop_loading";
export const VIEWED_HOTEL_BOOK_PROCESSING = "viewed_hotel_book_processing";
export const VIEWED_PC_AVAILABILITY_LOADING = "viewed_pc_availability_loading";
export const VIEWED_PC_SHOP_LOADING = "viewed_pc_shop_loading";
export const VIEWED_PC_BOOK_PROCESSING = "viewed_pc_book_processing";
export const VIEWED_CAR_AVAILABILITY_LOADING =
  "viewed_car_availability_loading";
export const VIEWED_CAR_SHOP_LOADING = "viewed_car_shop_loading";
export const VIEWED_CAR_BOOK_PROCESSING = "viewed_car_book_processing";
export const NO_AVAILABILITY_AIR = "no_availability_air";
export const NO_AVAILABILITY_HOTELS = "no_availability_hotels";
export const NO_AVAILABILITY_CARS = "no_availability_cars";
export const CHARGE_BOOKING_AGENT_FEE = "charge_booking_agent_fee";

export enum SelfServeEvents {
  ClickCancel = "self_serve_click_cancel_button",
  ClickSupport = "self_serve_click_support",
  ClickRedirectToCarrier = "self_serve_redirect_to_carrier",
  ClickSeatsLink = "self_serve_clicked_seats_airline_link",
  ConfirmCancellation = "self_serve_confirm_cancellation",
  RedirectToAirline = "self_serve_redirect_to_airline",
  ResponseFailure = "self_serve_cancel_response_failure",
  ResponseSuccess = "self_serve_cancel_response_success",
  ViewedCancelModal = "self_serve_viewed_cancel_modal",
  ViewedSavedTravelers = "self_serve_saved_travelers_viewlist",
  ClickSavedTravelersAddNew = "self_serve_saved_travelers_addnew",
  ClickSavedTravelersSaveTraveler = "self_serve_saved_travelers_savechanges",
  ViewedExchangeModal = "self_serve_viewed_exchange_modal",
  ViewedExchangeLanding = "self_serve_exchange_landingpage",
  ViewedExchangeShopPage = "self_serve_exchange_shoppage",
  ViewedExchangeReviewPage = "self_serve_exchange_reviewpage",
  ViewedExchangeConfirmPage = "self_serve_exchange_confirmpage",
  ViewedTravelerDetailModal = "self_serve_traveler_detail_view_modal",
  EditTravelerDetailModal = "self_serve_traveler_detail_edit_modal",
  SubmitEditTravelerDetailModal = "self_serve_traveler_detail_submit_change",
  FTCExchangeViewedNoResults = "self_serve_ftcexchange_viewed_no_results",
  ExchangeNotEligible = "self_serve_exchange_not_eligible",
  ExchangeViewedNoResults = "self_serve_exchange_viewed_no_results",
  ExchangePriceQuoteSuccess = "self_serve_exchange_price_quote_success",
  ExchangePriceQuoteFailure = "self_serve_exchange_price_quote_failure",
  ExchangeBookSuccess = "self_serve_exchange_book_success",
  ExchangeBookFailure = "self_serve_exchange_book_failure",
  ExchangeSubmitSuccess = "self_serve_exchange_submitsuccess",
  ExchangeSubmitFailure = "self_serve_exchange_submitfailure",
  ViewedFTCExchangeLanding = "self_serve_ftcexchange_landingpage",
  ViewedFTCExchangeShopPage = "self_serve_ftcexchange_shoppage",
  ViewedFTCExchangeReviewPage = "self_serve_ftcexchange_reviewpage",
  ViewedFTCExchangeConfirmPage = "self_serve_ftcexchange_confirmpage",
  FTCExchangeNotEligible = "self_serve_ftcexchange_not_eligible",
  FTCExchangeSubmitSuccess = "self_serve_ftcexchange_submitsuccess",
  FTCExchangeSubmitFailure = "self_serve_ftcexchange_submitfailure",
  FTCExchangePriceQuoteSuccess = "self_serve_ftc_redeem_price_quote_success",
  FTCExchangePriceQuoteFailure = "self_serve_ftc_redeem_price_quote_failure",
  FTCExchangeBookSuccess = "self_serve_ftc_redeem_book_success",
  FTCExchangeBookFailure = "self_serve_ftc_redeem_book_failure",
  CompletedRequestSelfServe = "completed_request_self_serve",
}

export interface ViewedSavedTravelersProperties {
  url: string;
  num_traveler_profiles: number;
}

export interface SavedTravelersProperties {
  url: string;
  frequent_flyer_program_added: boolean;
  num_frequent_flyer_programs: number;
}

export enum SelfServeCancelScenario {
  CfarCancel = "cfar_cancel",
}

export const VIEWED_RESEND_EMAIL = "viewed_resend_email";
export const CONFIRM_RESEND_EMAIL = "confirm_resend_email";
export const RESEND_EMAIL_SUBMIT = "resend_email_submit";

export interface ResendEmailProperties {
  screen: ItineraryEnum;
}

export interface ResendEmailSubmitProperties extends ResendEmailProperties {
  success: boolean;
}

export interface ChargeBookingAgentFeeProperties {
  agent_booking_fee_amount_usd: number;
  success: boolean;
}

export const BOOKING_FEE_WAIVED = "booking_fee_waived";

export interface BookingFeeWaivedProperties {
  reason: string;
}

export const CHOOSE_PRICE_FREEZE = "choose_price_freeze";

export interface ChoosePriceFreezeProperties {
  price_freeze_entry: string;
}

export enum PriceFreezeEntryEnum {
  FLIGHT_LIST = "flight_list",
  FLIGHT_LIST_FARE_CLASS = "flight_list_fare_class",
  FORECAST = "flight_list_forecast",
  TRIP_SUMMARY = "trip_summary",
}

export const VIEWED_PRICE_FREEZE = "viewed_price_freeze";

export interface ViewedPriceFreezeInfoProperties
  extends ChoosePriceFreezeProperties {}

export const FREEZE_PRICE_CLICK = "freeze_price_click";

export interface FreezePriceClickProperties
  extends ChoosePriceFreezeProperties {}

export const CLP_MODAL_VIEW = "CLP_modal_view";

export interface CLPModalViewProperties {
  origin: string;
  destination: string;
  origin_country_code: string;
  destination_country_code: string;
  departure_date: string;
  return_date: string;
  trip_type: "round_trip" | "one_way" | "multi_city";
  fare_class:
    | "BasicEconomy"
    | "Economy"
    | "Business"
    | "PremiumEconomy"
    | "First";
}

export const CLP_CLICK = "CLP_click";

export enum CLPClickEnum {
  CHOOSE_ANOTHER_FLIGHT = "Choose another flight",
  CONTINUE = "Continue",
  EXIT = "Exit",
}

export interface CLPClickProperties {
  selected_button: CLPClickEnum;
}

export const PRICE_FREEZE_REVIEW_DETAILS = "price_freeze_review_details";

export enum EntryButtonEnum {
  FREEZE_PRICE = "freeze_price",
  INFO_MODAL = "info_modal",
  CLP_MODAL = "CLP_modal",
}

export enum PreviousFlightEnum {
  CLP_FLIGHT = "CLP_flight",
  CHOSEN_FLIGHT = "chosen_flight",
}

export interface PriceFreezeReviewDetailsProps {
  price_freeze_entry?: PriceFreezeEntryEnum;
  entry_button?: EntryButtonEnum;
  previous_flight?: PreviousFlightEnum;
  price_freeze_duration?: number;
  price_freeze_otm_cap_usd?: number;
  // note: in power-loader, we have `price_freeze_total_cost_usd: number; = Field(coalesce=['price_freeze_total_cost'])`
  price_freeze_total_cost?: number;
  // note: in power-loader, we have `price_freeze_cost_per_pax_usd: number; = Field(coalesce=['price_freeze_cost_per_pax'])`
  price_freeze_cost_per_pax?: number;
  frozen_price_total_usd?: number;
}

export const CHANGE_FLIGHT = "change_flight";

export interface ChangeFlightTrackingProps {
  previous_flight: PreviousFlightEnum;
  success: boolean;
}

export const EDIT_TRAVELERS = "edit_travelers";

export interface EditTravelersTrackingProps {
  success: boolean;
  price_freeze_pax_total: number;
  price_freeze_pax_adults: number;
  price_freeze_pax_children: number;
  price_freeze_pax_lap_infants: number;
  price_freeze_pax_seat_infants: number;
}

export const EDIT_CONTACT = "edit_contact";

export const VIEWED_FROZEN_PRICES = "viewed_frozen_prices";

export interface ViewedFrozenPricesTrips {
  price_freeze_active: number;
  price_freeze_expired: number;
  hotel_price_freeze_active: number;
  hotel_price_freeze_expired: number;
}

export const COMPLETE_BOOKING_PF = "complete_booking_pf";

export interface CompleteBookinPF {
  price_freeze_id: ID;
}

export const COMPLETE_BOOKING_HOTEL_PRICE_FREEZE =
  "complete_booking_hotel_price_freeze";

export interface CompleteBookingHotelPriceFreeze {
  lob: "hotel";
  price_freeze_id: ID;
}

export const VIEW_PRICE_FREEZE_ITINERARY = "view_price_freeze_itinerary";

export const VIEW_HOTEL_PRICE_FREEZE_ITINERARY =
  "cap1_view_hotel_price_freeze_itinerary";

export interface ViewHotelPriceFreezeItinerary {
  lob: string;
  hotel_freeze_deposit_usd?: number;
  hotel_freeze_nightly_price_usd?: number;
  hotel_freeze_total_price_usd?: number;
  hotel_price_freeze_cap_usd?: number;
  hotel_price_freeze_state?: string;
  hotel_price_freeze_duration?: number;
  exp_timestamp?: number;
  hotel_price_freeze_exp_timestamp?: string;
}

export const getViewHotelPriceFreezeItineraryProperties = (
  voucher: Voucher | undefined,
  hotelNightlyPricing: FrozenHotelPricing | undefined,
  hotelTotalPricing: FrozenHotelPricing | undefined,
  hotelAction: Action | undefined
): ViewHotelPriceFreezeItinerary => {
  return {
    lob: "hotel",
    hotel_freeze_deposit_usd: voucher?.deposit.fiat.value,
    hotel_freeze_nightly_price_usd: hotelNightlyPricing?.frozen.fiat.value,
    hotel_freeze_total_price_usd: hotelTotalPricing?.frozen.fiat.value,
    hotel_price_freeze_cap_usd: hotelTotalPricing?.cap.fiat.value,
    hotel_price_freeze_state: voucher?.state.State,
    hotel_price_freeze_duration: hotelAction?.expiration
      ? dayjs(hotelAction.expiration).diff(dayjs(), "d")
      : undefined,
    exp_timestamp: dayjs(hotelAction?.expiration).unix(),
    // expiration for hotel PF is always at 23:59 of a certain date based on UTC,
    // so we convert the time to UTC first, and extract the date only.
    hotel_price_freeze_exp_timestamp: dayjs(hotelAction?.expiration)
      .utcOffset(0)
      .format("YYYY-MM-DD"),
  };
};

export interface ViewPriceFreezeItinerary {
  price_freeze_entry: "hp_banner" | "frozen_prices";
  advance: number;
  trip_type: "round_trip" | "one_way" | "multi_city";
  fare_class:
    | "BasicEconomy"
    | "Economy"
    | "Business"
    | "PremiumEconomy"
    | "First";
  departure_date: string;
  return_date?: string;
  price_freeze_flow: boolean;
  price_freeze_id: ID;
  price_freeze_duration: number;
  price_freeze_pax_total: number;
  time_since_price_freeze_purchase?: number;
  price_freeze_shown_OTM_total_usd: number;
  price_freeze_cap_hit: boolean;
  frozen_price_total_usd: number;
  price_freeze_total_cost: number;
  price_freeze_otm_cap_usd: number;
  price_freeze_cost_per_pax: number;
  price_freeze_expiration_date: string;
}

export interface HotelPriceFreezeRefundProperties {
  hotel_price_freeze_refund_total_usd?: number;
  success?: boolean;
}

interface IHotelPriceFreezeRefundProps {
  statement?: LodgingPriceFreezeGetCreditsStatement;
  voucher?: Voucher;
  pricingsFromGetDetail?: CurrentHotelPricingList;
  refundResult?: LodgingPriceFreezeRefundResult;
}

export const getHotelPriceFreezeRefundProperties = (
  args: IHotelPriceFreezeRefundProps
): HotelPriceFreezeRefundProperties => {
  const { voucher, refundResult } = args;
  const isRefundSuccessful =
    refundResult?.Result === ResultEnum.Ok &&
    refundResult.value.RefundResponse ===
      LodgingPriceFreezeRefundResponseEnum.Success;

  return {
    hotel_price_freeze_refund_total_usd: voucher?.deposit.fiat.value,
    success: !!refundResult ? isRefundSuccessful : undefined,
  };
};

export const HOTEL_PRICE_FREEZE_REFUND_CHOICE =
  "hotel_price_freeze_refund_choice";

export interface HotelPriceFreezeRefundChoiceProperties
  extends Pick<
      HotelPriceFreezeRefundProperties,
      "hotel_price_freeze_refund_total_usd"
    >,
    HotelPriceFreezeExerciseProperties {}

export const getHotelPriceFreezeRefundChoiceProperties = (
  args: IHotelPriceFreezeRefundProps
): HotelPriceFreezeRefundChoiceProperties => {
  const hotelPriceFreezeRefundProperties =
    getHotelPriceFreezeRefundProperties(args);
  const hotelPriceFreezeExerciseProperties =
    getHotelPriceFreezeExerciseProperties({
      isPriceFreezeExerciseFlow: true,
      statement: args.statement,
      voucher: args.voucher,
      pricingsFromGetDetail: args.pricingsFromGetDetail,
    });
  return {
    hotel_price_freeze_refund_total_usd:
      hotelPriceFreezeRefundProperties.hotel_price_freeze_refund_total_usd,
    ...hotelPriceFreezeExerciseProperties,
  };
};

export const HOTEL_PRICE_FREEZE_REFUND_OUTCOME =
  "hotel_price_freeze_refund_outcome";

export interface HotelPriceFreezeRefundOutcomeProperties
  extends HotelPriceFreezeRefundProperties,
    HotelPriceFreezeExerciseProperties {}

export const getHotelPriceFreezeRefundOutcomeProperties = (
  args: IHotelPriceFreezeRefundProps
): HotelPriceFreezeRefundOutcomeProperties => {
  const hotelPriceFreezeRefundProperties =
    getHotelPriceFreezeRefundProperties(args);
  const hotelPriceFreezeExerciseProperties =
    getHotelPriceFreezeExerciseProperties({
      isPriceFreezeExerciseFlow: true,
      statement: args.statement,
      voucher: args.voucher,
      pricingsFromGetDetail: args.pricingsFromGetDetail,
    });
  return {
    hotel_price_freeze_refund_total_usd:
      hotelPriceFreezeRefundProperties.hotel_price_freeze_refund_total_usd,
    success: hotelPriceFreezeRefundProperties.success,
    ...hotelPriceFreezeExerciseProperties,
  };
};

export const BOOK_HOTEL_PRICE_FREEZE = "cap1_book_hotel_price_freeze";

export interface BookHotelPrizeFreeze {
  lob: string;
  price_freeze_id: string;
  price_freeze_flow: boolean;
  advance: number;
}

export type HotelPriceFreezePurchaseEntry = "hotel_details" | "info_modal";

export interface HotelPriceFreezePurchaseProperties {
  price_freeze_entry?: HotelPriceFreezePurchaseEntry;
  confirmation_id?: string;
  total_rewards_amount_usd?: number;
  total_card_amount_usd?: number;
  card_product_used?: string;
  rewards_product_used?: string;
  rewards_currency?: string;
}

interface IHotelPriceFreezePurchaseProps {
  priceFreezeEntry?: HotelPriceFreezePurchaseEntry;
  payments?: PaymentOpaqueValue[];
  cardProduct?: string;
  rewardsProduct?: string;
  confirmationId?: string;
}

export const getHotelPriceFreezePurchaseProperties = (
  args: IHotelPriceFreezePurchaseProps
): HotelPriceFreezePurchaseProperties => {
  const {
    priceFreezeEntry,
    payments,
    cardProduct,
    rewardsProduct,
    confirmationId,
  } = args;
  /*
                paymentAmount: {
                  currency: creditCardPayment!.currencyCode, // <------
                  amount: roundToTwoDecimals(creditCardPayment!.value), // <------
                },
              */
  const cardPayment = payments?.find(
    (payment) => payment.value?.PaymentV2 == PaymentV2Enum.UserCard
  )?.value?.paymentAmount;
  /*
                paymentAmount: {
                  rewardsAccountId: rewardsAccountId!,
                  fiatValue: {
                    amount: roundToTwoDecimals(rewardsFiatPayment!.value),
                    currency: rewardsFiatPayment!.currencyCode,
                  },
                  rewardsPrice: {
                    value: roundToTwoDecimals(rewardsPayment!.value), // <------
                    currency: rewardsPayment!.currency, // <------
                  },
                },
              */
  const rewardsPayment = payments?.find(
    (payment) => payment.value?.PaymentV2 == PaymentV2Enum.Rewards
  )?.value?.paymentAmount?.rewardsPrice;

  return {
    price_freeze_entry: priceFreezeEntry,
    confirmation_id: confirmationId,
    total_rewards_amount_usd: rewardsPayment?.value,
    total_card_amount_usd: cardPayment?.amount,
    card_product_used: cardProduct,
    rewards_product_used: rewardsProduct,
    rewards_currency: rewardsPayment?.currency,
  };
};

export const HOTEL_PRICE_FREEZE_REVIEW_DETAILS =
  "cap1_hotel_price_freeze_review_details";

export const HOTEL_PRICE_FREEZE_EDIT_EMAIL =
  "cap1_hotel_price_freeze_edit_email";

export const HOTEL_PRICE_FREEZE_CLICK_ADD_PAYMENT =
  "cap1_hotel_price_freeze_click_add_payment";

export const HOTEL_PRICE_FREEZE_SELECT_PAYMENT =
  "cap1_hotel_price_freeze_select_payment";

export const HOTEL_PRICE_FREEZE_CLICKED_COMPLETE_BUY =
  "cap1_hotel_price_freeze_clicked_complete_buy";

export const HOTEL_PRICE_FREEZE_COMPLETE_BUY =
  "cap1_hotel_price_freeze_complete_buy";

export const HOTEL_PRICE_FREEZE_VIEWED_CONFIRMATION =
  "cap1_hotel_price_freeze_viewed_confirmation";

export const HOTEL_CLICKED_PRICE_FREEZE = "cap1_hotel_clicked_price_freeze";

export interface HotelPriceFreezeEntryProperties {
  price_freeze_entry?: HotelPriceFreezePurchaseEntry;
}

export interface HotelPriceFreezeInfoModalEntryProperties {
  info_modal_entry?: "choose_room_cta" | "offer_on_date";
}

export interface HotelClickedPriceFreezeProperties
  extends HotelPriceFreezeEntryProperties {
  success: boolean;
  position: "bottom_nav" | "right_nav" | "inline";
  lob: string;
}

export const HOTEL_PRICE_FREEZE_VIEW_INFO_MODAL =
  "cap1_hotel_price_freeze_view_info_modal";

export const HOTEL_PRICE_FREEZE_CLICK_INFO_MODAL_CONTINUE =
  "cap1_hotel_price_freeze_click_info_modal_continue";

export interface HotelPriceFreezeViewInfoModalProperties
  extends HotelPriceFreezeEntryProperties,
    HotelPriceFreezeInfoModalEntryProperties,
    HotelPriceFreezePurchaseOfferProperties {}

export const BOOK_PRICE_FREEZE = "book_price_freeze";

export const PRICE_FREEZE_REFRESH = "price_freeze_refresh";

export interface PriceFreezeRefresh {
  shop_advance: number;
  price_freeze_id: ID;
  price_freeze_duration: number;
  price_freeze_pax_total: number;
  time_since_price_freeze_purchase?: number;
  frozen_product_found: boolean;
  Carrier: string;
  fc_has_fares: boolean;
  fc_one_fare_match: boolean;
  fc_selected_fare_match: boolean;
  fc_selected_upgradable: boolean;
  fc_selected_upgradable_difference_usd: boolean;
}

export const CAP1_VIEW_BOOKING_ADD_ON = "cap1_view_booking_add_on";

export const CFAR_ADD_ON_CHOICE = "cfar_add_on_choice";

export const CHFAR_ADD_ON_CHOICE = "chfar_add_on_choice";

export const DISRUPTION_ADD_ON_CHOICE = "disruption_add_on_choice";

export interface CfarAttachProperties {
  charged_cfar_total?: number;
  charged_cfar_per_pax?: number;
  offered_cfar_per_pax?: number;
  cfar_product_id?: string;
  cfar_quote_id?: string;
  offered_cfar_product_id?: string[];
  attached_cfar_product_id?: string;
  cfar_policy_id?: string;
  offered_cfar_policy_id?: string[];
  attached_cfar_policy_id?: string;
  cfar_coverage_percentage?: number;
  cfar_premium_percentage?: number;
  cfar_choice?: 1 | 0 | "none";
  cancellation_policy?:
    | "non_refundable"
    | "FTC_no_fees"
    | "FTC_fees"
    | "refundable";
  cfar_shop_failed?: boolean;
  success?: boolean;
  is_multi_ticket: boolean;
  cfar_discount_original_premium?: number;
  cfar_discount_percentage?: number;
}

export interface RefundableFaresProperties {
  refundable_flights?: number;
  // now that we are supporting CoMerch
  cfar_choice?: 2 | 1 | 0 | null;
  cfar_fare_choice?: 1 | 0 | null;
  cfar_fare_offered?: 1 | 0 | null;
  rf_knockout_price?: number;
  number_of_rf?: number;
  rf_adult_price_total_usd?: number;
}

export interface TripAndFareClassFactsProperties {
  lob?: string;
  origin?: string;
  advance?: number;
  carrier?: string;
  fare_type?: string;
  destination?: string;
  return_date?: string;
  departure_date?: string;
  total_stops?: number;
  price_per_pax?: number;
  searched_pax_total?: number;
  fare_class?: string;
}

export const TRIPS_LOADING_COMPLETE = "trips_loading_complete";

// CFAR Exercise Events
export const VIEWED_CFAR_OFFER_OPTIONS = "cap1_viewed_cfar_offer_options";
export const TAPPED_CFAR_OFFER_OPTIONS = "cap1_tapped_cfar_offer_options";
export const VIEWED_CFAR_OFFER = "cap1_viewed_cfar_offer";
export const SELF_SERVE_CLICK_SUPPORT = "self_serve_click_support";
export const SELF_SERVE_CONFIRM_CANCELLATION =
  "self_serve_confirm_cancellation";
export const SELF_SERVE_CANCEL_RESPONSE_SUCCESS =
  "self_serve_cancel_response_success";
export const SELF_SERVE_CANCEL_RESPONSE_FAILURE =
  "self_serve_cancel_response_failure";

export enum CfarCancellationOutcome {
  // CfarCash corresponds to one of the following:
  // 1) cash-only option was returned from the backend
  // 2) choice option was returned from the backend and the user chose cash
  CfarCash = "cfar_cash",

  // CfarFtc = CfarCashOrCfarFtc was returned from the backend and the user chose FTC
  CfarFtc = "cfar_ftc",

  // CfarFtcWithPenalty = CfarCashOrCfarFtcWithPenalty was returned from the backend and the user chose FTC
  CfarFtcWithPenalty = "cfar_ftc_with_penalty",

  CfarCashOrCfarFtc = "cfar_cash_or_cfar_ftc",
  CfarCashOrCfarFtcWithPenalty = "cfar_cash_or_cfar_ftc_with_penalty",
  TicketedVoid = "ticketed_void",
  AirlineFullRefund = "airline_full_refund",
  AirlinePartialRefundOrCfarCash = "airline_partial_refund_or_cfar_cash",
  TooCloseToDeparture = "too_close_to_departure",
  ContactCustomerService = "contact_customer_service",
  CancellationPending = "cancellation_pending",
  Unrecognized = "unrecognized", // Response returned is unrecognized
  Error = "error", // Cannot fetch the response successfully
}

export enum CfarOfferChoice {
  CfarCash = "cfar_cash",
  CfarFtc = "cfar_ftc",
  CfarFtcWithPenalty = "cfar_ftc_with_penalty",
  Error = "error",
}

export enum CfarEvents {
  RebookingFeeWaived = "cfar_ftcexchange_rebookfeewaived",
}

export interface Cap1HotelCfarExerciseFactsProperties {
  cancel_scenario?: SelfServeCancelScenario;
  cfar_outcome?: CfarCancellationOutcome;
  total_price_usd?: number;
  price_per_night?: number;
  subtotal_usd?: number;
  base_total_usd?: number;
  charged_cfar_total_usd?: number;
  charged_cfar_per_night?: number;
  rooms_booked?: number;
  cancellation_policy?: HotelCfarCancelScenarioEnum;
  advance?: number;
  days_until_check_in?: number;
  cfar_fee_is_refundable?: boolean;
}

export const getHotelCfarExerciseFactsFromCfarContractAndReservation = (props: {
  reservation: Reservation;
  cfar?: HotelCfarContract;
  cfarCancelScenario?: HotelCfarCancelScenario;
}): Cap1HotelCfarExerciseFactsProperties => {
  const { reservation, cfar, cfarCancelScenario } = props;
  const { pricing, bedTypes, checkInDate, checkOutDate } = reservation;
  const { payNowTotal, subtotal, taxes } = pricing;
  const { premium } = cfar ?? {};
  const basePriceWithTaxes = subtotal.fiat.value + taxes.fiat.value;
  const cfarFee = premium?.amount.fiat.value;
  const nights = dayjs(checkOutDate).diff(checkInDate, "day");

  return {
    cancel_scenario: SelfServeCancelScenario.CfarCancel,
    cfar_outcome: CfarCancellationOutcome.CfarCash,
    // price paid with cfar fee and any other add ons included
    total_price_usd: payNowTotal.fiat.value,
    // price paid with cfar fee included
    price_per_night:
      nights > 0 && cfarFee !== undefined
        ? (basePriceWithTaxes + cfarFee) / nights
        : undefined,
    // price paid without cfar fee or any other add ons
    subtotal_usd: basePriceWithTaxes,
    // price paid without taxes and fees or any other add ons
    base_total_usd: subtotal.fiat.value,
    // cfar fee
    charged_cfar_total_usd: cfarFee,
    // cfar fee per night
    charged_cfar_per_night:
      nights > 0 && cfarFee !== undefined ? cfarFee / nights : undefined,
    rooms_booked: bedTypes.rooms.length,
    cancellation_policy: cfarCancelScenario?.HotelCfarCancelScenario,
    advance: dayjs(reservation.checkInDate).diff(dayjs(cfar?.purchased), "d"),
    days_until_check_in: dayjs(reservation.checkInDate).diff(dayjs(), "d"),
    cfar_fee_is_refundable:
      cfarCancelScenario?.HotelCfarCancelScenario ===
      HotelCfarCancelScenarioEnum.CfarRefundable
        ? !!cfarCancelScenario.shouldRefundCfarPremium
        : undefined,
  };
};

// TRAVEL WALLET EVENTS
export interface TravelWalletOfferTrackingProperties {
  applicable_air?: boolean;
  applicable_cars?: boolean;
  applicable_hotels?: boolean;
  offer_amount_usd?: number;
  offer_expiration_date?: string;
  offer_expiration_days?: number;
  offer_id?: string;
  offer_name?: string;
  offer_type?: string;
  program_id?: string;
}

export interface SharedTravelOfferTrackingProperties {
  has_offer?: boolean;
  offer_count?: number;
  offer_used?: boolean;
  offer_amount_redeemed_usd?: number;
}

export const SELECTED_TRAVEL_OFFER = "selected_offer";

export enum SelectedTravelOfferScreen {
  FLIGHT_SEARCH = "flight_search",
  FLIGHT_SHOP = "flight_shop",
  FLIGHT_REVIEW = "flight_review",
  FLIGHT_CHECKOUT = "flight_checkout",
  HOTEL_SEARCH = "hotel_search",
  HOTEL_SHOP = "hotel_shop",
  HOTEL_CUSTOMIZE = "hotel_customize",
  HOTEL_DETAILS = "hotel_details",
  HOTEL_CHECKOUT = "hotel_checkout",
  HOTEL_PRICE_FREEZE_PURCHASE = "hotel_price_freeze_purchase",
  CARS_SEARCH = "cars_search",
  CARS_SHOP = "cars_shop",
  CARS_REVIEW = "cars_review",
  CARS_CHECKOUT = "cars_checkout",
  TRAVEL_WALLET_LANDING = "travel_wallet_landing",
  PREMIUM_STAYS_SHOP = "premium_stays_shop",
  PREMIUM_STAYS_CUSTOMIZE = "premium_stays_customize",
  PREMIUM_STAYS_DETAILS = "premium_stays_details",
  PREMIUM_STAYS_CHECKOUT = "premium_stays_checkout",
  PACKAGES_CHECKOUT = "packages_checkout",
  PACKAGES_HOTEL_SHOP = "packages_hotel_shop",
  EXPERIENCES_SEARCH = "experiences_search",
  EXPERIENCES_SHOP = "experiences_shop",
  EXPERIENCES_CHECKOUT = "experiences_checkout",
}

export interface SelectedTravelOfferProperties
  extends SharedTravelOfferTrackingProperties {
  screen: SelectedTravelOfferScreen;
}

export const VIEWED_OFFERS_LIST = "viewed_offers_list";

export const VIEWED_OFFER_FAQ = "viewed_offer_faq";

export const ENGAGED_OFFER_CTA = "engaged_offer_cta";

export const VIEWED_OFFER_BANNER = "viewed_offer_banner";

// Travel sale
export const VIEWED_SALE_PAGE = "viewed_sale_page";

export interface ViewedSalePageTrackingProperties {
  entry_type?: string;
}

export const SALE_FILTER_APPLIED = "sale_filter_applied";

export interface SaleFilterAppliedTrackingProperties {
  filter_type: string;
}

export const VIEWED_SALE_OFFER_MODAL = "viewed_sale_offer_modal";

export interface ViewedSaleOfferModalTrackingProperties {
  location_brand: string;
  funnel: string;
}

export const CLICKED_SALE_OFFER_CTA = "clicked_sale_offer_cta";

export interface ClickedSaleOfferCTATrackingProperties {
  location_brand: string;
  funnel: string;
}

// DP User Preferences

export const DISRUPTION_OPT_IN_EVENT_NAME = "messaging_opt_in";
export const DISRUPTION_SOURCE = "disruption";

export interface DisruptionOptInProperties {
  messaging_source?: string;
  messaging_channel_preference_app_notif?: boolean;
  messaging_channel_preference_sms?: boolean;
}

// DP Exercise Events

export const SELECTED_DISRUPTION = "selected_disruption";
export const VIEWED_DISRUPTION_PAGE = "viewed_disruption_page";
export const MISSED_CONNECTION_REDEEM = "missed_connection_redeem";
export const DELAYS_REDEEM = "delays_redeem";
export const DISRUPTION_REDEEM_CHOICE_REFUND =
  "disruption_redeem_choice_refund";
export const DISRUPTION_REDEEM_CHOICE_REBOOK =
  "disruption_redeem_choice_rebook";
export const VIEWED_REFUND_CHOICE = "viewed_refund_choice";
export const CONFIRM_REFUND_CHOICE = "confirm_refund_choice";
export const CAP1_DISRUPTION_POLICY_EXERCISED =
  "cap1_disruption_policy_exercised";
export const CAP1_VIEWED_REBOOKING_CHOICE = "cap1_viewed_rebooking_choice";
export const CAP1_SELECTED_REBOOKING_CHOICE = "cap1_selected_rebooking_choice";
export const CAP1_VIEWED_REBOOKING_FLIGHT_LIST =
  "cap1_viewed_rebooking_flight_list";
export const CAP1_REBOOKING_VIEWED_SLICE = "cap1_rebooking_viewed_slice";
export const CAP1_REBOOKING_CONFIRMED_SLICE = "cap1_rebooking_confirmed_slice";
export const CAP1_REBOOKING_REVIEW_DETAILS = "cap1_rebooking_review_details";
export const CAP1_REBOOKING_COMPLETE_BUY = "cap1_rebooking_complete_buy";

export interface Cap1DpExerciseFactsProperties {
  active_disruption?:
    | "missed_connection"
    | "delay"
    | "involuntary_cancel"
    | "none";
  advance?: number;
  delay_duration?: number | "none";
  departure_date?: string;
  destination?: string;
  disruption_attached?: number;
  disruption_product?: "missed_connections" | "delays" | "both";
  disruption_rebook_costs?: number;
  disruption_refund_costs?: number;
  itinerary_departure_date?: string;
  itinerary_return_date?: string;
  origin?: string;
  outbound_segments?: number;
  outbound_stops?: number;
  page?: "disruption-page" | "rebook-flight-list";
  product_redeem_choice?: "missed_connection" | "delay";
  rebook_destination?: string;
  rebook_origin?: string;
  return_segments?: number;
  return_stops?: number;
  total_stops?: number;
}

export type PartialFlightsListDpExerciseFacts = Pick<
  Cap1DpExerciseFactsProperties,
  "disruption_attached" | "disruption_product"
>;

export type PartialEligibilityDpExerciseFacts = Pick<
  Cap1DpExerciseFactsProperties,
  "active_disruption" | "delay_duration"
>;

export type PartialDisruptedFlightDpExerciseFacts = Pick<
  Cap1DpExerciseFactsProperties,
  | "departure_date"
  | "itinerary_departure_date"
  | "itinerary_return_date"
  | "advance"
  | "destination"
  | "origin"
  | "outbound_segments"
  | "outbound_stops"
  | "return_segments"
  | "return_stops"
  | "total_stops"
>;

export const getPartialEligibilityDpExerciseFactsPropertiesFromEligibility = (
  eligibility: ExerciseEligibilityV1 | undefined
): PartialEligibilityDpExerciseFacts => {
  const disruptedSlice = (() => {
    switch (eligibility?.ExerciseEligibilityV1) {
      case ExerciseEligibilityV1Enum.IsEligible:
        return getEligibleSliceFromEligibility(eligibility);
      case ExerciseEligibilityV1Enum.NotEligible:
      default:
        return undefined;
    }
  })();
  const disruptedSegment = disruptedSlice?.slice.segments.find(
    (segment) =>
      segment.EligibilitySegment === EligibilitySegmentEnum.IsEligible
  );
  const reasons = (() => {
    const disruption = disruptedSegment?.disruption;
    switch (disruption?.FlightDisruption) {
      case FlightDisruptionEnum.IsDisrupted:
        return disruption.reasons;
      default:
        return undefined;
    }
  })();

  if (reasons) {
    const reason = reasons[0];
    switch (reason?.Reason) {
      case FlightDisruptionReasonEnum.Delayed:
        return {
          active_disruption: "delay",
          // note: use dayjs here to convert the duration into seconds; though we might need to know whether we want to add dayjs as a dependency or not.
          delay_duration: reason.inMinutes * 60,
        };
      case FlightDisruptionReasonEnum.MissedConnection:
        return {
          active_disruption: "missed_connection",
          delay_duration: reason.inMinutes * 60,
        };
      case FlightDisruptionReasonEnum.AirlineCancellation:
        return {
          active_disruption: "involuntary_cancel",
          delay_duration: "none",
        };
      default:
        return {
          active_disruption: "none",
          delay_duration: "none",
        };
    }
  }

  return {
    active_disruption: "none",
    delay_duration: "none",
  };
};

export interface Cap1DpOfferFactsProperties {
  disruption_product?: "delays" | "missed_connections";
  disruption_choice?: "0" | "1" | "none";
  disruption_per_pax?: number;
  disruption_policy_id?: string;
  disruption_product_id?: string;
  disruption_total?: number;
  charged_disruption_total_usd?: number;
  charged_disruption_per_pax?: number;
  outbound_segments?: number;
  outbound_stops?: number;
  return_segments?: number;
  return_stops?: number;
  total_stops?: number;
}

export type Cap1DisruptionAddOnChoiceProperties = Pick<
  Cap1DpOfferFactsProperties,
  | "disruption_product"
  | "disruption_choice"
  | "disruption_per_pax"
  | "disruption_policy_id"
  | "disruption_product_id"
  | "disruption_total"
  | "outbound_segments"
  | "outbound_stops"
  | "return_segments"
  | "return_stops"
  | "total_stops"
>;

export interface Cap1DpExerciseItineraryFactsProperties {
  success?: boolean;
  exercise_choice?: "refund" | "rebook";
  base_total_usd?: number;
  advance?: number;
  carrier?: string;
  origin?: string;
  destination?: string;
  departure_date?: string;
  return_date?: string;
  itinerary_create_date?: string;
  outbound_local_departure_date?: string;
  outbound_segments?: number;
  outbound_stops?: number;
  return_segments?: number;
  return_stops?: number;
  disruption_new_system_locator?: string;
  disruption_original_agent_locator?: string;
  disruption_original_system_locator?: string;
  disruption_original_trip_cost?: number;
  disruption_policy_id?: string;
  disruption_premium?: number;
  disruption_product_id?: string;
  multi_ticket_type?: string;
  pax_total?: number;
  price_per_pax_usd?: number;
  rebooking_direction?: "outbound" | "return";
  rebooking_flow?: "missed" | "delay" | "disruption";
  rebooking_tool?: boolean;
  subtotal_usd?: number;
  total_price?: number;
  total_price_usd?: number;
  trip_type?: "one_way" | "round_trip";
  total_stops?: number;
}

export type PartialOriginalItineraryDpExerciseItineraryFacts = Pick<
  Cap1DpExerciseItineraryFactsProperties,
  "rebooking_direction" | "rebooking_flow" | "trip_type" | "departure_date"
>;

export type PartialRebookedItineraryDpExerciseItineraryFacts = Pick<
  Cap1DpExerciseItineraryFactsProperties & Cap1DpExerciseFactsProperties,
  | "departure_date"
  | "return_date"
  | "price_per_pax_usd"
  | "rebooking_direction"
  | "rebooking_flow"
  | "rebooking_tool"
  | "subtotal_usd"
  | "total_price"
  | "total_price_usd"
  | "trip_type"
  | "disruption_rebook_costs"
  | "rebook_origin"
  | "rebook_destination"
>;

export type PartialRebookedItineraryDpDisruptionPolicyExercisedFacts = Pick<
  Cap1DpExerciseItineraryFactsProperties & Cap1DpExerciseFactsProperties,
  | "pax_total"
  | "exercise_choice"
  | "base_total_usd"
  | "disruption_new_system_locator"
  | "disruption_original_agent_locator"
  | "disruption_original_system_locator"
  | "disruption_original_trip_cost"
  | "disruption_policy_id"
  | "disruption_premium"
  | "disruption_product_id"
  | "product_redeem_choice"
>;

export type PartialRebookedItineraryDpRebookingCompleteBuyFacts = Pick<
  Cap1DpExerciseItineraryFactsProperties & Cap1DpExerciseFactsProperties,
  | "advance"
  | "carrier"
  | "destination"
  | "origin"
  | "outbound_segments"
  | "outbound_stops"
  | "return_segments"
  | "return_stops"
  | "rebook_origin"
  | "rebook_destination"
>;

export interface Cap1DpExerciseFlightsListFactsProperties {
  flights_shown?: number;
  fares_shown_outbound?: number;
  fares_shown_return?: number;
  rebooking_tool_filter?: "today" | "tomorrow";
  searched_pax_total?: number;
  searched_pax_total_infant_lap?: number;
  searched_pax_total_seated?: number;
  selected_pax_lap_infants?: number;
  selected_pax_seat_infants?: number;
  selected_pax_seated?: number;
  selected_pax_total?: number;
  slice_direction?: string;
  rebook_origin?: string;
  rebook_destination?: string;
}

export type PartialRebookSummaryDpExerciseFlightsListFacts = Pick<
  Cap1DpExerciseFlightsListFactsProperties,
  | "flights_shown"
  | "fares_shown_outbound"
  | "fares_shown_return"
  | "rebooking_tool_filter"
>;

export type PartialPassengerDpExerciseFlightsListFacts = Pick<
  Cap1DpExerciseFlightsListFactsProperties,
  | "searched_pax_total"
  | "searched_pax_total_infant_lap"
  | "searched_pax_total_seated"
  | "selected_pax_lap_infants"
  | "selected_pax_seat_infants"
  | "selected_pax_seated"
  | "selected_pax_total"
>;

// PC EVENTS

// HOTEL EVENTS
export const LC_ENTRY = "lc_entry";

export const PC_ENTRY = "pc_entry";

export interface PremierCollectionEntryProperties {
  delegated_to: string;
  first_launch: boolean;
  market: string;
  country: string;
  number_of_properties: number;
  number_of_guests: number;
  hotel_advance: string;
  rooms_searched: number;
  has_credits: boolean;
  credit_balance: number;
  vx_statement_credit_balance: number;
  rewards_accounts?: string;
  customer_account_role?: string;
  card_on_file?: boolean;
  is_mega_menu?: boolean;
}

export const VIEWED_PC_LIST = "viewed_pc_list";

export interface ViewedPremierCollectionListProperties
  extends PremierCollectionEntryProperties,
    TravelWalletOfferTrackingProperties,
    SharedTravelOfferTrackingProperties {
  account_type_selected: string;
  account_use_type?: string;
  customer_account_role?: string;
  account_allow_rewards_redemption?: boolean;
  unavailable_hotels: number;
  available_hotels: number;
  nth_night_unlocked_count?: number;
  nth_night_locked_count?: number;
}

export const VIEWED_UNAVAILABLE_PC_LIST = "viewed_unavailable_pc_list";

export interface ViewedUnavailablePremierCollectionListProperties
  extends ViewedPremierCollectionListProperties {}

export const APPLIED_PC_FILTER = "applied_pc_filter";

export interface AppliedPremierCollectionFilterProperties {
  filter_type: "stars" | "price" | "hotel_name" | "amenities";
}

export const SELECTED_PC_FROM_LIST = "selected_pc_from_list";

export interface SelectedPremierCollectionFromListProperties
  extends ViewedPremierCollectionListProperties {
  is_preferred_cot: boolean;
  nth_night_promo?: string;
}

export const SELECTED_PC_FROM_MAP = "selected_pc_from_map";

export interface SelectedPremierCollectionFromMapProperties
  extends ViewedPremierCollectionListProperties {
  is_preferred_cot: boolean;
}

export const VIEWED_DATELESS_HOTEL_DETAILS = "viewed_dateless_hotel_details";
export const VIEWED_UNAVAILABLE_DATELESS_HOTEL_DETAILS =
  "viewed_unavailable_dateless_hotel_details";

export const VIEWED_LC_DETAILS = "viewed_lc_details";

export const VIEWED_PC_DETAILS = "viewed_pc_details";

export interface ViewedPremierCollectionDetailsProperties
  extends ViewedPremierCollectionListProperties {
  account_type_selected: string;
  rooms_shown: number;
  is_preferred_cot: boolean;
  nth_night_promo?: string;
}

export const SELECT_LC_ROOM = "select_lc_room";

export const SELECT_PC_ROOM = "select_pc_room";

export interface SelectPremierCollectionRoomProperties
  extends ViewedPremierCollectionDetailsProperties {
  room_type: string;
  account_type_selected: string;
}

export const REVIEW_DETAILS_LC_CHECKOUT = "review_details_lc_checkout";

export const REVIEW_DETAILS_PC_CHECKOUT = "review_details_pc_checkout";

export interface ReviewDetailsPremierCollectionCheckoutProperties
  extends ViewedPremierCollectionListProperties {
  lodging_name: string;
  market: string;
  chain: string;
  brand: string;
  length_of_stay: number;
  sell_price_excluding_taxes_and_fees_usd: number;
  owed_now_excluding_taxes_and_fees: number;
  owed_now_taxes_and_fees: number;
  owed_at_checkin_taxes_and_fees: number;
  pay_later_excluding_taxes_and_fees: number;
  pay_later_taxes_and_fees: number;
  pay_later_fees: number;
  total_discount: number;
  refundability: "non_refundable" | "free_cancellation";
  supply_revenue_usd: number;
  check_in_date: string;
  check_out_date: string;
  supplier: string;
  hotel_advance: string;
  provider_type: "direct" | "retail" | "wholesale";
  adr: string;
  is_preferred_cot: boolean;
}

export const PC_SAVE_AND_CONTINUE_CLICKED = "pc_save_and_continue_clicked";

export const PRICE_QUOTE_LC = "price_quote_lc";

export const PRICE_QUOTE_PC = "price_quote_pc";

export interface PriceQuotePremierCollectionProperties
  extends ReviewDetailsPremierCollectionCheckoutProperties {
  success: boolean;
  booking_session_id: string;
  lodging_row_index?: number;
}

export const COMPLETE_BUY_LC = "complete_buy_lc";

export interface CompleteBuyLifestyleCollectionProperties
  extends CompleteBuyPremierCollectionProperties {}

export const COMPLETE_BUY_PC = "complete_buy_pc";

export interface CompleteBuyPremierCollectionProperties
  extends ReviewDetailsPremierCollectionCheckoutProperties,
    DatelessSearchProperties {
  success: boolean;
  total_rewards_amount_usd: number;
  total_card_amount_usd: number;
  card_product_used: string;
  rewards_product_used: string;
  rewards_currency: string; // ie. “Miles”, “Cash”, “Points”
  agent_booking_fee_amount_usd: number;
  booking_session_id: string;
  reservation_id: string;
  lodging_row_index?: number;
  is_recommended?: boolean;
  rooms_booked: number;
  credit_redeemed: boolean;
  credit_amt_redeemed: number;
  is_vcn: boolean;
  purpose_of_travel: string;
  nth_night_promo?: string;
}

export interface DatelessSearchProperties {
  is_dateless?: boolean;
}

// MEGAMENU EVENTS
export const MEGA_MENU_ENTRY = "mega_menu_entry";

export interface MegaMenuEntryProperties {
  is_expanded?: boolean;
}

// VR EVENTS
export const VR_TOGGLE_SELECTED = "vr_toggle_selected";

export const VR_ENTRY = "vr_entry";

export interface VacationRentalEntryProperties {
  adults_count: number;
  advance?: number;
  check_in_date?: string;
  check_out_date?: string;
  children_count: number;
  pets_count?: number;
  delegated_to: string;
  destination_city?: string;
  destination_country?: string;
  los?: number;
  first_launch: boolean;
  has_credits: boolean;
  credit_balance: number;
  vx_statement_credit_balance: number;
  rewards_accounts?: string;
  customer_account_role?: string;
  card_on_file?: boolean;
}

export const VIEWED_VR_LIST = "viewed_vr_list";

export interface ViewedVacationRentalListProperties
  extends VacationRentalEntryProperties,
    TravelWalletOfferTrackingProperties,
    SharedTravelOfferTrackingProperties {
  market_selection_name?: string;
  account_type_selected: string;
  account_use_type?: string;
  account_allow_rewards_redemption?: boolean;
}

export const SELECTED_VR_FROM_LIST = "selected_vr_from_list";

export interface SelectedVacationRentalFromListProperties
  extends ViewedVacationRentalListProperties {
  vr_type: string;
  vr_occupancy: number;
  vr_pet_friendly: boolean;
  vr_market: string;
  vr_price?: number;
}

export const SELECTED_VR_FROM_MAP = "selected_vr_from_map";

export interface SelectedVacationRentalFromMapProperties
  extends SelectedVacationRentalFromListProperties {}

export const VIEWED_VR_DETAILS = "viewed_vr_details";

export interface ViewedVacationRentalDetailsProperties
  extends ViewedVacationRentalListProperties {
  adr?: number;
  commission_usd?: number;
  // credits_amount_usd?: number;
  // discount_hopper_funded_usd?: number;
  // discount_host_funded_usd?: number;
  // discount_type?: string;
  // discount_usd?: number;
  home_city?: string;
  home_country?: string;
  home_host_name?: string;
  home_id?: string;
  home_state?: string;
  host_service_fee_usd?: number;
  market_id?: string;
  market_name?: string;
  markup_percentage?: number;
  markup_usd?: number;
  net_rate_excluding_taxes_fees_usd?: number;
  number_of_photos?: number;
  taxes_and_fees?: number;
  total_price?: number;
  product_type?: string;
  free_cancellation_shown?: boolean;
  pets_searched?: number;
  google_place_id?: string;
  destination_name?: string;
  lodging_row_index?: number;
  rooms_searched?: number;
  details_entry_source?: string;
  sort_order?: string;
}

export const VIEWED_VR_DETAILS_PHOTO_GALLERY =
  "viewed_vr_details_photo_gallery";
export const VR_DETAILS_PHOTO_GALLERY_IMAGE_LOAD_ERROR =
  "vr_details_photo_gallery_image_load_error";

export const REVIEW_DETAILS_VR_CHECKOUT = "review_details_vr_checkout";

export const COMPLETE_BUY_VR = "complete_buy_vr";

export interface CompleteBuyVacationRentalProperties
  extends ViewedVacationRentalDetailsProperties {
  reservation_id?: string;
  total_card_amount_usd?: number;
  total_rewards_amount_usd?: number;
  success: boolean;
  error_details?: string;
  error_displayed?: string;
}

// CREDITS TRACKING

export const VIEWED_CREDIT_BANNER = "viewed_credit_banner";

export const ENGAGED_CREDIT_CTA = "engaged_credit_cta";

export const VIEW_CREDIT_HISTORY = "view_credit_history";

export const VIEW_TRAVEL_WALLET = "view_travel_wallet";

export const VIEWED_CREDIT_BENEFITS_MODAL = "viewed_credits_benefits_modal";

// HOTEL CFAR
export interface Cap1HotelCfarOfferFactsProperties {
  cfar_premium_percentage?: number;
  cfar_premium_usd?: number;
  cfar_pricing_description?: string;
  cfar_rr_price_total_usd?: number;
  cfar_rr_hotel_choice?: 1 | 0 | "none";
  cfar_choice?: 1 | 0 | "none";
  cfar_fee_is_refundable?: boolean;
  cfar_added_from_modal?: boolean;
}

export type Cap1HotelCfarRRProperties = Pick<
  Cap1HotelCfarOfferFactsProperties,
  | "cfar_rr_hotel_choice"
  | "cfar_choice"
  | "cfar_fee_is_refundable"
  | "cfar_premium_usd"
>;

interface IRefundableRoomProductWithCfarChoices
  extends Pick<
    Cap1HotelCfarOfferFactsProperties,
    "cfar_rr_hotel_choice" | "cfar_choice"
  > {
  refundableRoomProduct: RoomProductWithTransformedIndexes | undefined;
}

const getHotelCfarOfferFactsFromRefundableRoomProduct = ({
  refundableRoomProduct,
  cfar_rr_hotel_choice,
  cfar_choice,
}: IRefundableRoomProductWithCfarChoices): Cap1HotelCfarOfferFactsProperties => {
  if (
    !!refundableRoomProduct?.roomProductVariant &&
    refundableRoomProduct.roomProductVariant.variant ===
      RoomProductType.RefundableRoom
  ) {
    const { totalDiscountAware, total } = refundableRoomProduct;
    const { premiumPrices, pricingDescription, additionalInfo } =
      refundableRoomProduct.roomProductVariant;
    const isCfarFeeRefundable = additionalInfo?.shouldRefundCfarPremium;

    return {
      cfar_premium_percentage:
        total.fiat.value > 0
          ? Math.round((premiumPrices.fiat.value * 100) / total.fiat.value)
          : undefined,
      cfar_premium_usd: premiumPrices.fiat.value,
      cfar_pricing_description: pricingDescription,
      cfar_rr_price_total_usd:
        totalDiscountAware?.priceWithUnmanagedDiscounts.fiat.value ??
        total.fiat.value,
      cfar_rr_hotel_choice,
      cfar_choice,
      cfar_fee_is_refundable: isCfarFeeRefundable,
    };
  }

  return { cfar_rr_hotel_choice, cfar_choice };
};

interface IHotelCfarQuoteWithCfarChoices
  extends Pick<
    Cap1HotelCfarOfferFactsProperties,
    "cfar_rr_hotel_choice" | "cfar_choice"
  > {
  roomProduct: RoomProduct | null;
  hotelCfarQuote: HotelCfarQuote | null;
  additionalInfo: HotelCfarQuoteAdditionalInfo | null;
  hasAddedCfarFromModal?: boolean;
}

const getHotelCfarOfferFactsFromHotelCfarQuote = ({
  roomProduct,
  hotelCfarQuote,
  additionalInfo,
  cfar_rr_hotel_choice,
  cfar_choice,
  hasAddedCfarFromModal,
}: IHotelCfarQuoteWithCfarChoices): Cap1HotelCfarOfferFactsProperties => {
  const isCfarFeeRefundable = additionalInfo?.shouldRefundCfarPremium;

  if (!roomProduct) {
    return {
      cfar_rr_hotel_choice,
      cfar_choice,
      cfar_fee_is_refundable: isCfarFeeRefundable,
    };
  }

  if (hotelCfarQuote) {
    const { total } = roomProduct;
    const { premiumPrices, pricingDescription } = hotelCfarQuote;

    return {
      cfar_premium_percentage:
        total.fiat.value > 0
          ? Math.round((premiumPrices.fiat.value * 100) / total.fiat.value)
          : undefined,
      cfar_premium_usd: premiumPrices.fiat.value,
      cfar_pricing_description: pricingDescription,
      cfar_rr_hotel_choice,
      cfar_choice,
      cfar_fee_is_refundable: isCfarFeeRefundable,
      cfar_added_from_modal: hasAddedCfarFromModal,
    };
  }

  return {
    cfar_rr_hotel_choice,
    cfar_choice,
    cfar_fee_is_refundable: isCfarFeeRefundable,
  };
};

export type PartialViewedHotelDetailsHotelCfarOfferFacts = Pick<
  Cap1HotelCfarOfferFactsProperties,
  | "cfar_premium_percentage"
  | "cfar_premium_usd"
  | "cfar_pricing_description"
  | "cfar_rr_price_total_usd"
>;

export const getViewedHotelDetailsHotelCfarOfferFacts = (
  refundableRoomProduct: RoomProductWithTransformedIndexes | undefined
): PartialViewedHotelDetailsHotelCfarOfferFacts => {
  const hotelCfarOfferFacts = getHotelCfarOfferFactsFromRefundableRoomProduct({
    refundableRoomProduct,
  });
  return {
    cfar_premium_percentage: hotelCfarOfferFacts.cfar_premium_percentage,
    cfar_premium_usd: hotelCfarOfferFacts.cfar_premium_usd,
    cfar_pricing_description: hotelCfarOfferFacts.cfar_pricing_description,
    cfar_rr_price_total_usd: hotelCfarOfferFacts.cfar_rr_price_total_usd,
  };
};

export type PartialSelectHotelRoomHotelCfarOfferFacts =
  Cap1HotelCfarOfferFactsProperties;

export const getSelectHotelRoomHotelCfarOfferFacts = (
  props: IRefundableRoomProductWithCfarChoices
): PartialSelectHotelRoomHotelCfarOfferFacts => {
  return getHotelCfarOfferFactsFromRefundableRoomProduct(props);
};

export const CAP1_HOTEL_VIEWED_ADD_ON = "cap1_hotel_viewed_add_on";

export type HotelViewedAddOnHotelCfarOfferFacts = Pick<
  Cap1HotelCfarOfferFactsProperties,
  | "cfar_premium_percentage"
  | "cfar_premium_usd"
  | "cfar_pricing_description"
  | "cfar_fee_is_refundable"
>;

export const getHotelViewedAddOnHotelCfarOfferFacts = (
  roomProduct: RoomProduct | null,
  hotelCfarQuote: HotelCfarQuote | null,
  additionalInfo: HotelCfarQuoteAdditionalInfo | null
): HotelViewedAddOnHotelCfarOfferFacts => {
  const hotelCfarOfferFacts = getHotelCfarOfferFactsFromHotelCfarQuote({
    roomProduct,
    hotelCfarQuote,
    additionalInfo,
  });
  return {
    cfar_premium_percentage: hotelCfarOfferFacts.cfar_premium_percentage,
    cfar_premium_usd: hotelCfarOfferFacts.cfar_premium_usd,
    cfar_pricing_description: hotelCfarOfferFacts.cfar_pricing_description,
    cfar_fee_is_refundable: hotelCfarOfferFacts.cfar_fee_is_refundable,
  };
};

export const CAP1_HOTEL_CFAR_ADD_ON_CHOICE = "cap1_hotel_cfar_add_on_choice";

export type HotelCfarAddOnChoiceHotelCfarOfferFacts = Pick<
  Cap1HotelCfarOfferFactsProperties,
  | "cfar_premium_percentage"
  | "cfar_premium_usd"
  | "cfar_pricing_description"
  | "cfar_choice"
  | "cfar_rr_hotel_choice"
  | "cfar_fee_is_refundable"
>;

export const getHotelCfarAddOnChoiceHotelCfarOfferFacts = (
  props: IHotelCfarQuoteWithCfarChoices
): HotelCfarAddOnChoiceHotelCfarOfferFacts => {
  const hotelCfarOfferFacts = getHotelCfarOfferFactsFromHotelCfarQuote(props);
  return {
    cfar_premium_percentage: hotelCfarOfferFacts.cfar_premium_percentage,
    cfar_premium_usd: hotelCfarOfferFacts.cfar_premium_usd,
    cfar_pricing_description: hotelCfarOfferFacts.cfar_pricing_description,
    cfar_choice: hotelCfarOfferFacts.cfar_choice,
    cfar_rr_hotel_choice: hotelCfarOfferFacts.cfar_rr_hotel_choice,
    cfar_fee_is_refundable: hotelCfarOfferFacts.cfar_fee_is_refundable,
  };
};

export type PartialReviewDetailsHotelCheckoutHotelCfarOfferFacts = Pick<
  Cap1HotelCfarOfferFactsProperties,
  | "cfar_premium_percentage"
  | "cfar_premium_usd"
  | "cfar_pricing_description"
  | "cfar_choice"
  | "cfar_rr_hotel_choice"
  | "cfar_fee_is_refundable"
>;

export const getReviewDetailsHotelCheckoutHotelCfarOfferFacts = (
  props: IHotelCfarQuoteWithCfarChoices
): PartialReviewDetailsHotelCheckoutHotelCfarOfferFacts => {
  const hotelCfarOfferFacts = getHotelCfarOfferFactsFromHotelCfarQuote(props);
  return {
    cfar_premium_percentage: hotelCfarOfferFacts.cfar_premium_percentage,
    cfar_premium_usd: hotelCfarOfferFacts.cfar_premium_usd,
    cfar_pricing_description: hotelCfarOfferFacts.cfar_pricing_description,
    cfar_choice: hotelCfarOfferFacts.cfar_choice,
    cfar_rr_hotel_choice: hotelCfarOfferFacts.cfar_rr_hotel_choice,
    cfar_fee_is_refundable: hotelCfarOfferFacts.cfar_fee_is_refundable,
  };
};

export type PartialCompleteBuyHotelHotelCfarOfferFacts = Pick<
  Cap1HotelCfarOfferFactsProperties,
  | "cfar_premium_percentage"
  | "cfar_premium_usd"
  | "cfar_pricing_description"
  | "cfar_choice"
  | "cfar_rr_hotel_choice"
  | "cfar_fee_is_refundable"
  | "cfar_added_from_modal"
>;

export const getCompleteBuyHotelHotelCfarOfferFacts = (
  props: IHotelCfarQuoteWithCfarChoices
): PartialCompleteBuyHotelHotelCfarOfferFacts => {
  const hotelCfarOfferFacts = getHotelCfarOfferFactsFromHotelCfarQuote(props);
  return {
    cfar_premium_percentage: hotelCfarOfferFacts.cfar_premium_percentage,
    cfar_premium_usd: hotelCfarOfferFacts.cfar_premium_usd,
    cfar_pricing_description: hotelCfarOfferFacts.cfar_pricing_description,
    cfar_choice: hotelCfarOfferFacts.cfar_choice,
    cfar_rr_hotel_choice: hotelCfarOfferFacts.cfar_rr_hotel_choice,
    cfar_fee_is_refundable: hotelCfarOfferFacts.cfar_fee_is_refundable,
    cfar_added_from_modal: hotelCfarOfferFacts.cfar_added_from_modal,
  };
};
export const PRODUCT_FEED_LOADING = "product_feed_loading";

export interface ProductFeedLoadingProperties {
  feed_type: "cross_sell_hotels" | "recently_viewed";
  feed_placement: "mytrips" | "homepage" | "flight_confirmation";
}

export const PRODUCT_FEED_VIEWED_EVENT = "product_feed_viewed";

export type ProductTileType =
  | "cross_sell_hotel"
  | "recently_viewed_hotel"
  | "recently_searched_hotel"
  | "recently_searched_air"
  | "recently_searched_car"
  | "recently_searched_package";

export interface ProductFeedViewedProperties {
  feed_type: "cross_sell_hotels" | "recently_viewed";
  feed_placement: "mytrips" | "homepage" | "flight_confirmation";
  latency: number;
  tile_type: ProductTileType | ProductTileType[];
}

export const PRODUCT_FEED_TILE_TAPPED = "prodfeed_tile_tapped";

export interface ProductFeedTileTappedProperties {
  feed_type: "cross_sell" | "recently_viewed";
  tile_type: ProductTileType;
  feed_placement: "homepage" | "flight_confirmation" | "my_trips";
}

export const VIEWED_MISSED_CONNECTION_GUARANTEE_MODAL =
  "viewed_missed_connection_guarantee_modal";

export const VIEWED_SELF_TRANSFER_MODAL = "viewed_self_transfer_modal";

export const VIEWED_VI_BAGGAGE_WARNING = "viewed_vi_baggage_warning";
export const CONFIRMED_VI_BAGGAGE_WARNING = "confirmed_vi_baggage_warning";
export const DECLINED_VI_BAGGAGE_WARNING = "declined_vi_baggage_warning";
export const EXPANDED_FLIGHT_DETAILS = "expanded_flight_details";
export const VIEWED_FARE_DETAILS_BREAKDOWN = "viewed_fare_details_breakdown";

export const ALL_FILTERS_MODAL_CARS_EVENT = "all_filters_modal_cars";

export interface CarsFilterEventProperties {
  car_type?: string;
  passengers?: string;
  bags?: string;
  pickup_location?: string;
  rental_company?: string;
  price?: number;
  cancellation?: string;
}

export interface ExpandedFlightDetailsProperties {
  slice_direction: "outbound" | "return";
  // Should be set to multiprovider for Hopper VI and HFv2 flights
  system_local_provider: string;
  multi_ticket_type: string;
}

export interface ViewedFareDetailsBreakdownProperties {
  entry_point: string;
  multi_ticket_type: string;
}

export interface VirtualInterlineModalProperties {
  entry_point: string;
}

export enum VirtualInterlineEntryPoint {
  Outbound = "outbound",
  Return = "return",
  Details = "itinerary_details",
  TripDetails = "trip_details",
}

export const SELECT_EDIT_TRAVELERS = "select_edit_travelers";

export interface SelectEditTravelersProperties {
  source: string;
}

export const VIEW_BOOKING_ADD_ON_POST_BOOKING =
  "cap1_view_booking_add_on_post_booking";

export const DISRUPTION_ADD_ON_CHOICE_POST_BOOKING =
  "cap1_disruption_add_on_choice_post_booking";

export const DISRUPTION_POST_BOOKING_COMPLETE_BUY =
  "cap1_disruption_post_booking_complete_buy";

export type PostBookingScreen =
  | "air_homepage"
  | "hotel_homepage"
  | "my_trips_homepage";

export const getPostBookingScreen = (locationSearch: string) => {
  const screens: PostBookingScreen[] = [
    "air_homepage",
    "hotel_homepage",
    "my_trips_homepage",
  ];

  const referenceScreenString = new URLSearchParams(locationSearch).get(
    "referenceScreen"
  );

  return screens.find((str) => str == referenceScreenString) ?? "air_homepage";
};

export const postBookingViewTakeoverPropteries = (
  screen: PostBookingScreen,
  takeover_display_count: number,
  trackingProperties: PostBookingTrackingProperties
) => {
  return {
    eventName: VIEW_BOOKING_ADD_ON_POST_BOOKING,
    properties: {
      screen: screen,
      takeover_display_count: takeover_display_count,
      ...trackingProperties,
    },
  };
};

export const postBookingViewCheckoutScreenProperties = (
  screen: PostBookingScreen,
  trackingProperties: PostBookingTrackingProperties
) => {
  return {
    eventName: VIEW_BOOKING_ADD_ON_POST_BOOKING,
    properties: {
      referring_screen: screen,
      screen: "fda_postbooking_checkout",
      ...trackingProperties,
    },
  };
};

export const postBookingChoiceTappedProperties = (
  screen: PostBookingScreen,
  disruption_choice: 0 | 1,
  trackingProperties: PostBookingTrackingProperties
) => {
  return {
    eventName: DISRUPTION_ADD_ON_CHOICE_POST_BOOKING,
    properties: {
      screen: screen,
      disruption_choice: disruption_choice,
      ...trackingProperties,
    },
  };
};

export const postBookingPurchaseCompleteProperties = (
  screen: PostBookingScreen,
  trackingProperties: PostBookingTrackingProperties
) => {
  return {
    eventName: DISRUPTION_POST_BOOKING_COMPLETE_BUY,
    properties: {
      referring_screen: screen,
      screen: "fda_postbooking_checkout",
      disruption_choice: 1,
      ...trackingProperties,
    },
  };
};

// Customer profile

export const CUSTOMER_PROFILE_ENTRY = "customer_profile_entry";

export interface CustomerProfileEntryProperties {
  profile_section:
    | "travelers"
    | "hotel_preferences"
    | "flight_preferences"
    | "payment_information"
    | "contact_information";
}

export const APPLIED_PREFERENCES = "applied_preferences";

export interface AppliedPreferencesProperties {
  preferences_type: "hotels" | "flights";
  location: "search" | "hotel_list" | "flight_list";
}

export const CLEAR_ALL_FILTERS = "clear_all_filters";

export const CLICKED_ADD_TRAVELER = "clicked_add_traveler";

export interface ClickedAddTravelerProperties {
  entry_type: "customer_profile" | "checkout";
}

export const SAVED_NEW_TRAVELER = "saved_new_traveler";

export interface SavedNewTravelerProperties {
  entry_type: "customer_profile" | "checkout";
}

export const SAVED_FLIGHT_PREFERENCES = "saved_flight_preferences";

export const SAVED_HOTEL_PREFERENCES = "saved_hotel_preferences";

export const NEW_PAYMENT_METHOD_ADDED = "new_payment_method_added";

export interface NewPaymentMethodAddedProperties {
  entry_type: "customer_profile" | "checkout";
}

// Corporate Travel
export const VIEWED_POLICY_MODAL = "viewed_policy_modal";
export const VIEWED_POLICY_DESCRIPTOR = "viewed_policy_descriptor";
export const POLICY_MODAL = "policy_modal";
export const POLICY_DESCRIPTOR = "policy_descriptor";

export enum PolicyDescriptorEntryPoints {
  FLIGHTS_LIST = "flights_list",
  FLIGHTS_LIST_EXPANDED = "flights_list_expanded",
  FLIGHTS_LIST_RETURN = "flights_list_return",
  FLIGHTS_LIST_RETURN_EXPANDED = "flights_list_return_expanded",
  HOTELS_LIST = "hotels_list",
  HOTELS_ROOM_DETAILS = "hotels_room_details",
  CARS_LIST = "cars_list",
}

export const TRIP_REQUESTED_AIR = "trip_requested_air";
export const TRIP_REQUESTED_HOTEL = "trip_requested_hotel";
export const VIEWED_TRAVELER_DETAILS = "viewed_traveler_details";

export interface SelectEditTravelersProperties {
  source: string;
}

export interface ViewedTravelerDetailsProperties {
  num_traveler_profiles: number;
}

export const CREATED_TRAVELER_PROFILE = "created_traveler_profile";

export const UPDATED_TRAVELER_PROFILE = "updated_traveler_profile";

export interface TravelerProfileProperties {
  source?: string;
  entry_point?: string;
  private?: boolean;
  frequent_flyer_program_added: boolean;
  num_frequent_flyer_programs: number;
  hotel_loyalty_program_added: boolean;
  num_hotel_loyalty_programs: number;
}

export const PRICE_MATCH_CLICKED = "price_match_clicked";

export const PORTAL_SWITCHED = "portal_switched";

export interface PortalSwitchedProperties {
  portal_selected: PortalTypes;
  current_portal: PortalTypes;
}

export const VIEWED_ITEMIZED_RECEIPT = "viewed_itemized_receipt";

export const PRINTED_ITEMIZED_RECEIPT = "printed_itemized_receipt";

export interface ItemizedReceiptEventsProperties {
  trip_id: string;
  active_tab: string;
  travel_type: string;
}

export const VIEWED_TRAVEL_OFFER = "viewed_travel_offer";
export const VIEWED_CORP_RATE_DESCRIPTOR = "viewed_corporate_rate_descriptor";

export enum ViewedCorpRateDescriptorEntryPoints {
  HOTELS_LIST_HEADER = "hotels_list_header",
  HOTELS_LIST_CARD = "hotels_list_card",
  HOTELS_LIST_MAP = "hotels_list_map",
  HOTELS_ROOM_DETAILS = "hotels_room_details",
  HOTELS_CHECKOUT = "hotels_checkout",
}

export const CORP_SWITCH_ACCOUNT = "switch_account";

export const CORP_SWITCH_ACCOUNT_CONTINUE = "switch_account_continue";
export const VXB_ACKNOWLEDGED = "vxb_acknowledged";
export const TAKEOVER_NOTICE_VIEWED = "takeover_notice_viewed";

// PACKAGES EVENTS
export const PACKAGE_ENTRY = "package_entry";
export const VIEWED_PACKAGES_SEARCH = "viewed_packages_search";
export const VIEWED_PACKAGES_LIST = "viewed_packages_list";
export const APPLIED_PACKAGES_FILTER = "applied_packages_filter";
export const SELECTED_PACKAGE_HOTEL_FROM_LIST =
  "selected_package_hotel_from_list";
export const SELECTED_PACKAGE_HOTEL_FROM_MAP =
  "selected_package_hotel_from_map";
export const VIEWED_PACKAGE_LODGING_IN_LIST = "viewed_package_lodging_in_list";
export const SELECTED_UNAVAILABLE_PACKAGE_HOTEL_FROM_LIST =
  "selected_unavailable_package_hotel_from_list";
export const COMPLETE_BUY_PACKAGE = "complete_buy_package";
export const PRICE_QUOTE_PACKAGE = "price_quote_package";
export const VIEWED_PACKAGE_CHECKOUT = "viewed_package_checkout";
export const VIEWED_PACKAGE_HOTEL_SHOP_LOADING =
  "viewed_packages_hotel_shop_loading";
export const VIEWED_PACKAGE_HOTEL_SHOP_MAP = "viewed_packages_hotel_shop_map";
export const SELECT_PACKAGE_HOTEL_ROOM = "select_package_hotel_room";
export const VIEWED_ITINERARY_REVIEW = "viewed_itinerary_review";
export const REVIEWED_FLIGHT_DETAILS = "reviewed_flight_details";
export const CHANGE_HOTEL_SELECTED = "change_hotel_selected";
export const CHANGE_ROOM_SELECTED = "change_room_selected";
export const CHANGE_FLIGHT_SELECTED = "change_flight_selected";
export const REDIRECT_TO_PACKAGE_HOTEL_SHOP = "redirect_to_package_hotel_shop";

export interface PackageEntryProperties {
  readonly market: string;
  readonly country: string;
  readonly los?: string;
  readonly number_of_adults: number;
  readonly number_of_children: number;
  readonly number_of_infants: number;
  readonly total_travelers: number;
  readonly non_stop: boolean;
  readonly fare_class?: FareclassShelfBrandName;
  readonly entry_type?: PackagesEntryTypeEnum;
}

export interface PackagesViewedHotelListProperties
  extends ViewedHotelListProperties {}

export interface PackagesHotelEntryProperties extends HotelEntryProperties {}
