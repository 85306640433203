import {
  ExperienceReservation,
  IPersonWithNameNumber,
  PackageItinerary,
} from ".";
import { WatchAlertView } from "../apis/tysons/watch-alerts";
import {
  BookedFlightItineraryWithDepartureTime,
  HomesItinerary,
} from "../index";
import { CarReservation } from "./groundItinerary";
import { HotelItinerary } from "./hotelItinerary";

export enum MyTripsFilter {
  UPCOMING_TRIPS = "Upcoming Trips",
  WATCHED_TRIPS = "Watched Trips",
  PRIZE_FREEZES = "Frozen Prices",
  TRAVEL_CREDITS = "Airline Travel Credits",
  PAST_TRIPS = "Past Trips",
  TRIP_REQUESTS = "Trip Requests",
  TRIPS_WITH_OFFERS = "Trips with Offers",
}

export enum ItineraryEnum {
  Flight = "Flight",
  Hotel = "Hotel",
  Watch = "Watch",
  Car = "Car",
  Home = "Home",
  Package = "Package",
  PackageHotel = "PackageHotel",
  PackageFlight = "PackageFlight",
  Experience = "Experience",
}
export type Itinerary =
  | BookedFlightItineraryWithDepartureTime
  | HotelItinerary
  | CarReservation
  | HomesItinerary
  | PackageItinerary
  | ExperienceReservation;

export interface Flight extends BookedFlightItineraryWithDepartureTime {
  type: ItineraryEnum;
}
export interface Hotel extends HotelItinerary {
  type: ItineraryEnum;
}
export interface Home extends HomesItinerary {
  type: ItineraryEnum;
}
export interface Car extends CarReservation {
  type: ItineraryEnum;
}

export interface Package extends PackageItinerary {
  type: ItineraryEnum;
}

export interface Experience extends ExperienceReservation {
  type: ItineraryEnum;
}

export interface Watch extends WatchAlertView {
  type: ItineraryEnum;
}

export enum IRequestTravelerEditFormEnum {
  frequentFlyerNumber = "frequentFlyerNumber",
  tsaRedressNumber = "tsaRedressNumber",
  knownTravelerNumber = "knownTravelerNumber",
  name = "name",
  birthdate = "birthdate",
  gender = "gender",
  specialAssistance = "specialAssistance",
}

export interface IRequestPassengerEdit {
  traveler: IPersonWithNameNumber;
  field?: IRequestTravelerEditFormEnum;
  newValue?: string;
}

type IRequestTravelerEditFormEnumObject = {
  [key in IRequestTravelerEditFormEnum]: string;
};

export interface IEditTravelerRequestInfo
  extends IRequestTravelerEditFormEnumObject {
  passengerNumberToModify: string;
}

export interface IEditTravelerRequestSubmit {
  pnr: string;
  itineraryId: string;
  travelersInformation: IEditTravelerRequestInfo[];
}

export type ItineraryWithType =
  | Flight
  | Hotel
  | Watch
  | Car
  | Home
  | Package
  | Experience;

export enum MyTripsModalTypes {
  ConfirmationModal = "ConfirmationModal",
  ExchangeFlight = "ExchangeFlight",
  PaymentModal = "PaymentModal",
  ResendConfirmation = "ResendConfirmation",
  SelfServeCancelFlight = "SelfServeCancelFlight",
  TravelersModal = "TravelersModal",
  OutboundItinerary = "OutboundItinerary",
  MulticityItinerary0 = "MulticityItinerary0",
  MulticityItinerary1 = "MulticityItinerary1",
  MulticityItinerary2 = "MulticityItinerary2",
  MulticityItinerary3 = "MulticityItinerary3",
  MulticityItinerary4 = "MulticityItinerary4",
  ItemizedHotelReceipt = "ItemizedHotelReceipt",
  ReturnItinerary = "ReturnItinerary",
  ScheduleChange = "ScheduleChange",
  StopWatching = "StopWatching",
  ChangeEmail = "ChangeEmail",
  CancelFlight = "CancelFlight",
  RequestTravelerEdit = "RequestTravelerEdit",
  RequestTravelerEditSuccess = "RequestTravelerEditSuccess",
  CfarFlight = "CfarFlight",
  ChangeFlight = "ChangeFlight",
  CancelHotel = "CancelHotel",
  ChangeHotel = "ChangeHotel",
  CheckInFlight = "CheckInFlight",
  CarConfirmation = "CarConfirmation",
  DriversModal = "DriversModal",
  ChangeCar = "ChangeCar",
  CancelCar = "CancelCar",
  PickUp = "PickUp",
  DropOff = "DropOff",
  BasicInfo = "BasicInfo",
  CfarHotel = "CfarHotel",
  VirtualInterlineMcp = "VirtualInterlineMcp",
  CancelHome = "CancelHome",
  ItemizedHomeReceipt = "ItemizedHomeReceipt",
  ContactHostHome = "ContactHostHome",
  ItemizedPackageReceipt = "ItemizedPackageReceipt",
  CancelExperience = "CancelExperience",
}

export interface IOpenModal {
  type: MyTripsModalTypes | null;
  selectedItinerary: ItineraryWithType | null;
}
