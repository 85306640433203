import React, { useState } from "react";
import { Box, Button, Typography } from "@material-ui/core";
import {
  ActionButton,
  DatePickerButton,
  DateRangePicker,
  DesktopPopupModal,
  PickerType,
} from "halifax";
import "./styles.scss";
import dayjs from "dayjs";
import { DateSelectorConnectorProps } from "./container";
import { RouteComponentProps } from "react-router";
import clsx from "clsx";

export enum IDateSelectorVariant {
  RANGE = "RANGE",
  BUTTON = "BUTTON",
}

export interface IDateSelector
  extends DateSelectorConnectorProps,
    RouteComponentProps {
  variant: IDateSelectorVariant;
  hideSeparator?: boolean;
  showErrorStyles?: boolean; // Only applies to IDateSelectorVariant.RANGE variant
}

export const DateSelector = (props: IDateSelector) => {
  const {
    fromDate,
    untilDate,
    hideSeparator,
    showErrorStyles,
    variant,
    unavailableDates,
    setFromDate,
    setUntilDate,
    fetchVacationRentalShop,
    history,
  } = props;

  const [showDatePicker, setShowDatePicker] = useState(false);
  const [from, setFrom] = React.useState<Date | null>(null);
  const [until, setUntil] = React.useState<Date | null>(null);

  React.useEffect(() => {
    setFrom(fromDate);
  }, [fromDate]);

  React.useEffect(() => {
    setUntil(untilDate);
  }, [untilDate]);

  const handleClickDone = () => {
    setFromDate(from);
    setUntilDate(until);
    setShowDatePicker(false);
    fetchVacationRentalShop(history, {
      fetchListingCalendar: true,
    });
  };

  return (
    <Box className="vacation-rentals-date-selector">
      {variant == IDateSelectorVariant.RANGE && (
        <Box>
          <DatePickerButton
            startDate={fromDate}
            endDate={untilDate}
            startLabel={"Check-in"}
            endLabel={"Checkout"}
            classes={[clsx("date-picker", showErrorStyles && "error-styles")]}
            hideSeparator={hideSeparator}
            onClick={() =>
              setShowDatePicker((showDatePicker) => !showDatePicker)
            }
            dateFormat={"ddd, MMM D"}
          />
        </Box>
      )}

      {variant == IDateSelectorVariant.BUTTON && (
        <ActionButton
          className="cta-button"
          buttonClassName="cta-button-inner"
          onClick={() => setShowDatePicker((showDatePicker) => !showDatePicker)}
          message={
            <Typography
              style={{
                fontSize: "16px",
                color: "white",
              }}
            >
              Change dates
            </Typography>
          }
        />
      )}
      {showDatePicker && (
        <DesktopPopupModal
          open={showDatePicker}
          contentClassName="date-picker-content"
          onClose={() => setShowDatePicker((showDatePicker) => !showDatePicker)}
          invisibleBackdrop={false}
          headerElement={
            <Typography style={{ padding: "40px 40px 10px" }}>
              Select the check-in and checkout dates for your stay
            </Typography>
          }
        >
          <DateRangePicker
            className="b2b"
            minAllowedDate={dayjs().toDate()}
            maxAllowedDate={dayjs().add(1, "year").toDate()}
            minNumDays={1}
            currency={""}
            startDate={from}
            endDate={until}
            setStartDate={setFrom}
            setEndDate={setUntil}
            months={[]}
            priceTags={[]}
            pickerType={PickerType.RANGE}
            columnView={false}
            unavailableDates={unavailableDates}
          />

          <Box
            display="flex"
            paddingTop="20px"
            paddingX="40px"
            marginBottom="40px"
            justifyContent="flex-end"
          >
            <Button
              onClick={handleClickDone}
              disabled={!from || !until}
              className="vacation-rentals-select-dates-button"
              variant="contained"
            >
              Done
            </Button>
          </Box>
        </DesktopPopupModal>
      )}
    </Box>
  );
};
