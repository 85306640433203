// Please do not over-write this file with TSGen types

import { Route } from "./shop-summary";
import {
  AirportRegion,
  Amount,
  BigDecimal,
  DateRange,
  DateTime,
  TrackingProperties,
} from "../../common";
import {
  nthNightPromotion,
} from "redmond"
import { TravelWalletOfferTrackingProperties } from "./events";

export enum AccountingType {
  Acquisition = "acquisition",
  FromPurchase = "fromPurchase",
  Promotion = "promotion",
}

export interface ActiveOffer {
  offer: TravelOffer;
}

export type ActiveOfferId = string;

export interface Air extends BaseAvailableProduct {
  tripId: string;
  route: Route;
  operatingCarriers: string[];
  marketingCarriers: string[];
  validatingCarriers: string[];
  tripType: TripType;
  departureDate: string;
  returnDate?: string;
  totalStops: number;
  lengthOfStay?: number;
  advance: number;
  includesSaturdayNightStay: boolean;
  shelfRatings: number[];
  canBeDiscounted: boolean;
  currency: string;
  comparisonDifference?: WallStreetAmount;
  productPricing?: ProductPricing;
}

export interface Airline extends BaseApplicability {
  code: string;
}
export interface RegionTypeAirport extends RegionType {
  RegionType: RegionTypeEnum.Airport;
}
export interface LocationKindAirport extends LocationKind {
  LocationKind: LocationKindEnum.Airport;
}

export interface Always extends BaseApplicability {}

export interface WallStreetAmount {
  amount: number;
  currency: string;
}

export interface MumbaiAmount {
  value: number;
  currency: string;
}

export interface AmountComparison extends BaseApplicability {
  comparator: Comparator;
  extractor: AmountExtractor;
}

export interface AmountExtractor {
  AmountExtractor: AmountExtractorEnum;
}

export enum AmountExtractorEnum {
  GrandTotal = "GrandTotal",
  ExpectedRevenue = "ExpectedRevenue",
  GrossBookValue = "GrossBookValue",
  BasePrice = "BasePrice",
  DailyComparablePriceDifference = "DailyComparablePriceDifference",
  AncillaryTotal = "AncillaryTotal",
  PreDiscountTotal = "PreDiscountTotal",
  ComparablePriceDifference = "ComparablePriceDifference",
  PreDiscountGrandTotal = "PreDiscountGrandTotal",
  SellPrice = "SellPrice",
}

export interface AmountPositive extends BaseApplicability {
  extractor: AmountExtractor;
}

export interface AncillaryTotal extends AmountExtractor {}

export interface And extends BaseApplicability {
  rules: Applicability[];
}

export type Applicability =
  | Airline
  | Always
  | AmountComparison
  | AmountPositive
  | And
  | FirstCarRental
  | FirstFlightBooking
  | FirstFlightBookingThisMonth
  | FirstLodgingPriceFreeze
  | FirstReservation
  | FirstReservationThisMonth
  | FirstWebLodgingReservation
  | HasComparable
  | HopperCanDiscount
  | IntegerComparison
  | IsAir
  | IsGround
  | IsLodging
  | IsLodgingPriceFreeze
  | IsPreferred
  | LodgingIds
  | Never
  | NoCarRentalHistory
  | NoFlightPurchaseHistory
  | NoLodgingPurchaseHistory
  | Not
  | Or
  | ProductGeoLocation
  | ProductWithDestination
  | ProductWithOrigin
  | PurchaseCurrency
  | RequiresCreditCard
  | UserCountry;

export interface BaseApplicability {
  Applicability: ApplicabilityEnum;
}

export enum ApplicabilityEnum {
  NoFlightPurchaseHistory = "NoFlightPurchaseHistory",
  ProductWithDestination = "ProductWithDestination",
  HasComparable = "HasComparable",
  NoCarRentalHistory = "NoCarRentalHistory",
  Always = "Always",
  FirstFlightBookingThisMonth = "FirstFlightBookingThisMonth",
  IsLodgingPriceFreeze = "IsLodgingPriceFreeze",
  FirstReservation = "FirstReservation",
  UserCountry = "UserCountry",
  IntegerComparison = "IntegerComparison",
  And = "And",
  IsGround = "IsGround",
  ProductGeoLocation = "ProductGeoLocation",
  NoLodgingPurchaseHistory = "NoLodgingPurchaseHistory",
  PurchaseCurrency = "PurchaseCurrency",
  IsAir = "IsAir",
  FirstLodgingPriceFreeze = "FirstLodgingPriceFreeze",
  LodgingIds = "LodgingIds",
  FirstWebLodgingReservation = "FirstWebLodgingReservation",
  FirstFlightBooking = "FirstFlightBooking",
  FirstReservationThisMonth = "FirstReservationThisMonth",
  Never = "Never",
  AmountPositive = "AmountPositive",
  FirstCarRental = "FirstCarRental",
  ProductWithOrigin = "ProductWithOrigin",
  HopperCanDiscount = "HopperCanDiscount",
  Airline = "Airline",
  AmountComparison = "AmountComparison",
  IsPreferred = "IsPreferred",
  RequiresCreditCard = "RequiresCreditCard",
  Or = "Or",
  IsLodging = "IsLodging",
  IsHomes = "IsHomes",
  HomeIds = "HomeIds",
  FirstHomesBooking = "FirstHomesBooking",
  Not = "Not",
}

export interface ApplicableOffer extends BaseOfferApplicability {
  offer: ActiveOffer;
  products: ProductApplicability[];
}

export interface ApplicableOffersResponse {
  offers: { [key: string]: ActiveOffer };
}

export type PersonalOfferState =
  | Authorized
  | Cancelled
  | ConfirmState
  | Expired
  | Pending
  | Used;
export enum PersonalOfferStateEnum {
  Used = "Used",
  ConfirmState = "ConfirmState", // ConfirmState isn't an interface and can't be extended - why? Is this still correct?
  Cancelled = "Cancelled",
  Expired = "Expired",
  Pending = "Pending",
  Authorized = "Authorized",
}

export type Authorized = PersonalOfferStateEnum.Authorized;

export type AvailableProduct =
  | Air
  | CarRental
  | Lodging
  | LodgingPriceFreeze
  | HomeAvailableProduct;

export interface BaseAvailableProduct {
  AvailableProduct: AvailableProductEnum;
}

export enum AvailableProductEnum {
  Air = "Air",
  CarRental = "CarRental",
  Lodging = "Lodging",
  LodgingPriceFreeze = "LodgingPriceFreeze",
  Home = "Home",
}

export interface BasePrice extends AmountExtractor {}

export type Cancelled = PersonalOfferStateEnum.Cancelled;

export interface CarRental extends BaseAvailableProduct {
  vehicleId: string;
  pickupDateTime: string;
  dropOffDateTime: string;
  canBeDiscounted: boolean;
  requiresCreditCard: boolean;
  vendor?: string;
  supplier?: string;
  productPricing?: ProductPricing;
  airport?: LocationCode;
  rateCode?: string;
}

export interface HomeAvailableProduct extends BaseAvailableProduct {
  listingId: string;
  merchant: MerchantOfRecord;
  market?: string;
  stayDates: DateRange;
  productPricing?: ProductPricing;
  paymentSchedule: PaymentSchedule;
  isHostFunded: boolean;
  location?: AirportRegion;
  hostId?: string;
}

export interface HomeIds extends BaseApplicability {
  homeIds: string[];
}

export type CashBack =
  | CashBackOffer
  | CashBackOfferWithDiscount
  | MultipleCashBackOffers;

export interface BaseCashBack extends BaseOffer {
  CashBack: CashBackEnum;
}

export enum CashBackEnum {
  CashBackOffer = "CashBackOffer",
  CashBackOfferWithDiscount = "CashBackOfferWithDiscount",
  MultipleCashBackOffers = "MultipleCashBackOffers",
}

export interface CashBackOffer extends BaseCashBack {
  name: string;
  rules: CashBackRules;
  isDeferred: boolean;
}

export type Discounted = CashBackOfferWithDiscount | Discount;
export interface BaseDiscounted extends BaseOffer {
  Discounted: DiscountedEnum;
}

export interface CashBackOfferWithDiscount
  extends BaseDiscounted,
    BaseCashBack {
  cashBackOffer: CashBackOffer;
  otherOffer: Discounted;
}

export interface BaseCashBackRules {
  CashBackRules: CashBackEnum;
}

export type CashBackRules =
  | FixedCashBack
  | GrandTotalRatioCashBack
  | RatioCashBack
  | RevenueRatioCashBack
  | SellPriceRatioCashBack;

export enum CashBackRulesEnum {
  RevenueRatioCashBack = "RevenueRatioCashBack",
  FixedCashBack = "FixedCashBack",
  RatioCashBack = "RatioCashBack",
  GrandTotalRatioCashBack = "GrandTotalRatioCashBack",
  SellPriceRatioCashBack = "SellPriceRatioCashBack",
}

export interface RegionTypeCity extends RegionType {
  RegionType: RegionTypeEnum.City;
}

export interface LocationKindCity extends LocationKind {
  LocationKind: LocationKindEnum.City;
}

export interface ComparablePriceDifference extends AmountExtractor {}

export interface Comparator {
  Comparator: ComparatorEnum;
}

export enum ComparatorEnum {
  ControlComparator = "ControlComparator",
  ResourceComparator = "ResourceComparator",
}

export type ConfirmState = Open | Voided;
export interface BaseConfirmState {
  ConfirmState: ConfirmStateEnum;
}

export enum ConfirmStateEnum {
  Open = "Open",
  Voided = "Voided",
}

export interface ControlComparator extends Comparator {}

export interface Country extends LocationKind {}

export interface DailyComparablePriceDifference extends AmountExtractor {}

export interface Discount {
  id: OfferId;
  name: string;
  asDeclinedOffer?: OfferWithCashBack;
  discount: WallStreetAmount;
  percentage?: number;
  maxDiscountAmount?: WallStreetAmount;
  expiresOn: string;
  deferredValue?: WallStreetAmount;
  accountingType: AccountingType;
  showExpiration: boolean;
}

export enum DiscountedEnum {
  CashBackOfferWithDiscount = "CashBackOfferWithDiscount",
  Discount = "Discount",
}

export interface ExpectedRevenue extends AmountExtractor {}

export type Expired = PersonalOfferStateEnum.Expired;

export interface FirstCarRental extends BaseApplicability {}

export interface FirstFlightBooking extends BaseApplicability {}

export interface FirstFlightBookingThisMonth extends BaseApplicability {}

export interface FirstLodgingPriceFreeze extends BaseApplicability {}

export interface FirstReservation extends BaseApplicability {}

export interface FirstReservationThisMonth extends BaseApplicability {}

export interface FirstWebLodgingReservation extends BaseApplicability {}

export interface FirstHomesBooking extends BaseApplicability {}

export interface FixedCashBack extends BaseCashBackRules {
  cashBackAmount: WallStreetAmount;
  cashBackExpiresIn?: number;
}

export interface GrandTotal extends AmountExtractor {}

export interface GrandTotalRatioCashBack extends BaseCashBackRules {
  ratio: number;
  cashBackExpiresIn?: number;
}

export interface GrossBookValue extends AmountExtractor {}

export interface Gt extends Operator {}

export interface Gte extends Operator {}

export interface HasComparable extends BaseApplicability {}

export interface HopperCanDiscount extends BaseApplicability {}

export interface IntExtractor {
  IntExtractor: IntExtractorEnum;
}
export enum IntExtractorEnum {
  NumNights = "NumNights",
  NumStops = "NumStops",
}

export interface IntValue {
  value: number;
}

export interface IntegerComparison extends BaseApplicability {
  comparator: Comparator;
  extractor: IntExtractor;
}

export interface IsAir extends BaseApplicability {}

export interface IsGround extends BaseApplicability {}

export interface IsLodging extends BaseApplicability {}

export interface IsHomes extends BaseApplicability {}

export interface IsLodgingPriceFreeze extends BaseApplicability {}

export interface IsPreferred extends BaseApplicability {}

export interface LocationCode {
  kind: LocationKindEnum;
  value: string;
}

export interface LocationKind {
  LocationKind: LocationKindEnum;
}

export enum LocationKindEnum {
  Region = "region",
  Airport = "airport",
  State = "state",
  Country = "country",
  City = "city",
}

export interface Lodging extends BaseAvailableProduct {
  id: string;
  merchant: MerchantOfRecord;
  location?: AirportRegion;
  lengthOfStay: number;
  stayPeriod: DateRange;
  comparisonDifference?: WallStreetAmount;
  productPricing?: ProductPricing;
  isPreferred: boolean;
  nthNightPromotion?: nthNightPromotion;
}

export interface LodgingIds extends BaseApplicability {
  lodgingIds: string[];
}

export interface LodgingPriceFreeze extends BaseAvailableProduct {
  voucherId: string;
  depositAmount: WallStreetAmount;
  canBeDiscounted: boolean;
  productPricing?: ProductPricing;
}

export interface Lt extends Operator {}

export interface Lte extends Operator {}

export enum MerchantOfRecord {
  Hopper = "hopper",
  Other = "other",
}

export enum PaymentSchedule {
  PayNow = "PayNow",
  PayLater = "PayLater",
}

export interface MultipleCashBackOffers extends BaseCashBack {
  offers: CashBack[];
}

export interface Never extends BaseApplicability {}

export interface NoCarRentalHistory extends BaseApplicability {
  since?: string;
}

export interface NoFlightPurchaseHistory extends BaseApplicability {
  destination: LocationCode;
  since?: string;
}

export interface NoLodgingPurchaseHistory extends BaseApplicability {
  location: LocationCode;
  since?: string;
}

export interface NonApplicableOffer extends BaseOfferApplicability {}

export interface Not extends BaseApplicability {
  rule: Applicability;
}

export interface NumNights extends IntExtractor {}

export interface NumStops extends IntExtractor {}

export interface BaseOffer {
  Offer: OfferEnum;
}

export enum Funnel {
  Air = "Air",
  Ground = "Ground",
  Lodging = "Lodging",
  Home = "Home",
  Homes = "Homes", // Handle applicability response
  Experiences = "Experiences",
}

export interface TravelWalletOffer {
  funnels: Array<Funnel>;
  amount: Amount;
  expiresOn: string;
  id: string;
  title: string;
  percentage?: number;
  maxDiscountAmount?: WallStreetAmount;
  descriptions: Array<string>;
  termsAndConditions?: string;
  landingPageBanner?: string;
  availabilityPageBanner?: string;
  shopPageBanner?: string;
  maxApplicableCredit?: WallStreetAmount;
  trackingProperties?: TravelWalletOfferTrackingProperties;
  trackingPropertiesV2?: TrackingProperties<TravelWalletOfferTrackingProperties>;
  saleEventStartOn?: string;
  offerCtaText?: string;
  imageId?: string;
}

export enum StatementCreditStandingEnum {
  EnterGoodStanding = "EnterGoodStanding",
  EnterBadStanding = "EnterBadStanding",
  Other = "Other",
  Unknown = "Unknown",
}

export enum StatementCreditDetailStatus {
  Full = "Full",
  Partial = "Partial",
  Empty = "Empty",
}

export interface StatementCreditDetail {
  createdOn: string;
  startingAmount: Amount;
  renewsOn: string;
  productType: string;
  expiresOn: string;
  last4: string;
  usableAmount: Amount;
  status: {
    Status: StatementCreditDetailStatus;
  };
  standing: {
    Standing: StatementCreditStandingEnum;
  };
  capOneCreditId: {
    value: string;
  };
  CreditDetail: "Statement";
}

export interface NonStatementCreditDetail {
  usableAmount: Amount;
  CreditDetail: "NonStatement";
}

export type CreditDetail = StatementCreditDetail | NonStatementCreditDetail;

export interface TravelWalletCredit {
  amount: WallStreetAmount;
  id: string;
  breakdown?: CreditDetail[];
  trackingPropertiesV2?: any;
}

export type TravelOffer = Discount;
// | CashBackOffer -- TODO [Jenny]: Possibly only need Discount for now - unknown for now
// | CashBackOfferWithDiscount
// | MultipleCashBackOffers;

export type OfferApplicability = ApplicableOffer | NonApplicableOffer;
export interface BaseOfferApplicability {
  OfferApplicability: OfferApplicabilityEnum;
}

export enum OfferApplicabilityEnum {
  Applicable = "Applicable",
  NonApplicable = "NonApplicable",
}

export interface OfferApplicabilityResponse {
  bestOfferIdPerProduct: ActiveOfferId[];
  bestOfferOverall: TravelWalletOffer;
  offers: { [key: string]: TravelWalletOffer };
}

export enum OfferEnum {
  CashBackOffer = "CashBackOffer",
  CashBackOfferWithDiscount = "CashBackOfferWithDiscount",
  Discount = "Discount",
  MultipleCashBackOffers = "MultipleCashBackOffers",
}

export type OfferId = string;

export interface OfferWithApplicability {
  offer: TravelOffer;
  applicability: Applicability;
}

export type OfferWithCashBack =
  | CashBack
  | CashBackOfferWithDiscount
  | MultipleCashBackOffers;

export interface OffersForProductsRequest {
  products: AvailableProduct[];
}

export interface Open extends BaseConfirmState {}

export interface Operator {
  Operator: OperatorEnum;
}

export enum OperatorEnum {
  Gt = "Gt",
  Gte = "Gte",
  Lt = "Lt",
  Lte = "Lte",
}

export interface Or extends BaseApplicability {
  rules: Applicability[];
}

export type Pending = PersonalOfferStateEnum.Pending;

export interface PersonalOfferDetails {
  id: OfferId;
  state: PersonalOfferState;
  creationDate: string;
  expirationDate: string;
  originalAmount: WallStreetAmount;
  applicability: Applicability;
}

export interface PreDiscountGrandTotal extends AmountExtractor {}

export interface PreDiscountTotal extends AmountExtractor {}

export enum ProductApplicability {
  Applicable = "applicable",
  NonApplicable = "nonApplicable",
}

export enum ProductClassEnum {
  Basic = "basic",
  Economy = "economy",
  Premium = "Premium",
}

export interface ProductGeoLocation extends BaseApplicability {
  location: LocationCode;
}

export interface ProductPricing {
  productClass: ProductClassEnum;
  currency: string;
  basePrice: number;
  grossBookValue: number;
  ancillaryTotal: number;
  preDiscountTotal: number;
  preDiscountGrandTotal: number;
  sellPrice: number;
  expectedRevenue?: number;
}

export interface ProductWithDestination extends BaseApplicability {
  destination: LocationCode;
}

export interface ProductWithOrigin extends BaseApplicability {
  origin: LocationCode;
}

export interface PurchaseCurrency extends BaseApplicability {
  currency: string;
}

export interface RatioCashBack extends BaseCashBackRules {
  basicGbvRatio: number;
  economyGbvRatio: number;
  premiumGbvRatio: number;
  ancillaryRatio: number;
  cashBackExpiresIn?: number;
}

export interface Region extends LocationKind {}

export interface RegionType {
  RegionType: RegionTypeEnum;
}

export enum RegionTypeEnum {
  Airport = "Airport",
  City = "City",
}

export interface RequiresCreditCard extends BaseApplicability {}

// TO DO [Jenny] - Delete types once confirming it does not show up on FE

// export interface ResourceComparator extends Comparator {
//   operator: Operator
// }

// export interface MumbaiAmountResourceComparator extends ResourceComparator {
//   value: MumbaiAmount;
// }

// export interface IntValueResourceComparator extends ResourceComparator {
//   value: IntValue;
// }

export interface RevenueRatioCashBack extends BaseCashBackRules {
  expectedRevenueRatio: number;
  maximum: number;
  minimum?: number;
  currency: string;
  cashBackExpiresIn?: number;
}

export interface SellPrice extends AmountExtractor {}

export interface SellPriceRatioCashBack extends BaseCashBackRules {
  sellPriceRatio: number;
  cashBackExpiresIn?: number;
}

export interface State extends LocationKind {}

export enum TripType {
  OneWay = "one_way",
  OpenJaw = "open_jaw",
  RoundTrip = "round_trip",
}

export type Used = PersonalOfferStateEnum.Used;

export interface UserCountry extends BaseApplicability {
  countryCode: string;
}

export interface Voided extends BaseConfirmState {}

export interface WalletDetailsResponse {
  offers: TravelWalletOffer[];
  credit?: TravelWalletCredit;
  creditBreakdown: CreditDetail[];
  bestOfferByFunnel: { [key: string]: TravelWalletOffer };
}

export interface WalletSummaryResponse {
  offerCount: number;
  creditBreakdown: CreditDetail[];
  bestOffer?: TravelWalletOffer;
  credit?: TravelWalletCredit;
}

export interface WalletOffersResponse {
  rankedApplicableOffers: TravelWalletOffer[];
}

export enum TransactionTypeEnum {
  TravelCreditRedemption = "TRAVEL_CREDIT_REDEMPTION",
  TravelCreditAdjustment = "TRAVEL_CREDIT_ADJUSTMENT",
  PremierCollectionTravelCredit = "PREMIER_COLLECTION_TRAVEL_CREDIT",
  StatementCreditCreated = "STATEMENT_CREDIT_CREATED",
  StatementCreditRedeemed = "STATEMENT_CREDIT_REDEEMED",
  StatementCreditExpired = "STATEMENT_CREDIT_EXPIRED",
  StatementCreditCanceled = "STATEMENT_CREDIT_CANCELED",
  PriceDropProtection = "PRICE_DROP_PROTECTION",
  TravelOffer = "TRAVEL_OFFER",
  PriceMatch = "PRICE_MATCH",
  Refund = "REFUND",
  PendingTravelCredit = "PENDING_TRAVEL_CREDIT",
  Expired = "EXPIRED",
  Canceled = "CANCELED",
}

export enum TransactionReasonCode {
  NEW_ACCOUNT_OPENING = "NEW_ACCOUNT_OPENING",
  START_NEW_BENEFIT_PERIOD = "START_NEW_BENEFIT_PERIOD",
  ENTER_BAD_STANDING = "ENTER_BAD_STANDING",
  ENTER_GOOD_STANDING = "ENTER_GOOD_STANDING",
  ACCOUNT_UPGRADE = "ACCOUNT_UPGRADE",
  ACCOUNT_DOWNGRADE = "ACCOUNT_DOWNGRADE",
  ACCOUNT_MIGRATED = "ACCOUNT_MIGRATED",
}

export interface Transaction {
  transactionTime: DateTime;
  transactionType: TransactionTypeEnum;
  funnel?: Funnel[];
  amountUsd: BigDecimal;
  statementCreditContext?: {
    productType: string;
    last4: string;
    capOneCreditId: {
      value: string;
    };
    reasonCode: TransactionReasonCode;
  };
}

export interface WalletHistoryResponse {
  credits: Transaction[];
}
