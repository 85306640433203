import React from "react";
import {
  ActionButton,
  ActionLink,
  B2BButton,
  CloseButtonIcon,
  DesktopPopupModal,
  Icon,
  IconName,
  MobilePopoverCard,
  PassengerCountPicker,
  PassengerCountPickerType,
} from "halifax";
import { Box, Tooltip, Typography } from "@material-ui/core";
import { GuestsSelection } from "redmond";
import "./styles.scss";
import { OccupancySelectorConnectorProps } from "./container";
import { RouteComponentProps } from "react-router";
import { GUEST_SELECTOR_INPUT_LABEL } from "../../textConstants";
import clsx from "clsx";
import { useDeviceTypes } from "../../../../hooks/useDeviceTypes";

const MAX_ROOM_COUNT = 10;
const MAX_TRAVELERS_COUNT = 16;
const PET_TOOLTIP_COPY =
  "If you're traveling with a pet, you need to book a pet-friendly stay. Adding a pet will display only pet-friendly stays in your search.<br/> <br/>If you’d like to bring more than 1 pet, please discuss with your stay after booking. Service animals do not count as pets—you don’t need to add them to your guest count, and you can inform your stay provider after booking.";

const MODAL_TITLES = {
  modalTitle: "Edit Travelers",
  adultTitle: "Adults",
  adultSubtitle: "",
  childrenTitle: "Children",
  childrenSubtitle: "",
  childAgeInputSubtitle:
    "To ensure the most accurate rates, please enter the exact age of the children on this booking.",
  petsTitle: "Traveling with pets?",
};

export enum IOccupanySelectionVariant {
  BUTTON = "BUTTON",
  RANGE = "RANGE",
  COMPONENT = "COMPONENT",
}

export interface IOccupancySelection
  extends OccupancySelectorConnectorProps,
    RouteComponentProps {
  variant: IOccupanySelectionVariant;
  maxOccupancy: number;
  showErrorStyles?: boolean; // Only applies to IOccupanySelectionVariant.RANGE variant
  component?: React.ReactNode; // Only applies to IOccupanySelectionVariant.COMPONENT variant
}

export const OccupancySelector = (props: IOccupancySelection) => {
  const {
    variant,
    adultsCount,
    childrenCount,
    petsCount,
    maxOccupancy,
    showErrorStyles,
    component,
    setOccupancyCounts,
    fetchVacationRentalShop,
    history,
  } = props;

  const { matchesMobile } = useDeviceTypes();

  const [showPassengerCountPicker, setShowPassengerCountPicker] =
    React.useState(false);

  const handleClickSave = (counts: GuestsSelection) => {
    setOccupancyCounts({
      adults: counts.adults,
      children: counts.children,
      pets: counts.pets,
    });
    fetchVacationRentalShop(history, {});
  };

  return (
    <Box className="vacation-rentals-occupancy-selection">
      {variant == IOccupanySelectionVariant.BUTTON && (
        <ActionButton
          className="cta-button"
          buttonClassName="cta-button-inner"
          onClick={() => setShowPassengerCountPicker(true)}
          message={
            <Typography
              style={{
                fontSize: "16px",
                color: "white",
              }}
            >
              Change guests
            </Typography>
          }
        />
      )}

      {variant == IOccupanySelectionVariant.RANGE && (
        <B2BButton
          style={{ height: "50px" }}
          className={clsx(showErrorStyles && "error-styles")}
          variant="traveler-selector"
          onClick={() => setShowPassengerCountPicker(true)}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" alignItems="center" style={{ gap: "10px" }}>
              <Icon name={IconName.B2BUser} className="icon-user" />
              <Typography>
                {GUEST_SELECTOR_INPUT_LABEL(
                  adultsCount,
                  childrenCount,
                  petsCount
                )}
              </Typography>
            </Box>
            <Icon name={IconName.B2BEditPencil} className="icon-user" />
          </Box>
        </B2BButton>
      )}

      {variant == IOccupanySelectionVariant.COMPONENT && (
        <Box onClick={() => setShowPassengerCountPicker(true)}>{component}</Box>
      )}

      {showPassengerCountPicker &&
        (matchesMobile ? (
          <MobilePopoverCard
            open={showPassengerCountPicker}
            onClose={() => setShowPassengerCountPicker(false)}
            className="vacation-rentals-mobile-popover-card-overrides"
            topRightButton={
              <ActionLink
                onClick={() => setShowPassengerCountPicker(false)}
                content={<CloseButtonIcon />}
                label="Close"
                showTappableArea
              />
            }
          >
            <PassengerCountPicker
              setPassengerCounts={(counts: PassengerCountPickerType) => {
                handleClickSave(counts as GuestsSelection);
              }}
              onClickApply={() => setShowPassengerCountPicker(false)}
              counts={
                {
                  adults: adultsCount,
                  children: childrenCount,
                  pets: petsCount,
                } as GuestsSelection
              }
              maximumRoomsCount={MAX_ROOM_COUNT}
              maximumTravelersCount={
                maxOccupancy ? maxOccupancy : MAX_TRAVELERS_COUNT
              }
              includeChildrenInMaxCount={true}
              titles={{
                ...MODAL_TITLES,
                petsSubtitle: (
                  <>
                    <Tooltip
                      placement="bottom"
                      title={
                        <Typography
                          variant="subtitle2"
                          className="tooltip-text"
                          dangerouslySetInnerHTML={{
                            __html: PET_TOOLTIP_COPY,
                          }}
                        />
                      }
                      classes={{
                        popper: "pets-info-tooltip-popper",
                        tooltip: "pets-info-tooltip-text",
                      }}
                      enterTouchDelay={50}
                      leaveTouchDelay={2500}
                      tabIndex={0}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="caption">
                          Additional fee may apply
                        </Typography>
                        <Icon name={IconName.InfoCircle} />
                      </div>
                    </Tooltip>
                  </>
                ),
              }}
            />
          </MobilePopoverCard>
        ) : (
          <DesktopPopupModal
            open={showPassengerCountPicker}
            className="desktop-passenger-count-picker-popup"
            contentClassName="desktop-passenger-count-picker-popup-container"
            onClose={() => setShowPassengerCountPicker(false)}
            invisibleBackdrop={false}
            headerElement={"Edit travelers"}
          >
            <PassengerCountPicker
              setPassengerCounts={(counts: PassengerCountPickerType) => {
                handleClickSave(counts as GuestsSelection);
              }}
              onClickApply={() => setShowPassengerCountPicker(false)}
              counts={
                {
                  adults: adultsCount,
                  children: childrenCount,
                  pets: petsCount,
                } as GuestsSelection
              }
              maximumRoomsCount={MAX_ROOM_COUNT}
              maximumTravelersCount={
                maxOccupancy ? maxOccupancy : MAX_TRAVELERS_COUNT
              }
              includeChildrenInMaxCount={true}
              titles={{
                ...MODAL_TITLES,
                petsSubtitle: (
                  <>
                    <Tooltip
                      placement="bottom"
                      title={
                        <Typography
                          variant="subtitle2"
                          className="tooltip-text"
                          dangerouslySetInnerHTML={{
                            __html: PET_TOOLTIP_COPY,
                          }}
                        />
                      }
                      classes={{
                        popper: "pets-info-tooltip-popper",
                        tooltip: "pets-info-tooltip-text",
                      }}
                      enterTouchDelay={50}
                      leaveTouchDelay={2500}
                      tabIndex={0}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="caption">
                          Additional fee may apply
                        </Typography>
                        <Icon name={IconName.InfoCircle} />
                      </div>
                    </Tooltip>
                  </>
                ),
              }}
            />
          </DesktopPopupModal>
        ))}
    </Box>
  );
};
