import React from "react";
import { Box, Typography } from "@material-ui/core";
import { MobileShopHeaderConnectorProps } from "./container";
import { RouteComponentProps } from "react-router";
import { ActionLink, pluralize } from "halifax";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";
import "./styles.scss";
import { goToAvailability } from "../../utils/queryStringHelpers";
import { IMobileDateSelectorVariant } from "../MobileDateSelector/component";
import { ConnectedMobileDateSelector } from "../MobileDateSelector/container";
import { ConnectedOccupancySelector } from "../OccupancySelector/container";
import { IOccupanySelectionVariant } from "../OccupancySelector/component";

export interface IMobileShopHeaderProps
  extends MobileShopHeaderConnectorProps,
    RouteComponentProps {}

export const MobileShopHeader = (props: IMobileShopHeaderProps) => {
  const {
    selectedListing,
    fromDate,
    untilDate,
    numAdults,
    numChildren,
    numPets,
    history,
  } = props;

  const title = selectedListing?.listing?.content?.name || "Stay";
  const maxOccupancy = selectedListing?.listing?.terms?.maxOccupancy || 16;

  const returnToAvailability = () => {
    goToAvailability({
      history,
      listing: selectedListing?.listing,
      fromDate,
      untilDate,
      adultsCount: numAdults,
      childrenCount: numChildren.length,
      petsCount: numPets,
    });
  };

  return (
    <Box
      className="mobile-shop-header"
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      borderBottom=".5px solid var(--grey-11)"
      height="55px"
      style={{ backgroundColor: "white" }}
      paddingX="18px"
    >
      <ActionLink
        onClick={returnToAvailability}
        content={<FontAwesomeIcon icon={faChevronLeft} />}
      />

      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="body1">{title}</Typography>
        <Box display="flex" style={{ gap: "5px" }}>
          <Typography
            style={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#505050",
            }}
          >
            {fromDate &&
              untilDate &&
              `${dayjs(fromDate).format("MMM DD")} - ${dayjs(untilDate).format(
                "MMM DD"
              )}, `}
          </Typography>
          <ConnectedOccupancySelector
            variant={IOccupanySelectionVariant.COMPONENT}
            maxOccupancy={maxOccupancy}
            component={
              <Typography
                style={{
                  fontSize: "12px",
                  fontWeight: "400",
                  color: "var(--blue-5)",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                {`${numAdults + numChildren.length} ${pluralize(
                  numAdults + numChildren.length,
                  "Traveler",
                  "Travelers"
                )}
                `}
              </Typography>
            }
          />
        </Box>
      </Box>

      <ConnectedMobileDateSelector variant={IMobileDateSelectorVariant.ICON} />
    </Box>
  );
};
