import React, { useEffect, useState } from "react";
import { VacationRentalShopConnectorProps } from "./container";
import { RouteComponentProps } from "react-router";
import { Avatar, Box, Divider, Typography } from "@material-ui/core";
import {
  AmenityIcon,
  GenericInfoPopup,
  Icon,
  IconName,
  PageTabNavigations,
  pluralize,
  useDeviceTypes,
} from "halifax";
import {
  Amenity,
  ListingSearchResult,
} from "redmond/apis/tysons/vacation-rentals";
import H from "history";
import {
  MOST_POPULAR_AMENITIES,
  RETURN_TO_SEARCH_MODAL_PRIMARY_BUTTON_TEXT,
  RETURN_TO_SEARCH_MODAL_SECONDARY_TEXT,
  VR_NOT_AVAILABLE_MODAL_TITLE,
} from "./constants";
import { TruncatedText } from "../TruncatedText/component";
import { ConnectedShopCta } from "../ShopCta/container";
import { IShopCtaVariant } from "../ShopCta/component";
import { ConnectedDesktopRewardsHeader } from "../../../rewards/components/DesktopRewardsHeader/container";
import { ConnectedRewardsAccountSelection } from "../../../rewards/components/RewardsAccountSelection/container";
import { ConnectedMobileShopHeader } from "../MobileShopHeader/container";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import {
  MODAL_ALERT,
  ModalCategoryType,
  ModalScreens,
  VIEWED_VR_DETAILS,
} from "redmond";
import { VacationRentalTerms } from "../../../common/VacationRentalTerms/component";
import { VacationRentalImportantInformation } from "../../../common/VacationRentalImportantInformation/component";
import { Map } from "../Map/component";
import "./styles.scss";
import { ReviewsSection } from "../Reviews/component";
import {
  getUserRatingQualifier,
  ReviewIconTooltip,
  ReviewScore,
} from "../Reviews/helpers";
import {
  AVAILABLE,
  getExperimentVariant,
  useExperiments,
  VR_COVER_REVIEWS_EXPERIMENT,
} from "../../../../context/experiments";
import { RoomsAndBeds } from "../RoomsAndBeds/components";
import { CategorizedAmenities } from "../CategorizedAmenities/component";
import { useInView } from "react-intersection-observer";
import { ConnectedPhotoGallery } from "../PhotoGallery/container";
import { PATH_STAYS } from "../../../../utils/paths";
import { VacationRentalsAlertProperties } from "../../../common";
import dayjs from "dayjs";
import { ShopSkeleton } from "../ShopSkeleton/component";

export interface IShopProps
  extends VacationRentalShopConnectorProps,
    RouteComponentProps {}

export const Shop = (props: IShopProps) => {
  const {
    history,
    selectedListing,
    fetchedVacationRentalDetails,
    viewedVacationRentalDetailsProperties,
    fromDate,
    untilDate,
  } = props;

  useEffect(() => {
    if (fetchedVacationRentalDetails) {
      trackEvent({
        eventName: VIEWED_VR_DETAILS,
        ...viewedVacationRentalDetailsProperties,
      });
    }
  }, [fetchedVacationRentalDetails]);

  if (!fetchedVacationRentalDetails) {
    return <ShopSkeleton />;
  }

  if (!selectedListing) {
    return (
      <ShopErrorModal
        history={history}
        fromDate={fromDate}
        untilDate={untilDate}
      />
    );
  }

  return <ShopListingDetails selectedListing={selectedListing} />;
};

interface ShopErrorModalProps {
  history: H.History;
  selectedListing?: ListingSearchResult;
  fromDate?: Date | null;
  untilDate?: Date | null;
}

const ShopErrorModal = ({
  history,
  selectedListing,
  fromDate,
  untilDate,
}: ShopErrorModalProps) => {
  const { matchesMobile } = useDeviceTypes();

  const redirectToStays = () => {
    history.push(`${PATH_STAYS}`);
  };

  const modalEventProperties: VacationRentalsAlertProperties = {
    lob: "vacation rentals",
    type: "vacation_rentals_shop_error",
    modal_type: "vacation_rentals_shop_error",
    screen: ModalScreens.VR_DETAILS,
    primary_button: RETURN_TO_SEARCH_MODAL_PRIMARY_BUTTON_TEXT || "",
    secondary_button: "",
    category: ModalCategoryType.TROUBLE,
    home_id: selectedListing?.listingId.id,
    check_in_date: fromDate ? dayjs(fromDate).format("YYYY-MM-DD") : undefined,
    check_out_date: untilDate
      ? dayjs(untilDate).format("YYYY-MM-DD")
      : undefined,
    funnel: "vacation_rentals",
    step: "shop",
    modal_title: VR_NOT_AVAILABLE_MODAL_TITLE,
    modal_subtitle: RETURN_TO_SEARCH_MODAL_SECONDARY_TEXT,
    agent_subtitle: "",
    agent_title: "",
  };

  useEffect(() => {
    trackEvent({
      eventName: MODAL_ALERT,
      properties: modalEventProperties,
    });
  }, []);

  const buttons = [
    {
      buttonText: RETURN_TO_SEARCH_MODAL_PRIMARY_BUTTON_TEXT,
      defaultStyle: "h4r-primary",
      onClick: () => {
        redirectToStays();
      },
    },
  ];

  return (
    <GenericInfoPopup
      buttons={buttons}
      className="booking-error-modal"
      image={<Icon className="error-icon" name={IconName.ErrorState} />}
      isMobile={matchesMobile}
      open={true}
      title={VR_NOT_AVAILABLE_MODAL_TITLE}
      subtitle={RETURN_TO_SEARCH_MODAL_SECONDARY_TEXT}
      zIndex={matchesMobile ? 1301 : 1300}
    />
  );
};

interface ShopListingDetailsProps {
  selectedListing: ListingSearchResult;
}

const ShopListingDetails = (props: ShopListingDetailsProps) => {
  const { selectedListing } = props;

  const { matchesDesktop, matchesMobile } = useDeviceTypes();
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const [isTabScrolling, setIsTabScrolling] = useState<boolean>(false);

  const expState = useExperiments();

  const showReviews =
    getExperimentVariant(expState.experiments, VR_COVER_REVIEWS_EXPERIMENT) ===
    AVAILABLE;

  const { availability, listing } = selectedListing;
  const { content, hostInfo, terms, reviews } = listing;
  const {
    name,
    description,
    amenities,
    layout,
    location,
    userRating,
    registrationId,
  } = content;
  const { bathrooms, bedrooms, numberOfBedrooms, numberOfBathrooms } = layout;

  const { hostName, hostImageUrl } = hostInfo || {};
  const { exactCoordinates } = location;

  const listingGoogleCoordinates = new google.maps.LatLng({
    lat: exactCoordinates.lat,
    lng: exactCoordinates.lon,
  });

  const {
    checkInTimes,
    checkOutTimes,
    checkinInformation,
    importantInformation,
    petsPolicy,
    petFriendly,
    maxOccupancy,
  } = terms;

  const { rate } = availability;

  const cancellationPolicy = rate?.cancellationPolicy?.policy;
  const paymentPolicy = rate?.paymentPolicy;

  const renderMaxOccupancyIconLabel = (maxOccupancy: number) => {
    return (
      maxOccupancy > 0 && (
        <Box display="flex" alignItems={"center"}>
          <Icon name={IconName.UserOutline} className="icon-user-outline" />
          <Typography
            style={{ fontSize: "14px", color: "#141414", paddingLeft: "4px" }}
          >
            Up to {maxOccupancy} {pluralize(maxOccupancy, "guest", "guests")}
          </Typography>
        </Box>
      )
    );
  };

  const renderNumberOfBedroomsIconLabel = (numBedrooms: number) => {
    return (
      numBedrooms > 0 && (
        <Box display="flex" alignItems="center">
          <Icon name={IconName.BedOutlineIcon} className="icon-bedroom" />
          <Typography
            variant="body1"
            style={{ fontSize: "14px", color: "#141414", paddingLeft: "4px" }}
          >
            {numBedrooms} {pluralize(numBedrooms, "bedroom", "bedrooms")}
          </Typography>
        </Box>
      )
    );
  };

  const renderNumberOfBathroomsIconLabel = (numBathrooms: number) => {
    return (
      numBathrooms > 0 && (
        <Box display="flex" alignItems="center">
          <Icon name={IconName.BathTubOutlineIcon} className="icon-bathroom" />
          <Typography
            style={{ fontSize: "14px", color: "#141414", paddingLeft: "4px" }}
          >
            {numBathrooms} {pluralize(numBathrooms, "bathroom", "bathrooms")}
          </Typography>
        </Box>
      )
    );
  };

  const renderAmenityIconLabel = (amenity: Amenity) => {
    return (
      <Box
        display="flex"
        alignItems="flex-start"
        className="icon-fill-black"
        key={amenity.kind}
      >
        <AmenityIcon amenity={amenity.kind} />
        <Typography
          style={{
            fontSize: "14px",
            color: "#141414",
            paddingLeft: "4px",
          }}
        >
          {amenity.displayName}
        </Typography>
      </Box>
    );
  };

  /** Navbar */
  const tabs = [
    {
      label: "Gallery",
      onClick: () => scrollToSection("gallery"),
    },

    { label: "Overview", onClick: () => scrollToSection("overview") },
    {
      label: "About",
      onClick: () => scrollToSection("about"),
    },
    {
      label: "Amenities",
      onClick: () => scrollToSection("amenities"),
    },
    ...(showReviews && reviews && reviews.length > 0
      ? [
          {
            label: "Reviews",
            onClick: () => scrollToSection("reviews"),
          },
        ]
      : []),
    {
      label: "Location",
      onClick: () => scrollToSection("location"),
    },
    {
      label: "House rules",
      onClick: () => scrollToSection("house-rules"),
    },
  ];

  const scrollToSection = (section: string) => {
    const elem = window.document.getElementById(section);
    if (elem) {
      setIsTabScrolling(true);

      const offset = 50 + 80 + 60 + 30; // 50 (banner) + 80 (toolbar) + 60 (navigation) + 30 (padding)
      const y = elem.getBoundingClientRect().top + window.scrollY - offset;

      window.scrollTo({ top: y, behavior: "smooth" });
      setTimeout(() => {
        setIsTabScrolling(false);
      }, 500);
    }
  };

  const [gallerySectionRef, gallerySectionInView] = useInView({
    threshold: 1,
    rootMargin: "0px 0px 500px 0px",
  });
  const [overviewSectionRef, overviewSectionInView] = useInView({
    threshold: 1,
    rootMargin: "0px 0px 500px 0px",
  });
  const [aboutSectionRef, aboutSectionInView] = useInView({
    threshold: 0.8,
    rootMargin: "0px 0px 500px 0px",
  });
  const [amenitiesSectionRef, amenitiesSectionInView] = useInView({
    threshold: 0.8,
  });
  const [reviewsSectionRef, reviewsSectionInView] = useInView({
    threshold: 0.8,
  });
  const [locationSectionRef, locationSectionInView] = useInView({
    threshold: 0.8,
  });
  const [houseRulesSectionRef, houseRulesSectionInView] = useInView({
    threshold: 0.5,
  });

  useEffect(() => {
    if (!isTabScrolling) {
      if (gallerySectionInView) {
        setSelectedTabIndex(0);
      } else if (overviewSectionInView) {
        setSelectedTabIndex(1);
      } else if (aboutSectionInView) {
        setSelectedTabIndex(2);
      } else if (amenitiesSectionInView) {
        setSelectedTabIndex(3);
      } else if (reviewsSectionInView) {
        setSelectedTabIndex(4);
      } else if (locationSectionInView) {
        setSelectedTabIndex(5);
      } else if (houseRulesSectionInView) {
        setSelectedTabIndex(6);
      }
    }
  }, [
    gallerySectionInView,
    overviewSectionInView,
    aboutSectionInView,
    amenitiesSectionInView,
    reviewsSectionInView,
    locationSectionInView,
    houseRulesSectionInView,
  ]);

  /** Sections */

  const renderOverview = (showPricing: boolean) => {
    return (
      <div id="overview" ref={overviewSectionRef}>
        <Box className="section">
          <Box
            display="flex"
            justifyContent="space-between"
            paddingBottom="20px"
          >
            <Typography
              className="title"
              style={{ padding: "0px", paddingRight: "5px" }}
            >
              {name}
            </Typography>
            {userRating && userRating?.score && (
              <Box display="flex" flexDirection="row" alignItems="baseline">
                <Typography style={{ color: "#141414" }}>
                  {getUserRatingQualifier(userRating?.score)}
                </Typography>
                <ReviewIconTooltip />
                <ReviewScore score={userRating?.score} size="lg" />
              </Box>
            )}
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            paddingBottom="20px"
            style={{ gap: "20px" }}
          >
            {renderMaxOccupancyIconLabel(maxOccupancy)}
            {renderNumberOfBedroomsIconLabel(numberOfBedrooms)}
            {renderNumberOfBathroomsIconLabel(numberOfBathrooms)}
          </Box>
          {showPricing && (
            <>
              <Divider style={{ marginBottom: "20px" }} />
              <Box display="flex" flexDirection="column" marginBottom="20px">
                <ConnectedShopCta variant={IShopCtaVariant.PRICING_ONLY} />
              </Box>
            </>
          )}
          <Divider style={{ marginBottom: "20px" }} />
          <Typography variant="h6" style={{ paddingBottom: "20px" }}>
            Most popular amenities
          </Typography>
          <Box
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            style={{ gap: "20px" }}
          >
            {amenities
              .filter((x) => MOST_POPULAR_AMENITIES.includes(x.kind))
              .map((y) => renderAmenityIconLabel(y))}
          </Box>
        </Box>
      </div>
    );
  };

  const renderAbout = () => {
    return (
      <div id="about" ref={aboutSectionRef}>
        <Box className="section">
          <Typography className="title">About</Typography>
          <Box display="flex" flexDirection="column" style={{ gap: "20px" }}>
            {hostName && (
              <Box display="flex" alignItems="center" style={{ gap: "15px" }}>
                <Avatar src={hostImageUrl} />
                <Typography variant="h6" style={{ color: "#141414" }}>
                  {hostName ? `Hosted by ${hostName}` : "Your host"}
                </Typography>
              </Box>
            )}
            <TruncatedText text={description} limit={500} setAsHTML={true} />
          </Box>
        </Box>
      </div>
    );
  };

  const renderRoomsAndBeds = () => {
    return (
      bedrooms.length > 0 && (
        <div id="rooms-and-beds">
          <Box className="section">
            <Typography className="title">Rooms and beds</Typography>
            <Box paddingBottom="20px">
              <RoomsAndBeds bedrooms={bedrooms} bathrooms={bathrooms} />
            </Box>
          </Box>
        </div>
      )
    );
  };

  const renderAmenities = () => {
    return (
      <div id="amenities" ref={amenitiesSectionRef}>
        <Box className="section">
          <Typography className="title">Amenities and features</Typography>
          <CategorizedAmenities amenities={amenities} />
        </Box>
      </div>
    );
  };

  const renderReviews = () => {
    return (
      showReviews &&
      reviews &&
      reviews.length > 0 && (
        <div id="reviews" ref={reviewsSectionRef}>
          <Box className="section">
            <Typography className="title">Customer Reviews</Typography>
            <ReviewsSection score={userRating?.score} reviews={reviews} />
          </Box>
        </div>
      )
    );
  };

  const renderLocation = () => {
    return (
      <div id="location" ref={locationSectionRef}>
        <Box className="section">
          <Typography className="title">Location</Typography>
          <Map
            listingName={name}
            location={location}
            coordinates={listingGoogleCoordinates}
            matchesMobile={matchesMobile}
          />
        </Box>
      </div>
    );
  };

  const renderHouseRules = () => {
    return (
      <div id="house-rules" ref={houseRulesSectionRef}>
        <VacationRentalTerms
          checkinInformation={checkinInformation}
          checkInTimes={checkInTimes}
          checkOutTimes={checkOutTimes}
          petFriendly={petFriendly}
          petsPolicy={petsPolicy}
          cancellationPolicy={cancellationPolicy}
          paymentPolicy={paymentPolicy}
        />
      </div>
    );
  };

  const renderImportantInformation = () => {
    return (
      (importantInformation || registrationId) && (
        <Box>
          <VacationRentalImportantInformation
            importantInformation={importantInformation}
            registrationId={registrationId}
          />
        </Box>
      )
    );
  };

  return (
    <Box className="vacation-rental-shop" style={{ backgroundColor: "white" }}>
      {/* Mobile */}
      {matchesMobile && (
        <Box className="shop-mobile" display="flex" flexDirection="column">
          <ConnectedMobileShopHeader />
          <Box
            padding="18px"
            style={{ backgroundColor: "#FBFBFB" }}
            borderBottom="0.5px solid var(--grey-11)"
          >
            <ConnectedRewardsAccountSelection />
          </Box>
          <Box id="gallery">
            <ConnectedPhotoGallery />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            padding="20px"
            style={{ backgroundColor: "#FBFBFB" }}
          >
            {renderOverview(true)}
            {renderAbout()}
            {renderRoomsAndBeds()}
            {renderAmenities()}
            {renderReviews()}
            {renderLocation()}
            {renderHouseRules()}
            {renderImportantInformation()}
          </Box>
          <Box
            position="fixed"
            bottom="0px"
            boxShadow="0px -2px 10px rgba(0, 0, 0, 0.2)"
            width="100%"
          >
            <ConnectedShopCta variant={IShopCtaVariant.MOBILE_CTA_BUTTON} />
          </Box>
        </Box>
      )}

      {/* Desktop */}
      {matchesDesktop && (
        <Box className="shop-desktop" display="flex" flexDirection="column">
          <ConnectedDesktopRewardsHeader />
          <div ref={gallerySectionRef} id="gallery">
            <Box paddingX="120px">
              <ConnectedPhotoGallery />
            </Box>
          </div>
          <PageTabNavigations
            className="navigation-overrides"
            navItems={tabs}
            selectedTab={selectedTabIndex}
            rightContent={<></>}
          />
          <Box
            display="flex"
            flexDirection="row"
            style={{ gap: "20px", backgroundColor: "#FBFBFB" }}
            paddingX="120px"
            paddingY="30px"
          >
            <Box display="flex" flexDirection="column">
              {renderOverview(false)}
              {renderAbout()}
              {renderRoomsAndBeds()}
              {renderAmenities()}
              {renderReviews()}
              {renderLocation()}
              {renderHouseRules()}
              {renderImportantInformation()}
            </Box>

            <Box
              position="sticky"
              minWidth="450px"
              maxWidth="500px"
              width="100%"
              height="100%"
              style={{ top: "220px", backgroundColor: "white" }}
              display="flex"
              flexDirection="column"
              border="solid 0.5px #CECFCD"
              borderRadius="8px"
            >
              <ConnectedShopCta variant={IShopCtaVariant.DESKTOP} />
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};
