// Flights
export const FLIGHT_CFAR_TERMS_BODY = (portalName: string) => `
The option to cancel a flight for any reason (marketed interchangeably as Cancel for Any Reason or a fare made partially refundable by ${portalName}) purchased through ${portalName} is subject to the following Terms and Conditions. Availability of this service may vary, and not all services are available to all customers or on all bookings. ${portalName} may revise these Terms and Conditions at any time by updating this posting.
<br/><br/>
Some of the services made available through ${portalName} are offered by Hopper (USA) Inc. and its affiliated companies (“Hopper”). Your use of the services offered by Hopper, and other third-parties that may be linked to from the Sites, will be governed by their respective Terms and Conditions and Privacy Policies.
<br/><br/>
With ${portalName}, you may be eligible to purchase the option to cancel your flight purchase for any reason, <strong>up to three (3) hours</strong> prior to your first scheduled departure, and receive a partial refund from ${portalName}, as set forth in these terms. You may also be eligible for a travel credit from your respective airline carrier, without purchasing the option to cancel for any reason. The ability to cancel for any reason is subject to the following terms:
<ol>
<li style="font-weight: 600;"><p style="font-weight: 400;"><strong>Purchases.</strong> The option to cancel for any reason with ${portalName} may only be purchased at the time of booking, and may not be added after the booking. The option to cancel for any reason must be purchased for all passengers on the booking, and for all segments or legs of such booking. Capital One reserves the right to deny purchases of the option for any reason permitted by law (e.g., fraud or misuse).</p></li>

<li style="font-weight: 600;"><p style="font-weight: 400;"><strong>Non-refundability.</strong> The cost of the option to cancel for any reason with ${portalName} is non-refundable and is only valid for the booking for which the option to cancel for any reason was purchased. The option to cancel for any reason is not transferable and cannot be carried over to any future bookings.</p></li>

<li style="font-weight: 600;"><p style="font-weight: 400;"><strong>Refund or Credit.</strong> By purchasing the option to cancel for any reason, you are eligible to receive a partial refund from ${portalName}. Prior to purchasing the option to cancel for any reason, your flight may be eligible for a travel credit provided by the respective airline carrier. If eligible, this option will be available to you regardless of whether you purchase the option to cancel for any reason. In that case, however, if you purchased the option to cancel for any reason through ${portalName}, you will not be charged any additional cancellation or rebooking fees when you use the travel credit from the respective airline carrier to rebook through ${portalName}. When you exercise the option to cancel for any reason, you will have the option to choose between a partial refund or a travel credit from the airline, if applicable. In the event your underlying flight purchase is serviced by multiple airline carriers, you may be required to contact ${portalName} at 844-422-6922 to exercise your option to cancel for any reason.</p></li>

<li style="font-weight: 600;"><p style="font-weight: 400;"><strong>Additional Information.</strong> For more detailed information regarding your existing cancellation policy from the respective airline carrier, visit the airline’s website.</p></li>

<li style="font-weight: 600;"><p style="font-weight: 400;"><strong>Coverage.</strong> Refunds cover the base fare and related taxes and fees and do not entitle you to a full refund of all trip costs. Any paid add-ons, such as baggage, seat or boarding order selection, and cancel for any reason, are not included in the refund.
<br/><br/>
Airlines may offer a travel credit for add-ons when the travel credit option is chosen. If offered, such travel credit will be provided separately from the base fare travel credit. Contact the respective airline carrier to inquire about receiving an additional travel credit for the add-ons.</p></li>

<li style="font-weight: 600;"><p style="font-weight: 400;"><strong>Exercise process.</strong> If you have purchased the option to cancel for any reason for a flight purchased through ${portalName}, you may cancel that purchase by visiting ${portalName}, navigating to My Trips and selecting the itinerary. No documentation or reason will be required in order to cancel.</p></li>

<li style="font-weight: 600;"><p style="font-weight: 400;"><strong>Cancellation.</strong> Exercising your option to cancel for any reason for any flight in the booking will result in a cancellation of all purchased flights for that traveler for all legs or segments (i.e., outbound and return flights). If multiple travelers are on the flight booking, you can cancel the flight for individual travelers by calling ${portalName}, or for all passengers by canceling online. <strong>Cancellations cannot be reversed.</strong></p></li>

<li style="font-weight: 600;"><p style="font-weight: 400;"><strong>Exercise window.</strong> Except as provided in Section 10 below, you must exercise your purchased flight cancellation through ${portalName}’s travel booking site or via phone agent at least three (3) hours prior to your first scheduled departure. After this deadline has passed, the option to cancel for any reason will expire and any changes or cancellations to your purchased flight will be subject to the fare rules imposed by the respective airline carrier for your ticket.</p></li>

<li style="font-weight: 600;"><p style="font-weight: 400;"><strong>Refund.</strong> Upon a valid exercise of your option to cancel for any reason, ${portalName} will refund to you a portion of the flight cost (including taxes and fees), or, if offered by the respective airline carrier for your ticket and you so select, a travel credit, subject to the airline’s terms and conditions.The refund portion is specified upon your purchase of the cancellation option. Reimbursements should be processed within 1-2 billing cycles. If assessed by the respective airline carrier, ${portalName} will cover any applicable cancellation fees for the travel credit option. Airlines are responsible for fulfilling travel credits.</p></li>

<li style="font-weight: 600;"><p style="font-weight: 400;"><strong>Exclusions.</strong> The option to cancel for any reason does not apply following the earlier of:
<br/><br/>
a. A cancellation of any flight by an airline carrier for any reason, including the result of a force majeure event;
<br/><br/>
b. Any voluntarily cancellation or change requested by you directly with any airline, or, if applicable, through ${portalName}’s flight disruption assistance service, of any tickets subject to the cancel for any reason;
<br/><br/>
c. A declaration of an “emergency” or similar “extraordinary measures” taken by any governmental authority in the destination city, region, or country of your ${portalName} trip, or issuance of a Safety and Security Alert by the United States Department of State or a Travel Health Notice Warning by the United States Centers for Disease Control and Prevention (CDC) for such destination; or
<br/><br/>
d. A designation by the World Health Organization (WHO) that the destination city, country, or region of your trip is at risk from COVID-19.
<br/><br/>
In the event of such cancellation, or declaration of emergency, alert, notice, designation, or other similar government action, your option to cancel for any reason shall expire immediately and a refund of your ${portalName} purchase will be subject to the fare rules imposed by the respective airline carrier for your ticket.</p></li>

<li style="font-weight: 600;"><p style="font-weight: 400;"><strong>Travel Credit.</strong> When elected, you will be provided a travel credit subject to the terms and conditions of the respective airline carrier. Travel credit restrictions can include limitations to availability, flight routes, and passengers. A full description of terms and conditions can be found on the respective airline carrier’s website.</p></li>

<li style="font-weight: 600;"><p style="font-weight: 400;"><strong>Refund Reductions.</strong> If you purchased the option to cancel for any reason from a price you froze through ${portalName}, the cost of your cancel for any reason option, and the amount of any refund in the event you elect to cancel, will be based on the final price you paid for your frozen flight. Similarly, if you received a travel credit via the price drop protection feature after purchasing the flight subject to the option to cancel for any reason, the amount of any refund in the event you elect to cancel will be reduced by the amount of the travel credit you previously received via the price drop protection feature.</p></li>

<li style="font-weight: 600;"><p style="font-weight: 400;"><strong>Modifications.</strong> If your purchased ${portalName} trip has been modified at your request after booking, the “cancel for any reason” will apply only to the original amount of the purchased trip. Certain modifications, such as changes to a passenger name or changes not applicable to all passengers on a multi-passenger booking, may require that you contact ${portalName} at 844-422-6922 in order to exercise your option to cancel for any reason.</p></li>

<li style="font-weight: 600;"><p style="font-weight: 400;"><strong>Not insurance.</strong> The option to cancel your flight purchase for any reason ensures that the respective airline carrier’s tickets purchased on the Sites can be canceled for any reason without incurring additional fees. ${portalName} provides its customers with this option to provide a more seamless and flexible experience for travel booked through the Sites. The option to cancel for any reason and the related terms and conditions are not intended to constitute an offer to insure, do not constitute insurance or an insurance contract, and do not take the place of insurance obtained or obtainable by you.</p></li>

<li style="font-weight: 600;"><p style="font-weight: 400;"><strong>Contact.</strong> For any questions, please contact ${portalName} at 844-422-6922.</p></li>
</ol>
`;

export const DISCOUNT_PROMO_TERMS_CONDITIONS = (portalName: string) =>
  `<strong>Promotion Terms and Conditions:</strong> This promotion can only be applied to the add-on fee to cancel a flight for any reason (marketed as Cancel for Any Reason or a fare made partially refundable by ${portalName}) purchased through ${portalName}. This promotion will provide customers with a discount for the add-on fee, as specified during the booking process. This limited-time promotion can only be applied to eligible flights that depart during the specified offer window. This promotion is only available while supplies last and for one booking per account. This promotion cannot be retroactively applied to bookings made before the promotion starts, and will not be applied to any bookings made after the promotion expires. Your Capital One account must be open and in good standing to be eligible for the discount. This promotion is valid for consumer, non-commercial use only. This promotion is non-transferrable, may not be redeemed for cash, and may not be combined with other promotions unless specified. We reserve the rights to: alter or terminate a promotion at any time; change these terms and conditions associated with promotions; and/or disqualify any account holder from any promotions.`;

export const REFUNDABLE_FARES_TITLE = `Make your flight <strong>refundable</strong>.`;

export const REFUNDABLE_FARES_LOCATION = "Tasmania, Australia";

// CFAR coverage will always be 80% for cap1
export const REFUND_COVERAGE_PERCENTAGE_HARDCODE_80 =
  "Cancel and get an <strong>80% refund</strong> of your flight base fare and taxes.";

export const TERMS_COPY = "Read terms and conditions";

const DEFAULT_CASH_COVERAGE_PERCENTAGE = 80;

type Punctuation = "." | "!" | "?";

export const CFAR_SOCIAL_PROOFING_USER_COUNT = "60,000";

export const CFAR_SOCIAL_PROOFING_COPY = `<p class="social-proofing-prefix">Over </p><p class="social-proofing-count">${CFAR_SOCIAL_PROOFING_USER_COUNT}</p><p class="social-proofing-suffix"> travelers have added this service to their flight booking.</p>`;

export const CFAR_POINT_ONE = `Add the flexibility to <strong>cancel your flight for any reason</strong> up to 3 hours before departure.`;

export const REFUNDABLE_FARES_POINT_ONE = (args?: {
  variant?: "v1" | "default";
  useStrong?: boolean;
  punctuation?: Punctuation;
}) => {
  const { variant, useStrong, punctuation } = args ?? {};
  return `${useStrong ? `<strong>` : ""}Cancel${
    variant === "v1" ? " your flight" : ""
  } for any reason${
    useStrong ? `</strong>` : ""
  } up to 3 hours before departure${punctuation ?? ""}`;
};

const getRefundableFarePointTwoText = ({
  refundAmount,
  airlineFTCAmount,
  coveragePercentage,
  punctuation,
  includeWithFeesCopy,
}: {
  refundAmount: number;
  airlineFTCAmount: number;
  coveragePercentage: number;
  punctuation?: string;
  includeWithFeesCopy?: boolean;
}) => {
  return `Choose between an ${coveragePercentage}% refund <strong>($${refundAmount.toLocaleString(
    "en-US"
  )})</strong> of your flight base fare and taxes or a 100% airline travel credit <strong>($${Math.round(
    airlineFTCAmount
  ).toLocaleString("en-US")})</strong>${
    includeWithFeesCopy ? ", with all fees covered" : ""
  }${punctuation ?? ""}`;
};

export const POINT_TWO_FTC_NO_FEES_TEXT = ({
  percent,
  useStrong,
  coMerch,
  refundAmount,
  airlineFTCAmount,
  punctuation,
  concise,
  showRefundAmountInSecondBulletText,
}: {
  percent: number | undefined;
  useStrong: boolean;
  coMerch?: boolean;
  refundAmount?: number;
  airlineFTCAmount?: number;
  punctuation?: string;
  concise?: boolean;
  showRefundAmountInSecondBulletText?: boolean;
}) => {
  if (showRefundAmountInSecondBulletText && refundAmount) {
    return `${concise ? "Choose" : "Cancel and choose"} between ${
      coMerch ? "a" : "an"
    } ${useStrong ? `<strong>` : ""}${
      coMerch ? "partial" : percent ?? DEFAULT_CASH_COVERAGE_PERCENTAGE
    }${coMerch ? "" : "%"} refund${
      useStrong ? `</strong>` : ""
    } <strong>($${refundAmount.toLocaleString(
      "en-US"
    )})</strong> of your flight base fare and taxes or a ${
      useStrong ? `<strong>` : ""
    }100% airline travel credit${useStrong ? `</strong>` : ""}${
      punctuation ?? ""
    }`;
  }

  //This is a RF only copy as RF is the only flow that sends both airlineFTCAmount and refundAmount
  if (refundAmount && airlineFTCAmount) {
    return getRefundableFarePointTwoText({
      refundAmount,
      airlineFTCAmount,
      coveragePercentage: percent ?? DEFAULT_CASH_COVERAGE_PERCENTAGE,
      punctuation,
      includeWithFeesCopy: false,
    });
  }
  return `${concise ? "Choose" : "Cancel and choose"} between ${
    coMerch ? "a" : "an"
  } ${useStrong ? `<strong>` : ""}${
    coMerch ? "partial" : percent ?? DEFAULT_CASH_COVERAGE_PERCENTAGE
  }${coMerch ? "" : "%"} refund${
    useStrong ? `</strong>` : ""
  } of your flight base fare and taxes or a ${
    useStrong ? `<strong>` : ""
  }100% airline travel credit${useStrong ? `</strong>` : ""}${
    punctuation ?? ""
  }`;
};

export const POINT_TWO_FTC_WITH_FEES_TEXT = ({
  percent,
  useStrong,
  coMerch,
  refundAmount,
  airlineFTCAmount,
  punctuation,
  concise,
  showRefundAmountInSecondBulletText,
}: {
  percent: number | undefined;
  useStrong: boolean;
  coMerch?: boolean;
  refundAmount?: number;
  airlineFTCAmount?: number;
  punctuation?: string;
  concise?: boolean;
  showRefundAmountInSecondBulletText?: boolean;
}) => {
  if (showRefundAmountInSecondBulletText && refundAmount) {
    return `${concise ? "Choose" : "Cancel and choose"} between ${
      coMerch ? "a" : "an"
    } ${useStrong ? `<strong>` : ""}${
      coMerch ? "partial" : percent ?? DEFAULT_CASH_COVERAGE_PERCENTAGE
    }${coMerch ? "" : "%"} refund${
      useStrong ? `</strong>` : ""
    } <strong>($${refundAmount.toLocaleString(
      "en-US"
    )})</strong> of your flight base fare and taxes or a ${
      useStrong ? `<strong>` : ""
    }100% airline travel credit${useStrong ? `</strong>` : ""}${
      coMerch ? "" : ", with all fees covered"
    }${punctuation ?? ""}`;
  }
  //This is a RF only copy as RF is the only flow that sends both airlineFTCAmount and refundAmount
  if (refundAmount && airlineFTCAmount) {
    return getRefundableFarePointTwoText({
      refundAmount,
      airlineFTCAmount,
      coveragePercentage: percent ?? DEFAULT_CASH_COVERAGE_PERCENTAGE,
      punctuation,
      includeWithFeesCopy: true,
    });
  }
  return `${concise ? "Choose" : "Cancel and choose"} between ${
    coMerch ? "a" : "an"
  } ${useStrong ? `<strong>` : ""}${
    coMerch ? "partial" : percent ?? DEFAULT_CASH_COVERAGE_PERCENTAGE
  }${coMerch ? "" : "%"} refund${
    useStrong ? `</strong>` : ""
  } of your flight base fare and taxes or a ${
    useStrong ? `<strong>` : ""
  }100% airline travel credit${useStrong ? `</strong>` : ""}${
    coMerch ? "" : ", with all fees covered"
  }${punctuation ?? ""}`;
};

export const POINT_TWO_NO_FTC_TEXT = ({
  percent,
  useStrong,
  coMerch,
  refundAmount,
  punctuation,
  showRefundAmountInSecondBulletText,
}: {
  percent: number | undefined;
  useStrong: boolean;
  coMerch?: boolean;
  refundAmount?: number;
  punctuation?: string;
  showRefundAmountInSecondBulletText?: boolean;
}) => {
  if (showRefundAmountInSecondBulletText && refundAmount) {
    return `Cancel and get ${coMerch ? "a" : "an"} ${
      useStrong ? `<strong>` : ""
    }${coMerch ? "partial" : percent ?? DEFAULT_CASH_COVERAGE_PERCENTAGE}${
      coMerch ? "" : "%"
    } refund${
      useStrong ? `</strong>` : ""
    } <strong>($${refundAmount.toLocaleString(
      "en-US"
    )})</strong> of your flight base fare and taxes${punctuation ?? ""}`;
  }
  if (refundAmount) {
    return `Get an ${
      percent ?? DEFAULT_CASH_COVERAGE_PERCENTAGE
    }% refund <strong>($${refundAmount.toLocaleString(
      "en-US"
    )})</strong> of your flight base fare and taxes${punctuation ?? ""}`;
  }
  return `Cancel and get ${coMerch ? "a" : "an"} ${
    useStrong ? `<strong>` : ""
  }${coMerch ? "partial" : percent ?? DEFAULT_CASH_COVERAGE_PERCENTAGE}${
    coMerch ? "" : "%"
  } refund${useStrong ? `</strong>` : ""} of your flight base fare and taxes${
    punctuation ?? ""
  }`;
};

export const CFAR_POINT_THREE = `Get a resolution online without contacting the airline or completing forms.`;

export const REFUNDABLE_FARES_POINT_THREE = (
  portalName: string,
  args?: {
    punctuation?: Punctuation;
  }
) => {
  const { punctuation } = args ?? {};
  return `Get a resolution through ${portalName} without contacting the airline or completing forms${
    punctuation ?? ""
  }`;
};

export const CREDIT_AVAILABLE_WITH_NO_FEES_LEGAL_COPY = `Without this option, your ticket isn’t refundable for cash — but you’re eligible for a 100% travel credit from the airline if you cancel. Cancel for any reason provides an alternative refund option.`;
export const CREDIT_AVAILABLE_WITH_NO_FEES_LEGAL_COPY_UPDATED_COPY = `Without this option, your ticket isn’t refundable for cash — but you’re eligible for a 100% travel credit from the airline if you cancel. Cancel for any reason provides an alternative refund option.`;
export const CREDIT_AVAILABLE_WITH_FEES_LEGAL_COPY = `Without this option, your ticket isn’t refundable for cash — but you’re eligible for a 100% travel credit from the airline (minus any penalties) if you cancel. Cancel for any reason provides an alternative refund option.`;
export const CREDIT_AVAILABLE_WITH_FEES_LEGAL_COPY_UPDATED_COPY = `Without this option, your ticket isn’t refundable for cash—but you’re eligible for a 100% travel credit from the airline (minus any penalties) if you cancel. Cancel for any reason provides an alternative refund option.`;
export const CREDIT_NOT_AVAILABLE_LEGAL_COPY = `If you don’t add this option your ticket is non-refundable.`;
export const CREDIT_NOT_AVAILABLE_LEGAL_COPY_UPDATED_COPY = `If you don’t add this option, your ticket is nonrefundable.`;

export const CREDIT_AVAILABLE_SPIRIT_FRONTIER_TF_ONLY = `Without this option, your ticket isn’t refundable for cash — but you’re eligible for a travel credit from the airline (minus any penalties) if you cancel. Cancel for any reason provides an alternative refund option.`;

export const FTC_WITH_PENALTY_AND_FTC_NO_PENALTY = (
  airlineA: string,
  airlineB: string
) =>
  `Without this option, your ticket isn’t refundable for cash — but you’re eligible for a 100% travel credit from ${airlineA} and ${airlineB} if you cancel. Cancel for any reason provides an alternative refund option.`;
export const FTC_WITH_PENALTY_AND_NO_FTC = (
  airlineA: string,
  airlineB: string
) =>
  `Without this option, your ticket with ${airlineA} isn’t refundable for cash — but you’re eligible for a 100% travel credit from the airline if you cancel and any cancellation penalties or re-booking fees will apply. Your ticket with ${airlineB} is non-refundable. Cancel for any reason provides an alternative refund option.`;
export const FTC_WITHOUT_PENALTY_AND_NO_FTC = (
  airlineA: string,
  airlineB: string
) =>
  `Without this option, your ticket with ${airlineA} isn’t refundable for cash — but you’re eligible for a 100% travel credit from the airline if you cancel. Your ticket with ${airlineB} is non-refundable. Cancel for any reason provides an alternative refund option`;

export const CFAR_HEADER = (args: {
  textVersion: "short" | "long";
  useStrong?: boolean;
  useBreak?: boolean;
  punctuation?: Punctuation;
}) => {
  const { textVersion, useStrong, useBreak, punctuation } = args ?? {};
  return `${useStrong ? `<strong>` : ""}Cancel${
    textVersion === "long" ? " your flight" : ""
  }${useStrong ? `</strong>` : ""}${useBreak ? `<br>` : " "}for any reason${
    punctuation ?? ""
  }`;
};

const REFUNDABLE_FARES_MARKING = "*";
export const REFUNDABLE_FARES_BANNER = (
  portalName: string,
  percent: number | undefined
) => (
  <>
    <strong>
      {REFUNDABLE_FARES_MARKING}
      Made {percent ?? DEFAULT_CASH_COVERAGE_PERCENTAGE}% refundable
    </strong>{" "}
    by {portalName}
  </>
);

export const PARTIALLY_REFUNDABLE_BANNER = (portalName: string) => (
  <>
    <strong>
      {REFUNDABLE_FARES_MARKING}
      Partially refundable
    </strong>{" "}
    by {portalName}
  </>
);

export const REFUNDABLE_FARES_ONLY_IN_RETURN_FLIGHTS_BANNER = (
  percent: number | undefined
) =>
  `We’re showing you ${
    percent ?? DEFAULT_CASH_COVERAGE_PERCENTAGE
  }% refundable fare options because you selected an ${
    percent ?? DEFAULT_CASH_COVERAGE_PERCENTAGE
  }% refundable fare class for your outbound flight.`;

// Hotels
export const HOTEL_CFAR_POINT_ONE = `<strong>Cancel your booking for any reason</strong> up until your scheduled check-in time.`;

export const HOTEL_CFAR_POINT_TWO = (
  coveragePercentage: number,
  coverageAmount?: string
) =>
  `Cancel and get a <strong>${coveragePercentage}% refund</strong> of your hotel base rate${
    coverageAmount ? `, plus taxes (${coverageAmount})` : " and taxes"
  } back to your original payment method.`;

export const FREE_CANCELLATION_BY_CAP1_HOTEL_CFAR_POINT_ONE = (
  premiumAmountString: string,
  perNight: boolean
) =>
  `This <strong>${premiumAmountString} ${
    perNight ? "per night " : ""
  }fee is included in your refund upon cancellation.</strong>`;

export const FREE_CANCELLATION_BY_CAP1_HOTEL_CFAR_POINT_TWO_VERBOSE = (
  expiryDateString: string,
  refundAmountString: string
) =>
  `Cancel for any reason up until your <strong>scheduled check-in time</strong> on <strong>${expiryDateString}</strong> and get <strong>a full refund of your hotel base rate, cancel for any reason fee, plus taxes and fees (${refundAmountString})</strong> back to your original payment method.`;

export const FREE_CANCELLATION_BY_CAP1_HOTEL_CFAR_POINT_TWO = (
  expiryDateString: string,
  refundAmountString: string,
  excludeTaxesAndFeesCopy?: boolean
) =>
  `Cancel for any reason up until your <strong>scheduled check-in time</strong> on <strong>${expiryDateString}</strong> and get <strong>a full refund of ${refundAmountString}${
    excludeTaxesAndFeesCopy ? "" : " plus taxes and fees"
  }</strong> back to your original payment method.`;
export const PARTIAL_REFUND_BY_CAP1_HOTEL_CFAR = ({
  expiryDateString,
  coverageAmount,
  excludeTaxesAndFeesCopy,
}: {
  expiryDateString: string;
  coverageAmount: string;
  excludeTaxesAndFeesCopy?: boolean;
}) =>
  `Cancel for any reason up until your <strong>scheduled check-in time</strong> on <strong>${expiryDateString}</strong> and get <strong>a refund of your hotel base rate (${coverageAmount})${
    excludeTaxesAndFeesCopy ? "" : " plus taxes and fees"
  }</strong> back to your original payment method.`;

export const PARTIAL_REFUND_BY_CAP1_HOTEL_CFAR_VERBOSE = ({
  expiryDateString,
  coverageAmount,
}: {
  expiryDateString: string;
  coverageAmount: string;
}) =>
  `Cancel for any reason up until your <strong>scheduled check-in time</strong> on <strong>${expiryDateString}</strong> and get <strong>a refund of your hotel base rate plus taxes and fees (${coverageAmount})</strong> back to your original payment method.`;

export const NON_REF_PARTIAL_HOTEL_CFAR_POINT_TWO = (coverageAmount: string) =>
  `Cancel and receive <strong>a refund of your hotel base rate (${coverageAmount}) plus taxes and fees</strong> back to your original payment method.`;

export const NON_REF_PARTIAL_HOTEL_CFAR_POINT_TWO_INC_TAXES_FEES = (
  coverageAmount: string
) =>
  `Cancel and receive <strong>a refund of your hotel base rate plus taxes and fees (${coverageAmount})</strong> back to your original payment method.`;

export const HOTEL_CFAR_POINT_THREE = (portalName: string) =>
  `Get a resolution through ${portalName} without contacting the hotel or completing forms.`;

export const HOTEL_CFAR_POINT_ONE_SHORT = `Cancel for any reason up until your scheduled check-in time`;

export const HOTEL_CFAR_POINT_TWO_SHORT = (coveragePercentage: number) =>
  `Get a <strong>${coveragePercentage}% refund</strong> of your hotel base rate and taxes`;

export const HOTEL_CFAR_POINT_THREE_SHORT = (portalName: string) =>
  `Get a fast resolution through ${portalName}`;

export const REFUNDABLE_ROOM_POINT_ONE_SHORT = (
  coveragePercentage: number,
  coverageAmountString: string
): string =>
  `Receive a <strong>${coveragePercentage}% refund</strong> of your hotel base rate <strong>(${coverageAmountString}) plus taxes</strong>`;

export const REFUNDABLE_ROOM_POINT_TWO_SHORT = `Cancel for any reason up until your <strong>scheduled check-in time</strong>`;

export const REFUNDABLE_ROOM_POINT_THREE_SHORT = (portalName: string) =>
  `Fast resolution through ${portalName}`;

export const PARTIALLY_REFUNDABLE_BY_CAP1_POINT_ONE_SHORT = (
  premiumAmountString: string
): string =>
  `Additional <strong>${premiumAmountString} per night fee is nonrefundable</strong>`;

export const PARTIALLY_REFUNDABLE_UPON_CANCELLATION_BY_CAP1_POINT_ONE_SHORT = (
  premiumAmountString: string
): string =>
  `Additional <strong>${premiumAmountString} per night fee is nonrefundable</strong> upon cancellation`;

export const PARTIALLY_REFUNDABLE_BY_CAP1_POINT_TWO_SHORT = (
  coverageAmountString: string,
  excludeTaxesAndFees?: boolean
): string =>
  `Cancel for any reason up until your scheduled check-in time and get a <strong>refund of your hotel base rate (${coverageAmountString})${
    excludeTaxesAndFees ? "" : " plus taxes and fees"
  }</strong>`;

export const PARTIALLY_REFUNDABLE_BY_CAP1_POINT_THREE_SHORT = (
  portalName: string
) => `Fast resolution through ${portalName}`;

export const POWERED_BY_CAPITAL_ONE_TRAVEL = "Offered by ${portalName}";

export const FREE_CANCELLATION_BY_CAP1_POINT_ONE_SHORT = (
  premiumAmountString: string
): string =>
  `Additional <strong>${premiumAmountString} per night fee is included in your refund</strong> upon cancellation`;

export const FREE_CANCELLATION_BY_CAP1_POINT_TWO_SHORT = (
  coverageAmountString: string,
  excludeTaxesAndFees?: boolean
): string =>
  `Cancel for any reason up until your scheduled check-in time and get a <strong>full refund of ${coverageAmountString}${
    excludeTaxesAndFees ? "" : " plus taxes and fees"
  }</strong>`;

export const FREE_CANCELLATION_BY_CAP1_POINT_THREE_SHORT = (
  portalName: string
) => `Fast resolution through ${portalName}`;

export const HOTEL_CFAR_TERMS_BODY = (portalName: string) => `
The option to cancel a hotel stay for any reason purchased through ${portalName} is subject to the following Terms and Conditions. Availability of this service may vary, and not all services are available to all customers or on all bookings. ${portalName} may revise these Terms and Conditions at any time by updating this posting.
<br/><br/>
Some of the services made available through ${portalName} are offered by Hopper (USA) Inc. and its affiliated companies (“Hopper”). Your use of the services offered by Hopper, and other third-parties that may be linked to from the Sites, will be governed by their respective Terms and Conditions and Privacy Policies.
<br/><br/>
With ${portalName}, you may be eligible to purchase the option to cancel your hotel stay for any reason, <strong>prior to the check-in time stated on your confirmation email</strong>, and receive a refund from ${portalName}, as set forth in these terms and as stated to you at time of purchase. The ability to cancel for any reason is subject to the following terms:
<ol>
<li style="font-weight: 600;"><p style="font-weight: 400;"><strong>Purchases</strong>. The option to cancel for any reason with ${portalName} may only be purchased at the time of your hotel reservation, and may not be added after you make the reservation. The option to cancel for any reason must be purchased for all rooms on the reservation. Capital One reserves the right to deny purchases of the option for any reason permitted by law (e.g., fraud or misuse).</li>

<li style="font-weight: 600;"><p style="font-weight: 400;"><strong>Fee for service and refunds</strong>. ${portalName} may offer multiple options to cancel for any reason. Some options to cancel for any reason with ${portalName} are only partially refundable. This means that if you purchase cancel for any reason with your hotel reservation and then cancel your hotel reservation, you will receive a refund of the hotel reservation cost (as provided in these Terms and Conditions), but you will not receive a refund of the cost of your purchase price to add the option to cancel for any reason. Depending on the hotel reservation you have selected, ${portalName} may also offer a fully refundable option to cancel for any reason, also marketed as a hotel reservation made fully refundable by ${portalName}. If you cancel this fully refundable option, you will receive a refund of the hotel reservation cost (as provided in these Terms and Conditions) and a refund of the cost of your purchase price to add the option to cancel for any reason. Whether your option to cancel for any reason is fully refundable will be made clear to you prior to purchase. Regardless of the cancel for any reason type that you purchase (partially refundable or fully refundable), the option is only valid for the hotel reservation for which the option to cancel for any reason was purchased. The option to cancel for any reason is not transferable and cannot be carried over to any future reservations.</li>

<li style="font-weight: 600;"><p style="font-weight: 400;"><strong>Form of and eligibility for refund</strong>. By purchasing the option to cancel for any reason, you are eligible to receive a refund from ${portalName} in accordance with Section 7. Prior to purchasing the option to cancel for any reason, your hotel stay may be eligible for a more limited refund policy from your hotel in connection with certain cancellations. If eligible, this option will be available to you regardless of whether you purchase the option to cancel for any reason. Even if you purchase the option to cancel for any reason for a hotel stay, you are only entitled to a single refund for your hotel stay and only up to the amount of the hotel stay you have paid at the time of valid cancellation.</li>
  
<li style="font-weight: 600;"><p style="font-weight: 400;"><strong>Additional information</strong>. For more detailed information regarding your existing cancellation policy from the respective hotel provider, visit the hotel’s website.</li>

<li style="font-weight: 600;"><p style="font-weight: 400;"><strong>Exercise process</strong>. If you have purchased the option to cancel for any reason for a hotel stay purchased through ${portalName}, you may cancel that purchase by visiting ${portalName}, navigating to My Trips, and selecting the reservation. No documentation or reason will be required in order to cancel. Using the cancel for any reason service will result in a cancellation of the entire booking for all nights, all rooms and all guests on the booking.</li>

<li style="font-weight: 600;"><p style="font-weight: 400;"><strong>Exercise window</strong>. Except as provided in Section 8 below, <strong>you must cancel your hotel stay purchase through ${portalName}’s booking site or via phone agent prior to the check-in time stated on your confirmation email</strong>. After this deadline has passed, the option to cancel for any reason will expire and any changes or cancellations to your purchased hotel stay will be subject to the rules imposed by the hotel. <strong>Your hotel stay purchase must be canceled through ${portalName} and cannot be canceled through your hotel</strong>.</li>

<li style="font-weight: 600;"><p style="font-weight: 400;"><strong>Amount and timing of refund</strong>. When you exercise the option to cancel for any reason, ${portalName} will refund to you the total cost of your hotel stay (the base fare for your room plus applicable taxes). If you have not pre-paid for the entirety of your hotel stay, your refund amount will be limited to the total amount of your hotel stay that you have paid to date. Canceling a hotel stay price does not entitle you to a full refund of all trip costs. No additional expenses other than the cost of the hotel stay purchased through ${portalName} will be reimbursed (including, for example, for partially-refundable cancel for any reason options, the purchase price you paid for the option to cancel your stay for any reason or any other purchased items such as airfare). If you purchased a fully refundable option to cancel for any reason, as described in Section 2 above, you will also receive a refund of the cost of the option to cancel for any reason. Reimbursements should be processed back to your original method of payment within 1-2 billing cycles.</li>

<li style="font-weight: 600;"><p style="font-weight: 400;"><strong>Exclusions</strong>. The option to cancel for any reason does not apply following the earlier of: a cancellation of your purchased hotel stay by the hotel for any reason, including the result of a force majeure event; any voluntarily cancellation or change requested by you directly with any hotel providing a hotel stay subject to the purchased option to cancel for any reason; a declaration of an “emergency” or similar “extraordinary measures” taken by any governmental authority in the destination city, region, or country of your ${portalName} trip, or issuance of a Safety and Security Alert by the United States Department of State or a Travel Health Notice Warning by the United States Center for Disease Control (CDC) for such destination; or a designation by the World Health Organization (WHO) that the destination city, country, or region of your ${portalName} trip is at risk from COVID-19. In the event of such cancellation, or change, declaration of emergency, alert, notice, designation, or other similar government action, your option to cancel for any reason shall expire immediately and a refund of your hotel stay purchase will be subject to the rules imposed by the hotel.</li>

<li style="font-weight: 600;"><p style="font-weight: 400;"><strong>Refund Reductions and Restrictions</strong>.  If you used any ${portalName} offer to purchase any portion of your hotel stay, you will not be refunded in any form for such an amount.</li>

<li style="font-weight: 600;"><p style="font-weight: 400;"><strong>Not Insurance</strong>. The option to cancel your hotel stay for any reason ensures that the respective hotel stay purchased on the Sites can be canceled for any reason without incurring additional fees. ${portalName} provides its customers with this option to provide a more seamless and flexible experience for travel booked through the Sites. The option to cancel for any reason is not intended to constitute an offer to insure, does not constitute insurance or an insurance contract, and does not take the place of insurance obtained or obtainable by you.</li>

<li style="font-weight: 600;"><p style="font-weight: 400;"><strong>Contact</strong>. For any questions, please contact ${portalName} at 844-422-6922.</li>
</ol>
`;

export const HOTEL_CFAR_TERMS_TABLE = `
<br/><br/>
<table style="width:100%; border-collapse: collapse; text-align: center;">
  <tr style="background: black;">
    <td style="padding: 6px; color: white; border: 1px solid black; border-collapse: collapse; width: 50%;">Cancel for Any Reason (Flights)</th>
    <td style="padding: 6px; color: white; border: 1px solid black; border-collapse: collapse; width: 50%;">Cancel for Any Reason (Hotels)</th>
  </tr>
  <tr>
    <td style="vertical-align:top; padding: 6px; border: 1px solid black; border-collapse: collapse; width: 50%;">Cancel your flight purchase for any reason, up to <strong>three (3) hours</strong> prior to your first scheduled departure</td>
    <td style="vertical-align:top; padding: 6px; border: 1px solid black; border-collapse: collapse; width: 50%;">Cancel your hotel stay for any reason, <strong>prior to the check-in time</strong> stated on your confirmation email</td>
  </tr>
  <tr>
    <td style="vertical-align:top; padding: 6px; border: 1px solid black; border-collapse: collapse; width: 50%;">Refunds cover the <strong>base fare and related taxes and fees</strong> and <strong>do not entitle you to a full refund of all trip costs</strong>. Any paid add-ons, such as baggage, seat or boarding order selection, and the fee to purchase cancel for any reason, are <strong>not included in the refund</strong>.</td>
    <td style="vertical-align:top; padding: 6px; border: 1px solid black; border-collapse: collapse; width: 50%;">Refunds cover the <strong>base fare for your room and applicable taxes</strong> and <strong>do not entitle you to a full refund of all trip costs</strong>. Any additional expenses, such as the fee to purchase cancel for any reason, are <strong>not included in the refund, with the exception of the fee to purchase the fully refundable option to cancel for any reason (as described in Section 2 above) which is included when that option is purchased</strong>.</td>
  </tr>
</table>
<br/><br/>
`;
